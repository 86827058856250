import React from 'react'
import TimePicker from './TimePicker';

type Props = {
  max_time?: string
  min_time?: string;
  start_time?: string,
  end_time?: string,
  onChange: ({ start_time, end_time }: { start_time: string, end_time: string }) => void
}

const TimeRangePicker = ({ max_time, min_time, start_time = "00:00", end_time = "00:00", onChange }: Props) => {

  return (
    <div className='flex items-center'>
      <TimePicker min_time={min_time} default_time={start_time} onChange={(e: any) => onChange({ start_time: e, end_time })} /> <div className='mx-2 font-bold'>-</div> <TimePicker min_time={start_time} max_time={max_time} default_time={end_time} onChange={(e: any) => onChange({ start_time, end_time: e })} />
    </div>
  )
}

export default TimeRangePicker