import React from 'react'
import { ReactSVG } from 'react-svg'
import { BRAND_ICON_MD, BRAND_ICON_SM, NEW_BRAND_ICON_MD, toAbsoluteUrl } from 'src/helpers'
import { Svg } from 'src/helpers/SVGC'

type Props = {}



const BrandComponent = (props: Props) => {

    return (
        <div>
            <div className='hidden md:block' style={{height:'65px'}}><img src={NEW_BRAND_ICON_MD} style={{ width: "100%", height: "100%", display: 'block' }} /></div>
            <div className='hidden max-md:block' style={{height:'48px'}}><img src={NEW_BRAND_ICON_MD} style={{ width: "100%", height: "100%", display: 'block' }} /></div>
        </div>
    )
}

export default BrandComponent