import { checkDecimal, convertToCamelCase, FormField } from 'src/helpers'
import DocumentViwer from 'src/shadcn/components/custom/DocumentViwer'
import RenderData from '../RenderData'
import * as modules from './modules'
import { GENERAL_PROJECT_INFO_FORM_FIELDS, generalProjectInfo, PROJECT_CATEGORY_WISE_FORM_FIELD, PROJECT_CATEGORY_WISE_FORM_STRUCTURE, RE_PROJECT_CAPACITY_FORM_SECTIONS } from './modules2'
import { Separator } from 'src/shadcn/components/ui'
import { LockKeyhole } from 'lucide-react'

type Props = {
  projectDetails: any
}


const RenderData2 = ({ value }: { value: string | undefined }) => {
  return  <div className='bg-white p-2 rounded-md '><LockKeyhole size={14} /></div>
}

const ViewProjectDetails = ({ projectDetails }: Props) => {

  return (
    <div className='flex flex-col space-y-2 text-xs'>
      {
        <div className='grid grid-cols-1 gap-4 p-2 bg-[#CF8C11]/10 my-2 '>
          <div className='col-span-2'>
            <div className='text-gray-600 col-span-2 border border-primary border-l-0 border-r-0 border-t-0 border-b-2 uppercase font-bold py-2'>Project Overview</div>
            {
              Object.keys(generalProjectInfo).filter((k: any) => !(["imagesAttachments", "headerImage", "videoAttachments"].includes(k))).map((k: any) => {

                if (!["coordinates"].includes(k)) {
                  var newVal = projectDetails && Object.keys(projectDetails).includes(k) ? projectDetails[k] : null
                  const formFiled = GENERAL_PROJECT_INFO_FORM_FIELDS.find((d: FormField) => d.dataKey == k)

                  if (formFiled?.componentProps?.type == "number" && formFiled.componentProps.numberFormatted) {
                    newVal = projectDetails[k] ? checkDecimal(projectDetails[k], true) : null
                  }
                  return <div key={`project overview-${k}`} className='grid grid-cols-3 gap-2 my-2'>
                    <div className='text-gray-600 flex'> {convertToCamelCase(k) || "-"} </div>
                    <div className='flex space-x-2 col-span-2 ml-4 md:ml-0' >{
                      formFiled && formFiled.componentType == "file_picker" ? (
                        <div>
                          {
                            formFiled.componentProps?.fileSelectionType == "multi" ?
                              projectDetails && projectDetails[k] && Array.isArray(projectDetails[k]) && projectDetails[k].map((d: any) => {
                                return <DocumentViwer url={d} />
                              })
                              : <DocumentViwer url={projectDetails[k]} />
                          }
                        </div>
                      ) :
                        <div className='flex items-center space-x-2'>
                          <RenderData value={projectDetails ? newVal : undefined} />
                        </div>}
                    </div>
                  </div>
                }
              })
            }
          </div>
        </div>
      }

      {
        projectDetails && PROJECT_CATEGORY_WISE_FORM_STRUCTURE[projectDetails.projectCategoryType as keyof object] && <>
          {
            projectDetails && Object.entries(PROJECT_CATEGORY_WISE_FORM_STRUCTURE[projectDetails.projectCategoryType as keyof object]).map(([key, objectKeys]) => {
              return key != "GENERAL_PROJECT_INFO" && <div className='grid grid-cols-1 gap-4 p-2 bg-[#CF8C11]/10 my-2 '>
                <div className='col-span-2'>
                  <div className='text-gray-600 col-span-2 border border-primary border-l-0 border-r-0 border-t-0 border-b-2 uppercase font-bold py-2'>{convertToCamelCase(key)}</div>
                  {
                    Object.keys(objectKeys as any).map((k: any) => {

                      if (!["coordinates"].includes(k)) {
                        var newVal = projectDetails && Object.keys(projectDetails).includes(k) ? projectDetails[k] : null
                        const form: any = PROJECT_CATEGORY_WISE_FORM_FIELD[projectDetails.projectCategoryType as keyof object]
                        const formFiled = form.find((d: FormField) => d.dataKey == k)
                        if (formFiled?.componentProps?.type == "number" && formFiled.componentProps.numberFormatted) {
                          newVal = projectDetails[k] ? checkDecimal(projectDetails[k], true) : null
                        }

                        return <div key={`project overview-${k}`} className='grid grid-cols-3 gap-2 my-2'>
                          <div className='text-gray-600 flex'> {convertToCamelCase(k) || "-"} </div>
                          <div className='flex space-x-2 col-span-2 ml-4 md:ml-0' >{
                            formFiled && formFiled.componentType == "file_picker" ? (
                              <div>
                                {/* {
                                  formFiled.componentProps?.fileSelectionType == "multi" ?
                                    projectDetails && projectDetails[k] && Array.isArray(projectDetails[k]) && projectDetails[k].map((d: any) => {
                                      return <DocumentViwer url={d} />
                                    })
                                    : <DocumentViwer url={projectDetails[k]} />
                                } */}
                              </div>
                            ) :
                              <div className='flex items-center space-x-2'>
                                <RenderData2 value={projectDetails ? newVal : undefined} />
                              </div>}
                          </div>
                        </div>
                      }
                    })
                  }
                </div>
              </div>
            })
          }
        </>
      }

    </div>
  )
}

export default ViewProjectDetails