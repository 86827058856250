import { MAIN_ROUTES_CONST } from "src/navigations/modules";

export const OVERVIEW = {
    title: "Land Lease For Solar",
    description: "Maximize the value of your land by leasing it for solar energy projects. Our land lease services allow landowners to generate passive income while contributing to renewable energy initiatives, making unused or underutilized land a profitable and sustainable resource.",
    video: "",
    img: "",
    targetPage: MAIN_ROUTES_CONST.marketplace+ "?projectCategoryType=Offer Land For Utility Scale Project"
};

export const KEY_FEATURES =[
    {
        icon: "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661722144_PassiveIncomeOpportunity.png",
        title: "Passive Income Opportunity",
        description: "Leasing your land for solar provides steady income without active management, making it a great long-term opportunity for landowners."
    },
    {
        icon: "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661721106_EnvironmentallyFriendly.png",
        title: "Environmentally Friendly",
        description: "Leasing your land for solar supports clean energy generation, reduces carbon footprints, and promotes sustainability while maximizing property use."
    },
    {
        icon: "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661718759_NoUpfrontCosts.png",
        title: "No Upfront Costs",
        description: "Land lease agreements require no investment from the landowner, as we handle installation and management for a hassle-free experience."
    },
    {
        icon: "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661719974_LongTermContracts.png",
        title: "Long-Term Contracts",
        description: "We offer long-term lease agreements, typically ranging from 20 to 30 years, providing financial stability and a secure income for the duration of the lease."
    }
];

export const FAQ = [
    {
      "question": "What is a land lease?",
      "answer": "A land lease allows a tenant to rent land from a property owner for a specific period, usually for agricultural, commercial, or renewable energy projects."
    },
    {
      "question": "How is land lease rent calculated?",
      "answer": ""
    },
    {
      "question": "Can a land lease be renewed?",
      "answer": ""
    },
    {
      "question": "Are there restrictions on land use?",
      "answer": ""
    },
    {
      "question": "Can the lease terms be changed later?",
      "answer": ""
    },
    {
      "question": "How can I get started with a land lease?",
      "answer": ""
    }
  ]