import React from 'react'

type Props = {}

const InfiniteProgressBar = (props: Props) => {
    return (
        
        <div className="progress-bar">
          <div className="progress-bar-value"></div>
        </div>
     
    )
}

export default InfiniteProgressBar