import { convertArrayToOptionType, EMAIL_REGEX, FormField, NAME_REGEX, NUMBER_REGEX, PROJECT_GENERAL_PROJECT_ORDER_TYPE_OPTIONS } from "src/helpers";
import * as Yup from 'yup';


export interface captureWhatsappInterestForm {
    name: string;
    email: string;
    phonenumber: string;
    service_type: string;
    type: string;
}


export const INIT_CAPTURE_WHATSAPP_INTEREST_FORM = {
    email: "",
    phonenumber: "",
    service_type: "",
    type: "",
}

export const CAPTURE_WHATSAPP_INTEREST_FORM_FIELDS: FormField[] = [
    {
        label: 'Name',
        dataKey: 'name',
        componentType: 'input',
        componentProps: {
            type: 'text',
            placeholder: 'Full Name',
            required: true,
            regex: NAME_REGEX
        },
    },
    {
        label: 'Email Address',
        dataKey: 'email',
        componentType: 'input',
        componentProps: {
            type: 'email',
            placeholder: 'Enter your email address',
            maxLength: 150,
            required: true,
            regex: EMAIL_REGEX
        },
    },
    {
        label: 'Phone Number',
        dataKey: 'phonenumber',
        componentType: 'input',
        componentProps: {
            type: 'text',
            placeholder: 'Enter your phone number',
            maxLength: 15,
            required: true,
            regex: NUMBER_REGEX
        },
    },
    {
        label: 'Type of Service',
        dataKey: 'service_type',
        componentType: 'options',
        componentProps: {
            options: convertArrayToOptionType([
                "Finance For RE-Projects",
                "EPC Services",
                "RE Project Capacity",
                "RE Project Rights",
                "Land",
                "Rooftop",
            ]),
            required: true
        },
    },
    {
        label: 'Type of Order',
        dataKey: 'type',
        componentType: 'options',
        componentProps: {
            options: convertArrayToOptionType(["BUY","SELL"]),
            required: true
        },
    },
];


export const CAPTURE_WHATSAPP_INTEREST_FORM_VALIDATION = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),

    phonenumber: Yup.string()
        .length(10, 'Phone number must be 10 digits only')
        .required('Phone number is required'),

    service_type: Yup.string().required('Service type is required'),
    type: Yup.string().required('Order type is required')
});
