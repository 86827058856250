import { IMG_GOOGLE_PLAY, IMG_P2P_DOWNLOAD_APP, IMG_P2P_DOWNLOAD_APP_BG_BANNER } from 'src/helpers'

type Props = {}

const P2pDownloadApp = (props: Props) => {
    return (
        <div>
            <div className='max-w-[80vw] mx-auto h-[550px] md:h-[450px] lg:h-[350px]' style={{ backgroundImage: "url(" + IMG_P2P_DOWNLOAD_APP_BG_BANNER + ")", backgroundRepeat: "no-repeat", backgroundSize: "100% 80%", backgroundPosition: "center",transform: "rotate(0deg)" }}
             >
                <div className=' md:grid md:grid-cols-2 md:h-full '>
                    <div className='flex items-center h-full  p-8 '>
                        <div className='flex flex-col  space-y-8 '>
                            <div>
                                <div className='text-4xl font-bold text-white mt-20 md:mt-0'>Download Trading App </div>
                                <div className='h-[2px] mt-4 bg-primary w-[50px]'></div>
                            </div>
                            <div className=' text-white uppercase'>Discover global markets with <br/> an easy-to-use app.</div>
                            <div className=''>
                                <img src={IMG_GOOGLE_PLAY} style={{ height: "50px" }} />
                            </div>
                        </div>
                    </div>
                    <div className='hidden md:flex md:justify-end'>
                        <img src={IMG_P2P_DOWNLOAD_APP} style={{height:"350px"}}  />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default P2pDownloadApp