import Slider from 'rc-slider'
import { checkDecimal, cn } from 'src/helpers'
import { ClassNameValue } from 'tailwind-merge'
import { Popover, PopoverContent, PopoverTrigger } from '../ui'

type Props = {
    name: string, min: number, max: number, steps?: number, onChange: (e: any) => void, triggerClassName?: ClassNameValue, value: number[]
    unit?: string
}
const RangeSelector = ({ name, min, max, steps = 1, onChange, triggerClassName, value, unit }: Props) => {
    return (
        <Popover>
            <PopoverTrigger asChild>
                {/* <div className={cn("cursor-pointer text-md", triggerClassName)}>{value[0] > 0 || value[1] > 1 ? (unit ? `${value[0] || 0} ${unit}` : millify(value[0] || 0)) + "-" + unit? `${value[1] || 0} ${unit}` : millify(value[1] || 0): name}</div> */}
                <div className={cn("cursor-pointer text-md", triggerClassName)}>{name } {unit ? `(${unit})` : ''}</div>

            </PopoverTrigger>
            <PopoverContent className="p-4">
                <div className="">
                    <div className="flex justify-between items-center">
                        <span className="text-gray-500 text-sm">{checkDecimal((value[0] || 0).toString(), true)} {unit || ""}</span>
                        <span className="text-gray-500 text-sm">{checkDecimal((value[1] || 0).toString(), true)} {unit || ""}</span>
                    </div>
                    <div className='mx-1 '>
                        <Slider
                            range
                            min={min}
                            max={max}
                            value={value}
                            step={steps}
                            onChange={(value: any) => {
                                onChange(value)
                            }}
                            trackStyle={[{ backgroundColor: "hsl(var(--primary))", height: 3 }]}
                            handleStyle={[
                                { backgroundColor: "hsl(var(--primary-foreground))", borderColor: "hsl(var(--primary))" },
                                { backgroundColor: "hsl(var(--primary-foreground))", borderColor: "hsl(var(--primary))" },
                            ]}
                            railStyle={{ backgroundColor: "#E5E7EB", height: 6 }}
                        />
                    </div>
                    {/* <div className="flex justify-between items-center">
                        <span className="text-gray-500">{checkDecimal(min.toString(),true)}</span>
                        <span className="text-gray-500">{checkDecimal(max.toString(),true)}</span>
                    </div> */}
                </div>
            </PopoverContent>
        </Popover>
    )
}

export default RangeSelector