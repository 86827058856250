import React, { useState } from 'react'
import ScheduleCallForm from 'src/modules/ScheduleCallForm/ScheduleCallForm'
import TimeRangePicker from 'src/shadcn/components/custom/TimePicker/TimeRangePicker'


type Props = {}

const ScheduleCallPageMain = (props: Props) => {

  const [timeFrame, settimeFrame] = useState<string>('00:00-00:00')
  return (
    <div className='flex justify-center items-center py-16 bg-gray-50'>
      <div className='w-[400px] p-4 bg-white rounded-md'>
        <div className='text-lg text-primary uppercase'>SCHEDULE CALL REQUEST</div>
        <div className='h-0.5 bg-primary w-[50px] rounded-full'></div>
        <div className='my-4 text-xs text-gray-400'>Please fill the form below to schedule call and get in touch with you.</div>
        <ScheduleCallForm />
      </div>
    </div>
  )
}

export default ScheduleCallPageMain