import { Mail, Share, Share2 } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { catchAsync, EMAIL_REGEX, IS_SUCCESS_RESPONSE } from "src/helpers";
import { useAuth } from "src/modules/Auth/Core";
import ChangePassword from "src/modules/Profile/ChangePassword";
import CompanySettings from "src/modules/Profile/CompanySettings";
import PrivacySettings from "src/modules/Profile/PrivacySettings";
import ProfileComponent from "src/modules/Profile/ProfileComponent";
import { sendRefferralLinkApiRequest } from "src/services/requests/Auth";
import CustomeDialog from "src/shadcn/components/custom/CustomeDialog";
import InputFiled from "src/shadcn/components/custom/InputeFiled/InputFiled";
import {
  Button,
  Input,
  Label,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/shadcn/components/ui";

type Props = {};

const ProfileMain = (props: Props) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [email, setemail] = useState<string>("");
  const [showEmailDialog, setshowEmailDialog] = useState<boolean>(false);
  const [sendingEmail, setsendingEmail] = useState<boolean>(false);
  const [showEmailerror, setshowEmailerror] = useState<string>("");

  useEffect(() => {
    if (!currentUser) {
      navigate("/");
    }
    return () => {};
  }, [currentUser]);

  const handleCopy = () => {
    navigator.clipboard
      .writeText("AB123456")
      .then(() => {
        toast.success("Text Copied!");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleSendEmail = catchAsync(
    async () => {
      setsendingEmail(true);
      return await sendRefferralLinkApiRequest({
        user_id: currentUser?.id,
        email: email,
      });
    },
    (result: any) => {
      if (IS_SUCCESS_RESPONSE(result)) {
        setsendingEmail(false);
        setshowEmailDialog(false);
        setemail("");
        toast.success("Email sent Successfully.");
      }
    },
    () => {
      setsendingEmail(false);
    }
  );

  return (
    <div className="w-full">
      <div className="relative mb-40">
        <div className="h-[200px] bg-gray-100 rounded-b-3xl  "></div>
        <div className="max-w-[80vw] mx-auto -mt-[100px] mb-4 rounded-xl bg-white p-6 space-y-4 shadow-xl">
        <div className="md:grid md:grid-cols-5">
 
          <Tabs defaultValue="profile" className="flex-1 w-full col-span-3">
            
              <TabsList className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 justify-start items-start gap-x-6 p-0 h-[100px] md:h-[50px]">
                <TabsTrigger value="profile" className="flex justify-start md:justify-center">Profile</TabsTrigger>
             
                <TabsTrigger value="change_password" className="flex justify-start md:justify-center">
                  Change Password
                </TabsTrigger>
                <TabsTrigger value="privacy_setting" className="flex justify-start md:justify-center">
                  Privacy Setting
                </TabsTrigger>
              </TabsList>
              
              <div className="flex md:hidden space-x-2 justify-end col-span-2">
                <Button
                  className=""
                  onClick={handleCopy}
                  size={"sm"}
                  variant={"outlinePrimary"}
                >
                  Copy Referrel Code
                </Button>
                <Button
                  variant={"outlinePrimary"}
                  onClick={() => setshowEmailDialog(true)}
                >
                  <Share2 size={16} />
                </Button>
              </div>
           
            <TabsContent value="profile">
              <ProfileComponent />
            </TabsContent>
            <TabsContent value="change_password">
              <ChangePassword />
            </TabsContent>
            <TabsContent value="privacy_setting">
              <PrivacySettings />
            </TabsContent>
          </Tabs>
          <div className="hidden md:flex space-x-2 justify-end col-span-2">
                <Button
                  className=""
                  onClick={handleCopy}
                  size={"sm"}
                  variant={"outlinePrimary"}
                >
                  Copy Referrel Code
                </Button>
                <Button
                  variant={"outlinePrimary"}
                  onClick={() => setshowEmailDialog(true)}
                >
                  <Share2 size={16} />
                </Button>
              </div>
          </div>
        
        </div>
      </div>
      <CustomeDialog
        className="md:max-w-md"
        isOpen={showEmailDialog}
        isLoading={sendingEmail}
        headerTitle="Share Your Refferal Code"
        onSubmit={() => {
          if (!email) {
            setshowEmailerror("Please enter email");
          } else {
            handleSendEmail();
          }
        }}
        onCancle={() => setshowEmailDialog(false)}
      >
        <div className="mb-4 flex flex-col space-y-2">
          <InputFiled
            type="email"
            label="Enter Email"
            nameKey="email"
            placeholder="Email"
            prefix={<Mail size={16} />}
            onchange={(e: any) => {
              setshowEmailerror("");
              setemail(e);
            }}
          />
          {showEmailerror ? (
            <p className="text-destructive text-xs ">{showEmailerror}</p>
          ) : (
            ""
          )}
        </div>
      </CustomeDialog>
    </div>
  );
};

export default ProfileMain;
