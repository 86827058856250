import React from "react";
import {
  Abhishek_Khare,
  ADVISORY_MANAGAMENT_IMG,
  Shailesh_Joshi,
  Vijay_Dhonde,
} from "src/helpers";
import { advisoryConsultacyData } from "./Component/modules";
import AdvisoryConsultancyCard from "./Component/AdvisoryConsultancyCard";
const AdvisoryConsultancyMain = () => {
  return (
    <div className="mb-40">
      {/* Banner Section */}
      <div
        className="w-full h-[250px] sm:h-[300px] bg-cover bg-center flex items-end justify-center"
        style={{ backgroundImage: `url(${ADVISORY_MANAGAMENT_IMG})` }}
      >
        <div className="text-white/75 text-2xl sm:text-4xl md:text-5xl p-12 sm:pr-20">
        Advisory Consultancy Services
        </div>
      </div>

      {/* Advisory Cards Section */}
      <div className="w-full my-16 space-y-10 mx-auto">

        {advisoryConsultacyData &&
          advisoryConsultacyData.map((cur: any, indexs: number) => (
            <AdvisoryConsultancyCard
              key={indexs + 1}
              description_1={cur?.description_1}
              description_2={cur?.description_2}
              imageName={cur?.image}
              indexNumbers={indexs}
              listArray={cur?.list}
              title={cur?.title}
            />
          ))}
      </div>
    </div>
  );
};

export default AdvisoryConsultancyMain;
