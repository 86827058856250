import { object } from "yup"
import CellRoutes, { CELL_ROUTES } from "./CellRoutes"

const MAIN_ROUTES_CONST = {
    login: "login",
    visit: "visits",
    registration: "register",
    welcome: "welcome",
    marketplace: "marketplace",
    forgot: "forgot",
    verify_otp: "otp/verify",
    reset_password: "reset-password",
    success_reset_password: "success-reset-password",
    faq: "faq",
    about_us: "about",
    contact_us: "contact",
    advisory :"advisory",
    crowd_funding: "crowd-funding",
    green_energy: "green-energy",
    cell: "cell",
    solarkit: "solar-kits",
    products: "products",
    products_details: "product-details",
    order: "orders",
    order_details: "order-details",
    create_order: "order",
    marketplace_product_details: "marketplace-product-details",
    wallet: "wallet",
    referredRewards: "referred-rewards",
    profile: "profile",
    wishlist: "wishlist",
    new_enquiry_request: "new-enquiry-request",
    existing_enquiry_rquest: "existing-enquiry-request",
    p2pEnergyTrading: "p2p-energy-trading",
    customerJourney: "customer-journey",
    services: "services",
    commercial_services: "services/commercial",
    residential_services: "services/residential",
    finance_services: "services/finance",
    land_lease_services: "services/land-lease",
    rooftop_solar_services: "services/rooftop-solar",
    ground_mounted_services: "services/ground-mounted",
    project_rights_services: "services/project-rights",
    service_1: "services/service1",
    service_2: "services/service2",
    new_constructed_project_service: "services/new-constructed-project",
    sales_existing_project_service: "services/sales-existing-project",
    solar_o_and_m_service: "services/solar-O-and-M",
    peer_to_peer_service: "services/peer-to-peer",
    products_service: "services/products",
    advisory_consultancy:"services/advisory-consultancy",
    refer_now: "refer-now",
    land_registration: "land-registration",
    lands: "lands",
    land_details: "land-details",
    services_enquiry_form: "services/enquiry-form",
    roof_tops: "roof-tops",
    roof_top_details: "roof-top-details",
    project_rights: "project-rights",
    project_rights_detail: "project-rights-details",
    sell_existing_projects: "sell-existing-projects",
    sell_existing_projects_detail: "sell-existing-projects-detail",
    new_constructed_projects: "new-constructed-projects",
    new_constructed_projects_detail: "new-constructed-projects-detail",
    p2p_energy_trading_landing: "p2p-energy",
    mission_and_vision: "mission-and-vision",
    careers: "careers",
    our_team: "our-team",
    quotation_details: "quotation-details",
    termsAndConditions: "terms-conditions",
    dataPrivacy: "data-privacy",
    order_confirmation: "order-confirmation",
    payment: "payments",
    area_calculator: "area-calculator",
    finance_service: "finance-service",
    enquiry_history: "enquiry-history",
    project_history: "project-history",
    captureInterest: "capture-interest",
    projects:"projects",
    projects_enquiry:"projects-enquiry",
    user_engagement:"user-engagement",
    schedule_call:"schedule-call",
    subscribe:"/subscribe",
    uploadBills:"/upload-bill"
}


const RESTRICT_LAYOUT_PATH = [
    // MAIN_ROUTES_CONST.marketplace,
    // MAIN_ROUTES_CONST.investment_calculator,
    MAIN_ROUTES_CONST.marketplace_product_details,
    // MAIN_ROUTES_CONST.wallet,
    // MAIN_ROUTES_CONST.profile,
    MAIN_ROUTES_CONST.new_enquiry_request,
    MAIN_ROUTES_CONST.existing_enquiry_rquest,
    MAIN_ROUTES_CONST.order,
    MAIN_ROUTES_CONST.order_details,
    MAIN_ROUTES_CONST.create_order,
    MAIN_ROUTES_CONST.land_registration,
    MAIN_ROUTES_CONST.lands,
    MAIN_ROUTES_CONST.services_enquiry_form,
    MAIN_ROUTES_CONST.new_constructed_projects,
    // MAIN_ROUTES_CONST.schedule_call
    // MAIN_ROUTES_CONST.land_details,
    // MAIN_ROUTES_CONST.epcContractor
]


const AUTH_ROUTES_CONST = {

}

const APP_ROUTES_CONST = {
    dashboard: "dashboard"
}

export { MAIN_ROUTES_CONST, APP_ROUTES_CONST, RESTRICT_LAYOUT_PATH }