import React from "react";
import {
  Abhishek_Khare,
  ADVISORY_MANAGAMENT_IMG,
  Shailesh_Joshi,
  Vijay_Dhonde,
} from "src/helpers";

const AdvisoryOptionMain = () => {
  return (
    <div className="mb-40">
      {/* Banner Section */}
      <div
        className="w-full h-[250px] sm:h-[300px] bg-cover bg-center flex items-end justify-center"
        style={{ backgroundImage: `url(${ADVISORY_MANAGAMENT_IMG})` }}
      >
        <div className="text-white/75 text-2xl sm:text-4xl md:text-5xl p-12 sm:pr-20">
        Marketplace Expert Panel
        </div>
      </div>

      {/* Advisory Cards Section */}
      <div className="w-full my-16 space-y-10 mx-auto">
        {/* Card 1 - Shailesh Joshi */}
        <div className="w-full max-w-[80%] mx-auto flex flex-col md:flex-row items-center justify-center space-y-6 md:space-y-0 md:space-x-10 p-6 bg-gradient-to-r from-[#F7F7F7] to-[#FFFFFF] rounded-lg shadow-lg">
          <div className="flex flex-col items-center">
            <img
              src={Shailesh_Joshi}
              alt="Shailesh Joshi"
              className="w-[150px] sm:w-[180px] md:w-[222px] h-[150px] sm:h-[180px] md:h-[222px] rounded-full object-cover"
            />
            <div className="text-[#908585] text-base sm:text-lg font-bold mt-2">
              Mr. Shailesh Joshi
            </div>
          </div>
          <div className="text-base font-normal font-sans w-full md:w-[60%] space-y-3 text-center md:text-left">
            <p>
              <b>Shailesh Joshi</b> is an Energy Sector veteran having 30+ years of
              experience across the energy value chain in Policy & Strategy, Project
              Finance, Risk Management, Regulatory, and more.
            </p>
            <p>
              He has worked with Governments, Regulators, Private Developers, and
              Utilities, providing a 360-degree perspective on energy sector issues.
            </p>
            <p>
              He advises clients on sustainability and decarbonization goals in the
              energy sector, including fuels, power generation, and renewables.
            </p>
            <br/>
            <br/>
          </div>
        </div>

        {/* Card 2 - Vijay Dhonde */}
        <div className="w-full max-w-[80%] mx-auto flex flex-col-reverse md:flex-row items-center justify-center space-y-6 md:space-y-0 md:space-x-10 p-6 bg-gradient-to-l from-[#F7F7F7] to-[#FFFFFF] rounded-lg shadow-lg">
          <div className="text-base font-normal font-sans w-full md:w-[60%] space-y-3 text-center md:text-left">
            <p>
              <b>Vijay Dhonde</b> is a dynamic leader with 29 years of experience in
              electricity, gas utilities, and consulting.
            </p>
            <p>
              He has led USD 3 billion power projects across India, Nepal, and
              Africa, managing 50+ project offices and 600+ engineers.
            </p>
            <p>
              His expertise includes electricity distribution, regulatory affairs,
              and digital transformation using AI and blockchain in energy markets.
            </p>
            <p>
              Known for his strategic leadership, he has contributed to power sector
              reforms and infrastructure growth.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <img
              src={Vijay_Dhonde}
              alt="Vijay Dhonde"
              className="w-[150px] sm:w-[180px] md:w-[222px] h-[150px] sm:h-[180px] md:h-[222px] rounded-full object-cover"
            />
            <div className="text-[#908585] text-base sm:text-lg font-bold mt-2">
              Mr. Vijay Dhonde
            </div>
          </div>
        </div>

        {/* Card 3 - Abhishek Khare */}
        <div className="w-full max-w-[80%] mx-auto flex flex-col md:flex-row items-center justify-center space-y-6 md:space-y-0 md:space-x-10 p-6 bg-gradient-to-r from-[#F7F7F7] to-[#FFFFFF] rounded-lg shadow-lg">
          <div className="flex flex-col items-center">
            <img
              src={Abhishek_Khare}
              alt="Abhishek Khare"
              className="w-[150px] sm:w-[180px] md:w-[222px] h-[150px] sm:h-[180px] md:h-[222px] rounded-full object-cover"
            />
            <div className="text-[#908585] text-base sm:text-lg font-bold mt-2">
            Adv. Abhishek Khare
            </div>
          </div>
          <div className="text-base font-normal font-sans w-full md:w-[60%] space-y-3 text-center md:text-left">
            <p>
              <b>Abhishek Khare</b> is an Advocate at the Supreme Court of India
              since 2015, with over 23 years of experience.
            </p>
            <p>
              He specializes in corporate law, energy law, infrastructure, EPC,
              maritime law, and regulatory matters.
            </p>
            <p>
              A postgraduate from Oxford University, he has advised corporations on
              project finance, EPC contracts, tariff regulations, and dispute
              resolution.
            </p>
            <p>
              Actively engaged in international arbitrations, he represents clients
              in EPC project disputes, energy sector issues, and human rights laws.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvisoryOptionMain;
