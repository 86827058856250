

import BlockchainService from 'src/modules/ReStructure/Welcome/BlockchainService'
import HeroSection3 from 'src/modules/ReStructure/Welcome/HeroSection3/HeroSection3'
import P2pTrading from 'src/modules/ReStructure/Welcome/P2PTrading/P2pTrading'
import WelcomeServices from 'src/modules/ReStructure/Welcome/Services'
import WhyUsMain from 'src/modules/ReStructure/Welcome/WhyUs/WhyUsMain'


const WelcomePage_R = () => {
  return (

    <div className='w-full   mb-[220px] '>
      {/* <div className='w-full relative'>
        <div className='w-full z-[90] -mt-20'>
            <HeroSection />
          </div>
        <div className='w-full absolute bottom-0 '>
          <EnquiryFormMain />
        </div>
      </div> */}
      <div className='w-full -mt-20 '>
        <HeroSection3 />
      </div>
      {/* <Features3 /> */}
      {/* <WhyGoToSolarMain /> */}
      {/* <WhyWeDoMain /> */}
      <WelcomeServices />
      <div className=''>
        <P2pTrading />
      </div>
      <BlockchainService />
      <WhyUsMain />
      {/* <OurProducts /> */}
      {/* <TestimonialSlider /> */}
      {/* <FaqSection /> */}

      {/* <TestimonialSlider /> */}
    </div>

  )
}

export default WelcomePage_R