import { FormField, NUMBER_REGEX } from 'src/helpers';
import * as Yup from 'yup';

export interface IBillUploadForm {
    phoneNumber: string;
    billUrl: File | null;
}

export const initialValues: IBillUploadForm = {
    phoneNumber: '',
    billUrl: null,
};

export const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
        .length(10, 'Phone number must be exactly 10 digits')
        .required('Phone number is required'),
    billUrl: Yup.mixed()
        .required('Please upload your electricity bill')
        .test('fileType', 'Only jpg, png and pdf files are allowed', (value: any) => {

            if (value && value instanceof File) {
                const fileType = value.type;
                return (
                    fileType === 'image/jpeg' ||
                    fileType === 'image/png' ||
                    fileType === 'application/pdf'
                )
            }
            else if (value && typeof value === "string") {
                return true
            }
        })
        .test('fileSize', 'File size must be less than 5MB', (value: any) => {

            if (value && value instanceof File) {
                const fileSize = value.size;
                return fileSize <= 2 * 1024 * 1024; // 5MB
            }
            else if (value && typeof value === "string") {
                return true
            }
        })
});

export const BILL_PHONE_FORM_FIELDS: FormField[] = [
    {
        label: 'Bill URL',
        dataKey: 'billUrl',
        componentType: 'file_picker',
        componentProps: {
            placeholder: 'Enter bill URL',
            required: true,
        },
    },
    {
        label: 'Phone Number',
        dataKey: 'phoneNumber',
        componentType: 'input',
        componentProps: {
            type: "number",
            placeholder: 'Phone Number',
            maxLength: 10,
            regex: NUMBER_REGEX,
            required: true,
        },
    }
];