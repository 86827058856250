import { ArrowLeft, ArrowRight } from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { MdFirstPage, MdLastPage } from "react-icons/md";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { catchAsync, checkDecimal, convertToCamelCase } from "src/helpers";
import FilterMain from "src/modules/Marketplace/Filters/FilterMain";
import { getTempDataRootState, setShowLoginModule } from "src/redux/reducers/tempDataSlice";
import { RootState } from "src/redux/store";
import { Separator } from "src/shadcn/components/ui/separator";
import {
  QueryRequestProvider,
  useQueryRequest,
} from "./core/QueryRequestProvider";
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination
} from "./core/QueryResponseProvider";
import Marketplacesheet from "./Marketplacesheet";

import { useDispatch } from "react-redux";
import { useAuth } from "src/modules/Auth/Core";
import NewLayoutResultLoader from "src/modules/Marketplace/Loaders/NewLayoutResultLoader";
import NewLayoutResultFilter from "src/modules/Marketplace/Result/NewLayoutResultFilter";
import { MAIN_ROUTES_CONST } from "src/navigations/modules";
import { getAllProjectsSummaryRequestApi } from "src/services/requests/Projects";
import { ProjectSummaryApiResponse } from "src/services/requests/Projects/modules";
import { NUMBER_REGEX } from "../../helpers/crud-helper/consts";
import millify from "millify";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/shadcn/components/ui";
import LoadingDialog from "src/shadcn/components/custom/LoadingDialog";

type Props = {};

let icons: any = {
  totalProject: "⚡",
  "New RE Project Construction": "🏗",
  "Land For RE Projects": "🏗",
  "RE Project Capacity": "🏭"
}

const Main = (props: Props) => {

  const { userLocationDetails } = useSelector((state: RootState) =>
    getTempDataRootState(state)
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const element = document.getElementById("projects-list");
  const paginationRef = useRef<any>(null);
  const apiResponse = useQueryResponseData();
  const { response, refetch } = useQueryResponse();
  const projects = useMemo(() => apiResponse, [apiResponse]);
  const [allProjects, setallProjects] = useState<any[]>([])
  const isLoading = useQueryResponseLoading();
  const paggination = useQueryResponsePagination();
  const { state, updateState } = useQueryRequest();
  const { pathname } = useLocation()
  const [open, setopen] = useState(false);
  const { currentUser } = useAuth()
  const [fetchedPages, setFetchedPages] = useState(new Set());
  const [porjectAnalysis, setPorjectAnalysis] = useState<any>({ })


  useEffect(() => {
    projectAnalysis()
    return () => { }
  }, [])

  useEffect(() => {

    if (response?.data && response?.data.length > 0 ) {
      if(fetchedPages.size > 0){
        setallProjects([...allProjects, ...response?.data])
      }
      else setallProjects([...response?.data])
    }
    else setallProjects([])

    if (response?.pagination) {
      const element = document.getElementById(`project-item-${(response?.pagination?.page - 1) * 10}`);
      if (element) {
        const topPosition = element.getBoundingClientRect().top + (window.scrollY - 100);
        window.scrollTo({ top: topPosition, behavior: "smooth" });
      }
    }

    return () => { }
  }, [response])


  useEffect(() => {
    if (userLocationDetails?.address) {
      let spliteAddr = userLocationDetails?.address.split(",").reverse();
      // console.log({ spliteAddr });

      if (
        !spliteAddr.some(
          (d: any) =>
            d.toLowerCase().includes("maharashtra") ||
            d.toLowerCase().includes("uttar pradesh")
        )
      ) {
        navigate("/");
      }
    }
    return () => { };
  }, [userLocationDetails]);

  const handleFilters = (e: any) => {
    // console.log({ filter: e });
    delete e["price"];
    // delete e["consumerType"]

    if (Object.keys(e).filter((a: any) => a != "customerType").some((d: any) => e[d] !== "") && e?.customerType) {
      delete e["customerType"]

      updateState({
        page: 1,
        filter: e,
      });
      setFetchedPages(new Set());
    }
    else {
      updateState({
        page: 1,
        filter: e,
      });
      setFetchedPages(new Set());
    }
  };

  const projectAnalysis = catchAsync(async () => {
    return await getAllProjectsSummaryRequestApi()
  }, (result: ProjectSummaryApiResponse) => {
    // console.log({ result });

    if (result.status == 200) {
      let data = result.data;

      // let totalProjectCategoryTypes = data.totalProjectCategoryTypes.reduce((acc: any, curr: any) => {
      //   acc[curr.category] = curr.count
      //   return acc
      // }, {})

      // let totalCustomerTypes = data.totalCustomerTypes.reduce((acc: any, curr: any) => {
      //   acc[curr.category] = curr.count
      //   return acc
      // }, {})

      // console.log({ totalProjectCategoryTypes, totalCustomerTypes });

      let summaryData = {
        totalProject: data.totalProject,
        ...data.totalProjectCategoryTypes,
        "RE Project Capacity": data.totalProjectCategoryTypes["RE Project Capacity"] ? data.totalProjectCategoryTypes["RE Project Capacity"] : {
          "totalBuyOrders": 0,
          "totalSellOrders": 0,
          "totalBuyOrdersSize": 0,
          "totalSellOrdersSize": 0,
          "totalSize": 0,
          "totalCount": 0
        }
      }

      setPorjectAnalysis(summaryData)
    }
  }, () => { })


  useEffect(() => {
    let timer: any;
    if (fetchedPages.size == 0) {
      if (!currentUser && pathname === `/${MAIN_ROUTES_CONST?.marketplace}`) {
        timer = setTimeout(() => {
          dispatch(setShowLoginModule(true));
        }, 180000);
      }

    }

    return () => {
      // Clear the timeout on component unmount
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const elementID = useMemo(() => response?.pagination && `project-item-${((response?.pagination?.page > 1 ? Number(response?.pagination?.page) : 1) * 10)}`, [response]);

  const isElementInViewport = (element: any) => {
    if (!element) return false;
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) - 200 &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const handleScroll = () => {
    if (response && elementID) {
      const targetElement = document.getElementById(elementID);
      if (isElementInViewport(targetElement)) {
        const currentPage = response?.pagination?.page;
        if (currentPage && currentPage < Number(paggination?.last_page) && !fetchedPages.has(currentPage)) {
          // Mark the current page as fetched
          setFetchedPages((prev) => new Set(prev).add(currentPage));
          // Update state and refetch data
          updateState({
            page: currentPage + 1,
            items_per_page: 12
          });

          refetch();
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [fetchedPages, response?.pagination]); // Add dependencies to re-run effect if needed


  return (
    <div className="w-full mb-32  md:mb-32 h-full">

      {/* <div className="mb-8 h-auto">
        <video autoPlay loop muted style={{ width: "1580px" }}>
          <source src={VIDEO} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div> */}

      <div className="w-full flex flex-col gap-6 pt-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6 px-4">
          {
            Object.keys(porjectAnalysis).map((key: any, index: number) => {
              return <Card title={key} value={porjectAnalysis[key]} icon={icons[key] || ""} key={index} />
            })
          }
        </div>

        <div className="hidden lg:block md:hidden xl:block ">
          <FilterMain onChange={handleFilters} />
        </div>

        <div className="lg:hidden xl:hidden flex ml-4 sticky top-0 md:sticky z-50 bg-white">
          <Marketplacesheet
            children={
              <FilterMain
                onChange={handleFilters}
                open={open}
                setopen={setopen}
              />
            }
            open={open}
            setopen={setopen}
          />
        </div>
        {/* 
        <div className="md:flex md:justify-between md:items-center px-14 md:px-28 ">
          <div className="uppercase text-primary font-bold">Projects</div>
          <div className="md:flex md:space-x-2">
            <div className="flex text-sm">
              <span className="text-gray-400">Total : </span>
              <span>{paggination?.total_records || 0}</span>
            </div>
            <Separator orientation="vertical" className="mx-1" />
            <div className="flex text-sm">
              <span className="text-gray-400">Total Project Size : </span>
              <span>{response?.extraData?.totalProjectSizeKW ? (response?.extraData?.totalProjectSizeKW / 1000).toFixed(0) : 0} MW</span>
            </div>
          </div>
        </div> */}

        {/* <div
          id="projects-list"
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 w-full md:w-[90vw] mx-auto p-4 h-auto"
        > */}

        {/* {isLoading ? (
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-4 2xl:gap-8 w-full px-28">
            {[...new Array(response?.pagination?.page ? Number(Number(response?.pagination?.page) < Number(response?.pagination?.last_page) ? response?.pagination?.page + 1 : response?.pagination?.page) * Number(response?.pagination?.items_per_page) : 3)].map((_, index: number) => {
              return <NewLayoutResultLoader key={index} />;
            })}
          </div>
        ) : ( */}
          <NewLayoutResultFilter projects={allProjects || []} loading={isLoading} />
          
        {/* )} */}

        {/* </div> */}

        <div ref={paginationRef} className="flex items-center justify-center  ">
          <div className="flex items-center space-x-2">
            <button
              className="border p-2 rounded-md"
              onClick={() => {
                updateState({
                  ...state,
                  page: 1,
                });
                handleScroll();
              }}
              disabled={paggination?.page === 1}
            >
              <MdFirstPage />
            </button>
            <button
              className="border p-2 rounded-md"
              onClick={() => {
                updateState({
                  ...state,
                  page: paggination?.page !== 1 ? paggination?.page - 1 : 1,

                });
                handleScroll();
              }}
              disabled={paggination?.page === 1}
            >
              <ArrowLeft />
            </button>
            <span>
              Page {paggination?.page} of {paggination?.total_pages}
            </span>
            <button
              className="border p-2 rounded-md"
              onClick={() => {
                updateState({
                  ...state,
                  page: Number(paggination?.page) + 1,
                });
                handleScroll();
              }}
              disabled={
                paggination.page && paggination?.last_page
                  ? Number(paggination.page) === Number(paggination?.last_page)
                  : true
              }
            >
              <ArrowRight />
            </button>
            <button
              className="border p-2 rounded-md"
              onClick={() => {
                updateState({
                  ...state,
                  page: Number(paggination?.last_page),

                });
                handleScroll();
              }}
              disabled={
                paggination.page && paggination?.last_page
                  ? Number(paggination.page) === Number(paggination?.last_page)
                  : true
              }
            >
              <MdLastPage />
            </button>
          </div>
        </div>
        <LoadingDialog isOpen={isLoading} />
      </div>

    </div >
  );
};

const MarketplaceMain = () => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <Main />
      </QueryResponseProvider>
    </QueryRequestProvider>
  );
};

type CardProps = {
  title: string;
  value: any;
  icon: string;
};

const Card: React.FC<CardProps> = ({ title, value, icon }) => {
  return (
    <div className="bg-white p-6 rounded-xl border-2 flex flex-col text-center space-y-4">
      {/* <span className="text-4xl mb-2">{icon}</span>
      <h3 className="text-lg font-semibold">{title}</h3>
      <p className="text-gray-500 text-xl font-bold">{value}</p> */}
      <div className="flex justify-between">
        <div className="flex space-x-1">
          <div>{icon}</div>
          <div className="font-semibold">{convertToCamelCase(title)}</div>
        </div>
        {/* <div className="flex space-x-1">
          <div># {Number(value?.totalBuyOrders) + Number(value?.totalSellOrders)}</div>
          <Separator orientation="vertical" />
          <div>{(parseFloat(value?.totalSize) / 1000).toFixed(0)} MW</div>
        </div> */}

      </div>
      {/* <div className="flex space-x-2">
        <div className="flex-1 flex-col items-center">
          <div className="text-sm mb-1 text-gray-500">Total</div>
          <div className="text-sm "><span className="text-gray-500">#</span> <span className="font-semibold">{checkDecimal((Number(value?.totalBuyOrders) + Number(value?.totalSellOrders)).toString(), true)}</span></div>
        </div>
        <Separator orientation="vertical" />
        <div className="flex-1 flex-col items-center">
          <div className="text-sm text-nowrap  mb-1  text-gray-500 ">Size</div>
          <div className="text-sm font-semibold text-nowrap"> {value?.totalSize > 1000000 ? ((value?.totalSize / 1000) / 1000).toFixed(2) + ' GW' : (value?.totalSize / 1000).toFixed(0) + " MW"}</div>
        </div>
        <Separator orientation="vertical" />
        <div className="flex-1 flex-col items-center">
          <div className="text-sm  mb-1  text-gray-500">Buy</div>
          <div className="text-sm"><span className="text-gray-500">#</span> <span className="font-semibold">{checkDecimal(value?.totalBuyOrders, true)}</span></div>
        </div>
        <Separator orientation="vertical" />
        <div className="flex-1 flex-col items-center">
          <div className="text-sm  mb-1  text-gray-500">Sell</div>
          <div className="text-sm "><span className="text-gray-500" >#</span> <span className="font-semibold">{checkDecimal(value?.totalSellOrders, true)}</span></div>
        </div>
      </div> */}
      <div>
        <Table>
          <TableHeader className="bg-gray-100 p-0">
            <TableHead className="h-7"></TableHead>
            <TableHead className="h-7 text-xs text-center">Count</TableHead>
            <TableHead className="h-7 text-xs text-center">Size</TableHead>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell className="text-sm text-gray-500">Buy</TableCell>
              <TableCell className="text-sm">{checkDecimal(value?.totalBuyOrders, true)}</TableCell>
              <TableCell className="text-sm">{checkDecimal(((parseFloat(value?.totalBuyOrdersSize) / 1000).toString()), true)} MW</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="text-sm text-gray-500">Sell</TableCell>
              <TableCell className="text-sm">{checkDecimal(value?.totalSellOrders, true)}</TableCell>
              <TableCell className="text-sm">{checkDecimal(((parseFloat(value?.totalSellOrdersSize) / 1000).toString()), true)} MW</TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell className="text-sm text-gray-500">Total</TableCell>
              <TableCell className="text-sm">{checkDecimal(value?.totalCount, true)}</TableCell>
              <TableCell className="text-sm">{checkDecimal(((parseFloat(value?.totalSize) / 1000).toString()), true)} MW</TableCell>
            </TableRow> */}
          </TableBody>
        </Table>
      </div>
      {/* {
        value && Array.isArray(value) ? <div className="flex ">{
          value.map((item, index) => {
            return (
              <div className="flex-1 flex-col items-center">
                <div key={index} className="flex justify-center text-gray-500 text-sm ">{item.title}</div>
                <div key={index} className="flex justify-center text-gray-500 text-md font-bold">{item.value}</div>
              </div>
            )
          })
        }</div> : <div className="text-gray-500 text-2xl font-bold pt-4">{value}</div>
      } */}
    </div>
  );
};

export default MarketplaceMain;
