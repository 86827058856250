import { AtSign, KeyRound } from "lucide-react"
import { FormField } from "src/helpers"
import * as Yup from "yup"

export interface IProfile {
    firstName: string,
    lastName: string,
    email: string,
    mobileNumber: string,
    abouteMe: string
}

export const ProfileInit = {
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    abouteMe: ""
}

export interface IChangePassword {
    password: string
    confirm_password: string
    mobileNumber: string,
}

export const changePassword_initialValues: IChangePassword = {
    mobileNumber: '',
    password: '',
    confirm_password: "",
};

export const profileFormValidationSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('First name is required')
        .max(50, 'First name must be 50 characters or less'),
    lastName: Yup.string()
        .required('Last name is required')
        .max(50, 'Last name must be 50 characters or less'),
    email: Yup.string()
        .email('Invalid email address').optional(),
        // .required('Email is required'),
    mobileNumber: Yup.string()
        .required('Mobile number is required')
        .matches(/^[0-9]{10}$/, 'Invalid mobile number'),
    // abouteMe: Yup.string()
    //     .required('About me is required')
    //     .max(200, 'About me must be 200 characters or less'),
});


export const changePasswordFormValidationSchema = Yup.object().shape({
    mobileNumber: Yup.string()
        .length(10,"mobile number must be 10 digits")
        .required('Email is required'),
    password: Yup.string()
        .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/,"Password should be at least 1 capital letter , 1 special character, 1 small letter")
        .min(8,"Minimum 8 characters")
        .max(16,"Maximum 16 characters")
        .required('Password is required'),
    confirm_password: Yup.string()
        .required('Password is required')
        .test('passwords-match', 'Passwords not match', function (value) {
            return this.parent.password === value;
        }),
});

export const PROFILE_FORM_FIELDS: FormField[] = [
    {
        label: 'First Name',
        dataKey: 'firstName',
        componentType: 'input',
        componentProps: {
            type: 'text',
            placeholder: 'Enter first name',
            regex: /[^A-Za-z ]+/g,
        }
    },
    {
        label: 'Last Name',
        dataKey: 'lastName',
        componentType: 'input',
        componentProps: {
            type: 'text',
            placeholder: 'Enter last name',
            regex: /[^A-Za-z ]+/g,
        }
    },
    {
        label: 'Email',
        dataKey: 'email',
        componentType: 'input',
        componentProps: {
            type: 'email',
            placeholder: 'Enter email',
            
        }
    },
    {
        label: 'Mobile Number',
        dataKey: 'mobileNumber',
        componentType: 'input',
        componentProps: {
            type: 'text',
            placeholder: 'Enter mobile number',
            regex: /[^0-9]+/g,
            maxLength:10,
            disabled: true
        }
    },
    {
        label: 'About Me',
        dataKey: 'abouteMe',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'Enter about me',
            regex: /[^A-Za-z-/, ]+/g,
        }
    },
];

export const CHANGE_PASSWORD_FORM_FIELDS: FormField[] = [
    {
        label: 'Mobile Number',
        dataKey: 'mobileNumber',
        componentType: 'input',
        componentProps: {
            type: 'number',
            disabled: true
        }
    },
    {
        label: 'Password',
        dataKey: 'password',
        componentType: 'input',
        componentProps: {
            type: 'password',
            inputPrefixIcon: <KeyRound className='text-gray-500' size={16} />
        }
    },
    {
        label: 'Confirm Password',
        dataKey: 'confirm_password',
        componentType: 'input',
        componentProps: {
            type: 'password',
            inputPrefixIcon: <KeyRound className='text-gray-500' size={16} />
        }
    }
];