    
    import {

        Transaction_Advisory_Services,
        Project_Management_Consultancy,
        Energy_Audit_Services
      } from "src/helpers";
    const advisoryConsultacyData = [
        {
          title: "Transaction Advisory Services",
          description_1: "Navigating the complexities of renewable energy transactions requires expertise, market insights, and strategic planning. Our Transaction Advisory Services help businesses, investors, and developers in structuring successful deals for buying, selling, or financing renewable energy projects. We offer:",
          image: Transaction_Advisory_Services,
          list: [
            "Due diligence & risk assessment to ensure informed decision-making.",
            "Financial modeling & valuation for project feasibility analysis.",
            "Regulatory & compliance guidance to streamline approvals.",
            "Investor matchmaking & deal structuring to secure funding and partnerships."
          ],
          description_2:"With our data-driven approach and deep industry expertise, we ensure transactions that are seamless, secure, and financially rewarding."
        },
        {
          title: "Project Management Consultancy (PMC) Services",
          description_1: "Successful execution of renewable energy projects requires meticulous planning, coordination, and execution. Our PMC Services cover every stage of the project lifecycle, ensuring efficiency, quality, and compliance. We assist in:",
          image: Project_Management_Consultancy,
          list: [
            "Project feasibility & site assessments to determine the best-fit solutions.",
            "Vendor selection & contract management for reliable execution.",
            "Timely project tracking & risk mitigation to avoid delays.",
            "Regulatory & compliance adherence for smooth project approvals."
          ],
          description_2 :"From concept to commissioning, we provide end-to-end project oversight, ensuring that your renewable energy investment is optimized for maximum efficiency and long-term success."
        },
        {
          title: "Energy Audit Services",
          description_1: "Uncover hidden energy inefficiencies and reduce operational costs with our comprehensive Energy Audit Services. We conduct detailed evaluations of energy consumption patterns and provide customized solutions to enhance energy efficiency for industrial, commercial, and institutional setups. Our services include:",
          image: Energy_Audit_Services,
          list: [
            "Energy consumption analysis to identify wastage and inefficiencies.",
            "Load optimization strategies to improve energy performance.",
            "Recommendations on renewable energy integration for cost savings.",
            "Compliance with energy efficiency standards to enhance sustainability."
          ],
          description_2:"By implementing our recommendations, businesses can reduce energy bills, improve operational efficiency, and contribute to a greener future."
        }
      ];

      export {advisoryConsultacyData}