import React from "react";
import { cn } from "src/helpers";
interface AdvisoryConsultancyCardProps {
  imageName: string;
  title: string;
  description_1: string;
  description_2: string;
  listArray: string[];
  indexNumbers:number
}
const AdvisoryConsultancyCard = ({
  imageName,
  title,
  description_1,
  description_2,
  listArray,
  indexNumbers
}: AdvisoryConsultancyCardProps) => {
    // console.log(indexNumbers%2)
  return (
    <div className={cn("w-full max-w-[80%] mx-auto flex flex-col lg:flex-row  items-center justify-center space-y-6 md:space-y-0 lg:space-x-10 md:p-6 p-3 bg-gradient-to-r from-[#F7F7F7] to-[#FFFFFF] rounded-lg shadow-lg",{" flex lg:flex-row-reverse bg-gradient-to-l from-[#F7F7F7] to-[#FFFFFF]":indexNumbers % 2 !==0})}>
      <div className="flex flex-col items-center justify-center w-full lg:w-[40%]">
        <img
          src={imageName}
          alt={title}
          className=" rounded- object-cover"
        />
        {/* <div className="text-[#908585] text-base sm:text-lg font-bold mt-2">
                  Mr. Shailesh Joshi
                </div> */}
      </div>
      <div className="text-base font-normal font-sans w-full lg:w-[60%] space-y-3 text-center md:text-left">
        <h1 className="text-2xl font-bold text-black text-left">{title}</h1>
        <p className="text-base font-medium text-left">{description_1}</p>
        <ul style={{ listStyleType: "disc" }} className="ml-10 text-sm">
          {Array.isArray(listArray) && listArray?.length > 0 ? (
            listArray.map((cur: string, index: number) => (
              <li key={index} className="text-left">{cur}</li>
            ))
          ) : (
            <></>
          )}
        </ul>
        <p className="text-base font-medium text-left">{description_2}</p>
      </div>
    </div>
  );
};

export default AdvisoryConsultancyCard;
