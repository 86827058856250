import React from 'react'
import { IMG_P2P_ENERGY_BENEFITS_BG, IMG_P2P_ENERGY_SCREEN_1, IMG_ROUNDED_1, IMG_ROUNDED_2, IMG_ROUNDED_3 } from 'src/helpers'

type Props = {}

const P2penrgyBenefits = (props: Props) => {
    return (
        <div className='md:h-[600px] p-8' style={{ backgroundImage: "url(" + IMG_P2P_ENERGY_BENEFITS_BG + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>
            <div className='max-w-[80vw] mx-auto space-y-10  md:space-y-4'>
                <div className='flex flex-col justify-center items-center space-y-2'>
                    <span className='text-sm uppercase '>Peer-to-Peer Energy for a Greener Tomorrow</span>
                    <span className='h-[2px] bg-primary w-[50px]'></span>
                    <span className='font-bold text-3xl'>Benefits of P2P Energy Trading </span>
                </div>
                <div className=' md:grid md:grid-cols-2 md:mt-16 items-center' >
                    <div>
                        <img src={IMG_P2P_ENERGY_SCREEN_1} style={{ height: "400px" }} />
                    </div>
                    <div className='flex justify-center mt-[50px] md:mt-0' >
                        <div className='space-y-8 max-w-[80%]  relative'>
                            <div className='flex space-x-2 items-center  '>
                                <img src={IMG_ROUNDED_1} style={{ height: "50px",zIndex:10}} />
                                <div>Turn your excess energy into earnings or buy clean energy directly from your community.</div>
                            </div>
                            <div className='flex space-x-2 items-center  '>
                                <img src={IMG_ROUNDED_2} style={{ height: "50px",zIndex:10}} />
                                <div>Get clean, renewable energy at lower prices than the traditional grid.</div>
                            </div>
                            <div className='flex space-x-2 items-center  '>
                                <img src={IMG_ROUNDED_3} style={{ height: "50px",zIndex:10}} />
                                <div>Support a sustainable energy system and reduce carbon emissions by trading clean energy.</div>
                            </div>
                            <div className='absolute h-[50%] top-0 bg-gray-50 w-[30px] border-r-2 border-r-primary border-dotted'></div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default P2penrgyBenefits