import { MAIN_ROUTES_CONST } from "src/navigations/modules";
import { toAbsoluteUrl as IMG_FAQ_EQUATION_MARK, toAbsoluteUrl } from "../AssetHelpers";
import { ClassNameValue } from "tailwind-merge";


const convertArrayToOptionType = (data: string[]) => data.map((d: string) => ({ label: d, value: d }))


export const ERROR_CODES = [400, 500, 404];

export type optionTypes = {
  label: string,
  value: string,
  icons?: any
}

export type componentType = 'input' | 'inputicon' | 'options' | 'date_picker' | 'file_picker' | "textarea" | "time-range-piker";
export type componentProps = {
  type?: 'email' | 'password' | 'phone' | 'text' | 'url' | 'number',
  className?: string,
  placeholder?: string
  regex?: RegExp,
  maxLength?: number,
  uppercase?: boolean,
  options?: optionTypes[],
  inputSuffixIcon?: any,
  inputPrefixIcon?: any,
  required?: boolean,
  readonly?: boolean,
  disabled?: boolean,
  showCharacterLength?: boolean,
  fileSelectionType?: "single" | "multi",
  numberFormatted?: boolean,
  labelStyle?: ClassNameValue,
  description?: string
  numberPattern?: string
  conditionalDisabled?: {
    formKey: string,
    expectedValue: string
  },
  inputWidth?: number,
  hide?: {
    formKey: string,
    expectedValue: string
  },
  conditionalHide?: {
    formKey: string,
    expectedValue: string | string[]
  } & Record<string, any>;
  error_className?: ClassNameValue
}

export interface FormField {
  label: string;
  dataKey: string;
  value?: string,
  componentType: componentType,
  componentProps?: componentProps
}


export const NAV_TABS = {
  home: MAIN_ROUTES_CONST.welcome,
  marketplace: MAIN_ROUTES_CONST.marketplace,
  solarKit: MAIN_ROUTES_CONST.solarkit,
  contact_us: MAIN_ROUTES_CONST.contact_us
}

export const APP_NAV_TABS = {
  // dashboard: MAIN_ROUTES_CONST.dashboard,
  marketplace: MAIN_ROUTES_CONST.marketplace,
  solarkit: MAIN_ROUTES_CONST.solarkit,
  enquiry_request: MAIN_ROUTES_CONST.new_enquiry_request,
  contact_us: MAIN_ROUTES_CONST.contact_us
}

export const HERO_IMG_1 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882532124_hero-img-1.png";
export const IMG_FEATURE_P2P_ENERGY_TRADING = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882537793_featutre_p2p_energy_trading.png";
export const IMG_FEATURE_P2P_CROWDFUNDING = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882541183_featutre_p2p_crowed_funding.png";
export const IMG_FEATURE_P2P_MARKETPLACE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882544181_featutre_p2p_market_place.png";
export const IMG_DISCOVERS_BG = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882546300_discover_bg.png";
export const IMG_FEATURE_INFO_1 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882548064_info1.png";
export const SVG_FEATURE_INFO_1 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882552443_info1.svg";
export const IMG_FEATURE_INFO_2 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882555727_info2.png";
export const IMG_FEATURE_INFO_3 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882557845_info3.png";
export const IMG_FEATURE_INFO_4 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882560399_info4.png";
export const IMG_FEATURE_INFO_5 = toAbsoluteUrl("/media/png/info5.png")
export const ICON_ENERGY_SUN = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882562595_energy_sun.svg";
export const ICON_GREEN_ENERGY = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882563882_green_energy.svg";
export const ICON_BASKET = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882565827_basket.svg";
export const ICON_PAYMENT_RECEIVE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882567510_payment_receive.svg";
export const ICON_EMAIL = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882568917_email.svg";
export const IMG_FAQ_BANNER = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882571265_faq_banner.png";
export const IMG_ABOUT_US_BANNER = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882574758_about_us_banner.jpg";
export const IMG_CONTACT_US_BANNER = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882577669_contact_us_banner.jpg";
export const IMG_CROWDFUNDING_BANNER = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882581825_croudfunding_banner.png";
export const IMG_GREEN_ENERGY_BANNER = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882585534_green_energy_banner.png";
export const IMG_CROWDFUNDING_SELL_ENERGY = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882588077_sellEnergy.png";
export const IMG_CROWDFUNDING_CROWD_FUNDING = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882590011_crowd-funding.png";
export const IMG_CROWDFUNDING_BUY_ENERGY = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882591566_BuyersFundig.png";
export const IMG_GREEN_ENERGY_AGGREMENT = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882592985_Agreement.png";
export const IMG_P2P_ENERGY_TRADING = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882599262_p2p_energy_trading.jpg";
export const IMG_GREEN_ENERGY_TOKENIZED = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882608185_TokenizedSolar.png";
export const IMG_GREEN_ENERGY_VIRTUAL_ROOFTOP = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882610221_Virtual_Rooftop.png";
export const ICON_CURLY = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882611544_curly.png";
export const ICON_PROJECT_SIZE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882612673_project_size.png";
export const ICON_PROJECT_TYPE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882613934_project_type.png";
export const ICON_ROOF_TOP = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882615247_roof-top.png";
export const ICON_SEND = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882616335_send.png";
export const ICON_RECEIVE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882617661_receive.png";
export const ICON_PROFIT_AS_BUYER = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882619207_profitAsBuyerIcon.png";
export const ICON_PROFIT_AS_SALER = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882620199_profitAsSallerIcon.png";
export const ICON_WALLET = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882621553_Wallet.png";
export const ICON_OCT_VERIFIED = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882622958_oct-verified.png";
export const IMG_EPC_COMPANY_PLACEHOLDER_LOGO = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882624465_company-logo.png";
export const IMG_EPC_COMPANY_LOGO = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882625734_Nous.png";
export const IMG_SOLAR_ROOF_TOP = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882627467_solar-rooftops.png";
export const IMG_SOLAR_FARMS = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882630248_solar-farms.png";
export const IMG_SOLAR_WATER_PUMP = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882633010_Solar-Water-Pumps.png";
export const IMG_SAMPLE_PANEL_1 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882634931_sample_panel_1.png";
export const IMG_SAMPLE_PANEL_2 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882636418_sample_panel_2.png";
export const IMG_SERVICES_BANNER = toAbsoluteUrl("media/svg/image/ServiceSolarImg.svg")
export const IMG_ROUNDED_1 = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661688922_rounded_1.png";
export const IMG_ROUNDED_2 = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661686712_rounded_2.png";
export const IMG_ROUNDED_3 = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661685561_rounded_3.png";
export const IMG_LAND_LEASING_BENEFITS = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661744851_Leasing_Land_Benifits.png"
export const IMG_WELCOME_SERVICES_BANNER_BG = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882638680_WelcomeServicesBg.png";
export const IMG_QUOTE_IMG = toAbsoluteUrl("/media/jpg/quote_img.jpg")
export const IMG_BLOCKCHAIN_BANNER = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882642171_BlackchainServiceBanner.png";
export const IMG_FAQ = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882644223_faq.svg";
export const IMG_ProductsBanner = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882646211_ProductsBanner.png";
export const IMG_BLOCKCHAIN_IMG = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882648901_blackchain_img.web";
export const IMG_P2P_ENERGY_BANNER = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882651196_p2p_energy_banner.png";
export const IMG_P2P_ENERGY_BENEFITS_BG = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882653637_p2p_energy_benifits_bg.png";
export const IMG_P2P_ENERGY_SCREEN_1 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882655617_p2p_energy_screen_1.png";
export const IMG_P2P_ENERGY_HOW_IT_WORKS = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882657496_p2p_energy_how_it_works.webp";
export const IMG_P2P_ENERGY_OUR_PRODUCTS = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882660322_p2p_enenrgy_our_products.png";
export const IMG_P2P_ENERGY_JOINING_TEXTURE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882662950_p2p_energy_joining.png";
export const IMG_EMPTY_ROUND = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882664302_empty_round.png";
export const IMG_P2P_DOWNLOAD_APP_BG_BANNER = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882666107_p2p_energy_app_bg_banner.png";
export const IMG_P2P_DOWNLOAD_APP = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882668116_p2p_energy_app.png";
export const IMG_TREE_ICON = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882669418_tree_icon.png";
export const IMG_GOOGLE_PLAY = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882670446_googleplay.png";
export const IMG_SERVICES_BANNER_2 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882672807_services_banner.png";
export const HERO_BANNER_1 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882675274_Banner1.webp";
export const WELCOME_SERVICE_BG = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882676879_welcome_service_bg.webp";
export const IMG_COMMERCIAL_SERVICES_REFERENCES_BG = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882680254_CommercialServiceReferencesBg.png";

export const CoMMERCIAL_SERVICES_REFERENCES_PRODUCTS_ROOFTOP_AREA = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882684081_RooftopArea.png";
export const CoMMERCIAL_SERVICES_REFERENCES_PRODUCTS_TURNKEY_OPEN_FIELD = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882686993_TurnkeyOpenField.png";
export const CoMMERCIAL_SERVICES_REFERENCES_PRODUCTS_PROJECT_RIGHTS1 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882689199_ProjectRights1.png";
export const CoMMERCIAL_SERVICES_REFERENCES_PRODUCTS_PROJECT_RIGHTS2 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882691197_ProjectRights2.png";
export const SECTION_BG = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882692715_SectionBG.webp";
export const HERO_BANNER_2 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882694425_Banner2.webp";
export const HERO_BANNER_3 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882696094_Banner3.webp";
export const HERO_BANNER_4 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882698765_Banner4.png";
export const HERO_BANNER_5 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882701994_Banner5.png";
export const RESIDENTIAL_BANNER = "https://p2p-marketplace-production-bucket.s3.amazonaws.com/1738655633877_Recidential_banner.png";
export const RESPONSIVE_BG = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661756021_banner_bg.png";
export const RESPONSIVE_BANNER_1 = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661747867_banner_1.png";
export const RESPONSIVE_BANNER_2 = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661753505_banner_2.png";
export const RESPONSIVE_BANNER_3 = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661761914_banner_3.png";
export const RESPONSIVE_BANNER_4 =  "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661764567_banner_4.png";
export const RESPONSIVE_BANNER_5 =  "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661758951_banner_5.png";
export const HAND_SHAK_IMG = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882704421_hand_shak_img.webp";
export const ANNOTATION_ICON = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882705871_anotation_icon.png";
export const CELL_BACKGROUND_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882708301_ProjectDetailsBanner.png"
export const PROJECT_IMAGE_URL = "https://images.unsplash.com/photo-1497440001374-f26997328c1b?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
export const REFER_AND_EARN_IMAGE_URL = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882710878_ReferAndEarn.svg";
export const HEADER_MAIN_REFER_NOW = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882712388_Refer_Now.svg";
export const MODULE_HOW_IT_WORKS1 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882713469_re_how_it_works_1.png";
export const MODULE_HOW_IT_WORKS2 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882714507_re_how_it_works_2.png";
export const MODULE_HOW_IT_WORKS3 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882715521_re_how_it_works_3.png";
export const MODULE_AMBASSADORS1 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882716482_Ambassdor1.png";
export const MODULE_AMBASSADORS2 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882717471_Ambassdor2.png";
export const MODULE_AMBASSADORS3 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882718500_Ambassdor3.png";
export const MODULE_TESTIMONIALS1 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882719721_avatar1.jpg";
export const MODULE_TESTIMONIALS2 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882720751_avatar2.jpg";
export const MODULE_TESTIMONIALS3 = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882721892_avatar3.jpg";
export const REFERAL_FORM_IMAGE = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661679559_refer_and_earn1.png";
export const REFER_AND_EARN_DETAILS_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882723069_refer_and_earn2.png";
export const REFER_AND_EARN_DETAILS_SUN_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882724029_sun.png";
export const REFER_AND_EARN_DETAILS_BULB_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882724929_bulb.png";
export const COSTOMER_JOURNEY_SOLAR_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882725915_SolarDeal.svg";
export const COSTOMER_JOURNEY_OUTCOME_SOLAR_IMAGE = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661920768_Solar-Img-customerJourney.svg"
export const COSTOMER_JOURNEY_OUTCOME_LITTER_OF_WATER_IMAGE = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661937292_LittersOfWater.svg"
export const COSTOMER_JOURNEY_OUTCOME_TONS_OF_CO2_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882727301_TonesOfCo2.svg";
export const COSTOMER_JOURNEY_OUTCOME_TREES_SEEDING_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882729251_TreesSeedingIcon.svg";
export const COSTOMER_JOURNEY_OUTCOME_LIFETIME_PRODUCTION_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882732163_LifetimeProduction.svg";
export const COSTOMER_JOURNEY_OUTCOME_ANNUAL_ELECTRICITY_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882734100_PeopleAnnualElectricity.svg";
export const SOLAR_POWER_GENERATION_RUPEE_ICON = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882735951_Ruppe.svg";
export const SOLAR_POWER_GENERATION_PERCENTAGE_ICON = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882738744_percentage.svg";
export const SOLAR_POWER_GENERATION_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882740671_solar-power-genration.svg";
export const SOLAR_POWER_PLANT_CAPACITY_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882742565_solar-powerplant-capacity.svg";
export const ENERGY_SUN_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882744648_ExesiveEnergy_2.svg";
export const WATER_SAVE_BACKGROUND_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882746129_WaterSaveBackgroundImg.svg";
export const COMMERCIAL_SERVICE_BANNER_IMAGE = toAbsoluteUrl("media/svg/image/ServiceSolarImg.svg");
export const LEASE_LAND_SERVICE_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882749000_Lease-Rent-services.svg";
export const ROOFTOP_SERVICE_SOLAR_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882752269_RoughTopSolar-services.svg";
export const GROUND_MOUNTED_SERVICE_IMAGE = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661904751_Ground-mounted-services.svg";
export const PROJECTS_RIGHTS_SERVICE_IMAGE = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661871460_Project-right-services.svg";
export const NEW_CONSTRUCTED_SERVICE_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882754912_New-constructed-service.svg";
export const EXHISTING_PROJECT_SERVICE_IMAGE = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661927628_SolarOfExtingProject.svg";
export const FINANCE_SERVICE_IMAGE = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661892227_Finace-services.svg";
export const SOLAR_EPC_SERVICE_IMAGE = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661883217_Solar-epc-Services.svg";
export const OandM_SERVICE_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882758105_Solar-O%26M.svg";
export const PRODUCT_SERVICE_IMAGE = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661914804_Products-services.svg";
export const ELECTRICITY_BANNER_IMAGE = toAbsoluteUrl("/media/svg/Electricity_PM.svg");
export const ELECTRICITY_EM_BANNER_IMAGE = toAbsoluteUrl("/media/svg/Electricity_EM.svg");
export const MONEY_BAG_RUPEE_IMAGE = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661940770_money-bag-rupee.svg";
export const ENVIORNMENT_CARE_IMAGE = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661941794_environment-care.svg";
export const RENEWABLE_ENERGY_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882760214_renewable-energy.svg";
export const TRUSTED_EXPERTS_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882761483_TrustedExperts.svg";
export const AFFORDABLE_SOLUTIONS_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882762732_AffordableSolutions.svg";
export const END_TO_END_SUPPORT_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882763761_End_to_EndSupport.svg";
export const SUSTAINABILITY_FOCUS_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882764719_SustainabilityFocus.svg";
export const EXTENSIVE_NETWORK_IMAGE = toAbsoluteUrl("/media/svg/ExtensiveNetwork.svg");
export const CUSTOMER_SATISFACTION_IMAGE = toAbsoluteUrl("/media/svg/CustomerSatisfaction.svg");
export const WHAT_WE_DO_BACKGROUND_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882766202_what_We_do_background.svg";
export const RROF_TOP_SOLAR_PLANT_IMAGE = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661930611_Roof_tough_solar_plant.svg";
export const SOLAR_FINANCE_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882774800_SolarFinance.svg";
export const EPC_CONSULTANT_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882781891_BestEpcCONSULTANT.svg";
export const LEASE_OUT_LAND_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882784667_leaseOutLand.svg";
export const RENT_OUT_ROOF_IMAGE = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661897199_RentOutRoofTought.svg";
export const SELL_EXHISTING_PROJECT =  "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661924425_SellExitingProject.svg";
export const SOLAR_FORM_PROJECT = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661886140_SOLARfORM.svg";
export const PRODUCTS_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882787291_products.png";
export const VIDEO = toAbsoluteUrl("/media/adds/marketplace/Add3.mp4");
export const OUR_TEAM_IMAGE = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882790385_OurTeamBg.png";
export const P2P_BANNER = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882792487_P2PBanner.webp";
export const MISSION_ICON = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882795676_mission_icon.png";
export const VISION_ICON = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1732882796828_vision_icon.png";
export const MISSION_AND_VISSION_BANNER = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661750892_MissionAndVissioBanner.png";
export const SOLAR_PLANT_ICON = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661677995_solar-plant.png";

export const FINANCE_MAIN = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661829923_finance_main.jpg";
export const FINANCE_SERVICE1 = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661823150_service1.png";
export const FINANCE_SERVICE2 = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661824497_service2.png";
export const FINANCE_SERVICE3 = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661821721_service3.png";
export const FINANCE_SERVICE4 = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661818153_service4.png";
export const FINANCE_SERVICE5 = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661816749_service5.png";
export const FINANCE_SERVICE6 = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661828137_service6.png";
export const FINANCE_BG = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661826424_finance_bg.jpg";
export const FEATURES_BG = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661819958_finance_bg1.jpg";

export const LOAN_STEP1 = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661768325_loan_step_1.png";
export const LOAN_STEP2 = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661766810_loan_step_2.png";
export const LOAN_STEP3 = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661771893_loan_step_3.png";
export const LOAN_STEP4 = "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661769913_loan_step_4.png";

export const BRAND_ICON_SM = "/media/svg/brand/brand-sm.svg"
export const BRAND_ICON_MD = "/media/svg/brand/brand-md.svg"
export const NEW_BRAND_ICON_MD = "/media/svg/brand/new_brand.svg"
export const ADVISORY_MANAGAMENT_IMG=toAbsoluteUrl("/media/png/Services/Advisory/Management_Advisors.png")
export const Abhishek_Khare=toAbsoluteUrl("/media/png/Services/Advisory/Abhishek_Khare.png")
export const Shailesh_Joshi=toAbsoluteUrl("/media/png/Services/Advisory/Shailesh_Joshi.png")
export const Vijay_Dhonde=toAbsoluteUrl("/media/png/Services/Advisory/Vijay_Dhonde.png")
export const WHATSAPP_ICON = "https://p2p-marketplace.s3.ap-south-1.amazonaws.com/1740139926814_icons8-whatsapp-64.png"

export const Transaction_Advisory_Services=toAbsoluteUrl("/media/png/Services/Advisory_Consultancy/Transaction_Advisory_Services.png")
export const Project_Management_Consultancy=toAbsoluteUrl("/media/png/Services/Advisory_Consultancy/Project_Management.png")
export const Energy_Audit_Services=toAbsoluteUrl("/media/png/Services/Advisory_Consultancy/Energy_Audit_Services.png")
export const successTheme = {
  background: '#fff',
  color: "hsl(var(--primary))",
  borderRadius: '8px',
  padding: '16px',
};

export const errorTheme = {
  background: '#FF5722',
  color: '#FFF',
  borderRadius: '8px',
  padding: '16px',
};


export const BG_SKIN_COLOR_LIGHT = "bg-[#E8E3E3]"


export const USER_ROLES = {
  // "INVESTOR": "investor",
  // "OFF_TAKER": "offtakers",
  "INDUSTRIAL_COMMERCIAL_OWNER": "Industrial/Commercial Owner",
  "RENEWABLE_ENERGY_DEVELOPER": "Renewable Energy Developer",
  "EPC_SPECIALIST": "EPC Specialist",
  "PROPERTY_OWNER": "Property Owner",
  "MNRE_VENDOR": "MNRE Vendor",
  "POWER_SECTOR_CONSULTANT": "Power Sector Consultant",
  "ENERGY_AUDITOR": "Energy Auditor",
  "BD_EXECUTIVE": "BD Executive",
  "REAL_ESTATE_AGENT": "Real Estate Agent",
  "GREEN_ENERGY_CUSTOMER": "Green Energy Customer"
}


export const NUMBER_REGEX = /[^0-9.]+/g
export const PHONE_NUMBER_REGEX = /[^0-9]+/g
export const EMAIL_REGEX = /[^a-z0-9.@]+/g
export const NAME_REGEX = /[^A-Za-z ]+/g;
export const EXTRACT_NUMBERS_REGEX = /[^A-z0-9.]+/g
export const LAT_LONG_REGEX = /[^0-9., ]+/g
export const ADDRESS_REGEX = /[^A-z0-9/-_, ]+/g
export const EXTRACT_STRING_REGEX = /[^A-Za-z ]+/g;
export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,18}$/


export const SESSIONS = {
  FORGOT_PASSWORD: "FORGOT-PASSWORD",
  REGISTRATION: "REGISTRATION"
}

export const ORDER_STATUS_ENUM = {
  "PENDING": "PENDING",
  "SHIPPED": "SHIPPED",
  "DELIVERED": "DELIVERED",
  "CANCELLED": "CANCELLED",
}

export const BADGE_STATUS_VARIANT = {
  "PENDING": "warning",
  "SHIPPED": "warning",
  "DELIVERED": "success",
  "CANCELLED": "destructive",
  "PAID": "success",
}

export const PAYMENT_STATUS_ENUM = {
  "PENDING": "PENDING",
  "PAID": "PAID",
  "CANCELLED": "CANCELLED",
}


export const PROJECT_CATEGORY_OPTIONS = [
  {
    "label": "Community Solar",
    "value": "Community Solar"
  },
  {
    "label": "Ground-Mounted",
    "value": "Ground-Mounted"
  },
  {
    "label": "Floating Solar",
    "value": "Floating Solar"
  },
  {
    "label": "Solar Parks",
    "value": "Solar Parks"
  },
  {
    "label": "Rooftop Solar",
    "value": "Rooftop Solar"
  },
  {
    "label": "Carport Solar",
    "value": "Carport Solar"
  },
  {
    "label": "Hybrid (Solar + Wind +Battery)",
    "value": "Hybrid (Solar + Wind +Battery)"
  },
  {
    "label": "BESS (Energy Storage System)",
    "value": "BESS (Energy Storage System)"
  }
]

export const PROJECT_TYPES = [
  // { label: 'Matchmaking', value: 'Matchmaking' },
  { label: 'GROUND MOUNTED', value: 'GROUND MOUNTED' },
  { label: 'ROOF-TOP', value: 'ROOF TOP' },
  // { label: 'EaaS', value: 'EaaS' },
  // { label: 'VPP', value: 'VPP' },
];

export const PROPERTY_TYPE_OPTIONS = [{
  "label": "Rented",
  "value": "Rented"
}, {
  "label": "Ownership",
  "value": "Ownership"
}];

export const BUILDING_TYPES = [{
  "label": "Residential",
  "value": "Residential"
}, {
  "label": "Commercial",
  "value": "Commercial"
}, {
  "label": "Industrial",
  "value": "Industrial"
}];



export const AUTHORIZED_PARTNER_OPTIONS = [{
  "label": "Gold",
  "value": "Gold"
}, {
  "label": "Platinum",
  "value": "Platinum"
}, {
  "label": "Silver",
  "value": "Silver"
}];



export const VERIFIED_UNVERIFIED_OPTIONS = [{
  "label": "Verified",
  "value": "Verified"
}, {
  "label": "Unverified",
  "value": "Unverified"
}];



export const SYSTEM_TYPES = [
  {
    "label": "Photovoltaic(PV)",
    "value": "Photovoltaic"
  },
  {
    "label": "Solar Thermal",
    "value": "Solar Thermal"
  },
  {
    "label": "Off-Grid Solar",
    "value": "Off-Grid Solar"
  },
  {
    "label": "On-Grid Solar",
    "value": "On-Grid Solar"
  },
  {
    "label": "Hybrid Systems",
    "value": "Hybrid Systems"
  },
  {
    "label": "Concentrated Solar Power (CSP)",
    "value": "Concentrated Solar Power (CSP)"
  },
  {
    "label": "Building-Integrated Photovoltaic (BIPV)",
    "value": "Building-Integrated Photovoltaic (BIPV)"
  },
  {
    "label": "Floating Solar",
    "value": "Floating Solar"
  },
  {
    "label": "Agrivoltaics (Agro-Solar)",
    "value": "Agrivoltaics (Agro-Solar)"
  }
]

export const PROJECT_LAND_TYPES = [
  { label: 'Residential', value: 'Residential' },
  { label: 'Commercial', value: 'Commercial' },
  { label: 'Industrial', value: 'Industrial' },
  { label: 'Agricultural', value: 'Agricultural' },
  { label: 'Institutional', value: 'Institutional' },
  { label: 'Government', value: 'Government' },
];

export const SERVICE_TYPES = [
  { label: 'Residential', value: 'Residential' },
  { label: 'Commercial', value: 'Commercial' },
  { label: 'Industrial', value: 'Industrial' },
  // { label: 'Agricultural', value: 'Agricultural' },
  // { label: 'Institutional', value: 'Institutional' },
  // { label: 'Government', value: 'Government' },
];


export const DUMMY_OPTIONS = [
  {
    label: 'Type 1',
    value: 'type1',
  },
  {
    label: 'Type 2',
    value: 'type2',
  },
]


export const indianNumberFormatter = new Intl.NumberFormat('en-IN', {
  maximumFractionDigits: 2,
  style: 'currency',
  currency: 'INR'
});

export const AVAILABLE_SPACE_UNITS_OPTIONS = [
  { label: "SqFt", value: "SqFt" },
  { label: "meter", value: "m" },
  { label: "feet", value: "ft" },
  { label: "SqM", value: "SqM" },
]

export const PROPERTY_TYPE_ICONS = {
  "BOOT": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738659827720_boot.png",
  "GROUND MOUNTED": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661858391_8_Ground%20Mounted%20final.svg",
  "ROOF TOP": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661846658_9_Rooftop%20final.svg",
  "Rooftop": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661846658_9_Rooftop%20final.svg",
  "Ground Mounted": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661858391_8_Ground%20Mounted%20final.svg",
}

// export const PROPERTY_TYPE_ICONS = {
//   "BOOT": IMG_FAQ_EQUATION_MARK("/media/png/boot.png"),
//   "GROUND MOUNTED": IMG_FAQ_EQUATION_MARK("/media/png/boot.png"),
// }

export const COMMERCIAL_AND_INDUSTRIAL_PROJECT_OPTIONS = ['Buy Project Rights', 'Buy Existing Projects', 'Buy New Construction Projects', 'Lease Roof Areas', 'Offer Land For Utility Scale Project'].map((d: string) => ({ label: d, value: d }))

export const RESIDENTIAL_AND_SOCIETY_PROJECT_OPTIONS = ['Competitive finance', 'Solar EPC', 'Rooftop Solar'].map((d: string) => ({ label: d, value: d }))

export const PROJECT_CATEGORY_ICON = {
  "Buy Project Rights": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661850443_Buy%20Project%20Rights%20Final.svg",
  "Buy Existing Projects": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661841148_Buy%20Existing%20Projects%20final.svg",
  "Buy New Construction Projects": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661838569_Buy%20New%20Construction%20Projects%20final.svg",
  "Lease Roof Areas": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661834270_Lease%20Roof%20Areas%20Final.svg",
  "Offer Land For Utility Scale Project": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661835485_Offer%20Land%20For%20Utility%20Scale%20Project%20Final.svg",
  "Solar EPC": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661835485_Offer%20Land%20For%20Utility%20Scale%20Project%20Final.svg",
  "Rooftop SOlar": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661846658_9_Rooftop%20final.svg",
  "Competitive Finance": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661835485_Offer%20Land%20For%20Utility%20Scale%20Project%20Final.svg"
}

export const CUSTOMER_TYPE_ICON = {
  "Commercial": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661848240_Commercial.svg",
  "Industrial": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661860904_Industrial.svg",
  "Residential": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661845635_Residential.svg",
  "Society": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661849285_Societies.svg"
};

export const BUILDING_TYPE_ICONS = {
  "Residential": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661687835_property-residential.png",
  "Commercial": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661690017_property-commercial.png",
  "Industrial": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661683982_property-industry.png"
}

export const QUERY_TYPE_OPTIONS = [
  { label: "Account", value: "Account" },
  { label: "Loan Request", value: "Loan Request" },
  { label: "Project Information", value: "Project Information" },
  { label: "General Enquiry", value: "General Enquiry" },
  { label: "Transaction", value: "Transaction" },
  { label: "Project Rights", value: "Project Rights" },
  { label: "Buy Existing Project", value: "Buy Existing Project" },
  { label: "Buy New Construction Project", value: "Buy New Construction Project" },
  { label: "Rooftop Solar Systems", value: "Rooftop Solar Systems" },
  { label: "Land Lease For Solar", value: "Land Lease For Solar" },
  { label: "Solar EPC for Societies", value: "Solar EPC for Societies" },




]

export const DISCOMS_OPTIONS = [
  { label: "Account", value: "Account" },
  { label: "Loan Request", value: "Loan Request" },
  { label: "Project Information", value: "Project Information" },
  { label: "General Enquiry", value: "General Enquiry" },
  { label: "Transaction", value: "Transaction" },
  // { label: "Investor", value: "Investor" },
  // { label: "Offtaker", value: "Offtaker" }
]

export const EPC_CONTRACTORS_SPECIALIZATIONS = [
  "Solar farm design and engineering",
  "Procurement of solar panels, inverters, and other equipment",
  "Construction and installation of solar farms",
  "Routine maintenance and repair of solar farms",
  "Performance monitoring and optimization",
  "Troubleshooting and fault resolution",
  "Electrical infrastructure design and installation",
  "Electrical testing and commissioning",
  "Electrical maintenance and repair",
  "Site preparation and grading",
  "Foundation design and installation",
  "Road construction and maintenance",
  "Environmental impact assessments",
  "Permitting and compliance",
  "Wildlife habitat assessments and mitigation"
]

export const MANUFACTURERS_SPECIALIZATIONS = [
  "Monocrystalline silicon solar panels",
  "Polycrystalline silicon solar panels",
  "Thin-film solar panels",
  "Bifacial solar panels",
  "String inverters",
  "Central inverters",
  "Microinverters",
  "Power optimizers",
  "Fixed-tilt mounting systems",
  "Tracking mounting systems",
  "Roof-top mounting systems",
  "Ground-mounted systems",
  "Lithium-ion battery systems",
  "Lead-acid battery systems",
  "Flow battery systems",
  "Sodium-ion battery systems",
  "Data loggers",
  "Monitoring software",
  "Control systems for inverters and trackers",
  "Weather stations"
]

export const OTHER_SPECIALIZATIONS = [
  "Residential solar installations",
  "Commercial solar installations",
  "Utility-scale solar installations",
  "String inverter installation",
  "Central inverter installation",
  "Microinverter installation",
  "Electrical testing and inspection",
  "Commissioning of electrical systems",
  "Electrical safety auditing",
  "Preventive maintenance",
  "Corrective maintenance",
  "Predictive maintenance",
  "Energy audits and assessments",
  "Energy efficiency recommendations",
  "Energy management and monitoring"
]


export const ENUM_SERVICE_ENQUIRY_TYPE = {
  "landOwner": "landOwner",
  "roofTopSolar": "roofTopSolar",
  "groundMounted": "groundMounted",
  "projectRights": "projectRights",
  "newConstructedProjects": "newConstructedProjects",
  "sellExistingProject": "sellExistingProject",
  "finance": "finance",
  "solarEPC": "solarEPC",
  "solarOandM": "solarOandM"
}

export const ENQUIRY_TYPES_INSTREST: optionTypes[] = [
  {
    label: "Buy",
    value: "BUY"
  },
  {
    label: "Sell",
    value: "SELL"
  },
]

export const ENQUIRY_SERVICE_TYPES: optionTypes[] = [
  {
    label: "Land Owner",
    value: "landOwner"
  },
  {
    label: "Rooftop Solar",
    value: "roofTopSolar"
  },
  {
    label: "Ground Mounted",
    value: "groundMounted"
  },
  {
    label: "Project Rights",
    value: "projectRights"
  },
  {
    label: "New Constructed Projects",
    value: "newConstructedProjects"
  },
  {
    label: "Sell Existing Project",
    value: "sellExistingProject"
  },
  {
    label: "Finance",
    value: "finance"
  },
  {
    label: "Solar EPC",
    value: "solarEPC"
  },
  {
    label: "Solar O&M",
    value: "solarOandM"
  }
];

export const SYSTEM_TYPE_OPTIONS = [
  {
    "label": "Photovoltaic(PV)",
    "value": "Photovoltaic(PV)"
  },
  {
    "label": "Solar Thermal",
    "value": "Solar Thermal"
  },
  {
    "label": "Off-Grid Solar",
    "value": "Off-Grid Solar"
  },
  {
    "label": "On-Grid Solar",
    "value": "On-Grid Solar"
  },
  {
    "label": "Hybrid Systems",
    "value": "Hybrid Systems"
  },
  {
    "label": "Concentrated Solar Power(CSP)",
    "value": "Concentrated Solar Power(CSP)"
  },
  {
    "label": "Building-Integrated Photovoltaic(BIPV)",
    "value": "Building-Integrated Photovoltaic(BIPV)"
  },
  {
    "label": "Floating Solar",
    "value": "Floating Solar"
  },
  {
    "label": "Agrivoltaics (Agro-Solar)",
    "value": "Agrivoltaics (Agro-Solar)"
  }
]

export const YEARS_RANGE_OPTIONS = [
  {
    "label": "Less than 1 Years",
    "value": "Less than 1 Years"
  },
  {
    "label": "1-5 Years",
    "value": "1-5 Years"
  },
  {
    "label": "5-10 Years",
    "value": "5-10 Years"
  },
  {
    "label": "10-15 Years",
    "value": "10-15 Years"
  },
  {
    "label": "15-20 Years",
    "value": "15-20 Years"
  },
  {
    "label": "More than 20 Years",
    "value": "More than 20 Years"
  }
]

export const CONNECTION_TYPE_OPTIONS = [
  { label: "Single Phase", value: "Single Phase" },
  { label: "Three Phase (LV)", value: "Three Phase (LV)" },
  { label: "Three Phase (MV)", value: "Three Phase (MV)" }
]

export const TYPE_OF_SPACE_ENUM = {
  "Rooftop": "Rooftop",
  "Land": "Land"
}

export const TYPE_OF_SPACE_OPTIONS = [{
  "label": "Rooftop",
  "value": "Rooftop"
}, {
  "label": "Land",
  "value": "Land"
}]

export const ROOF_MATERIAL_OPTIONS = [
  {
    "label": "RCC",
    "value": "RCC"
  },
  {
    "label": "Metal Sheet",
    "value": "Metal Sheet"
  },
  {
    "label": "Coaler",
    "value": "Coaler"
  },
  {
    "label": "Other",
    "value": "Other"
  }
]

export const ROOF_ORIENTATION = [
  {
    label: "North",
    value: "North",
  },
  {
    label: "South",
    value: "South",
  },
  {
    label: "East",
    value: "East",
  },
  {
    label: "West",
    value: "West",
  },
];

export const ROOFTOP_MATERIAL_OPTIONS = [
  {
    label: "Concrete",
    value: "Concrete",
  },
  {
    label: "Tin",
    value: "Tin",
  },
  {
    label: "GI Sheet",
    value: "GI Sheet",
  },
  {
    label: "Clay Tiles",
    value: "Clay Tiles",
  },
  {
    label: "Fiber Sheets",
    value: "Fiber Sheets",
  },
  {
    label: "Other",
    value: "Other",
  },

]

export const SHADING_OPTIONS = [
  {
    label: "No Shading",
    value: "No Shading",
  },
  {
    label: "Partial Shading",
    value: "Partial Shading",
  },
  {
    label: "Full Shading",
    value: "Full Shading",
  },
];


export const ROOFTOP_PROPERTY_TYPE_OPTIONS = [
  {
    label: "Residential",
    value: "Residential",
  },
  {
    label: "Commercial",
    value: "Commercial",
  },
  {
    label: "Industrial",
    value: "Industrial",
  },
  {
    label: "Institutional",
    value: "Institutional",
  },];

export const ROOF_TYPE_OPTIONS = [
  {
    label: "Flat Roof",
    value: "Flat Roof"
  },
  {
    label: "Sloped Roof",
    value: "Sloped Roof"
  },
  {
    label: "Metal Roof",
    value: "Metal Roof"
  },
  {
    label: "RCC",
    value: "RCC"
  },
  {
    label: "Asbestos",
    value: "Asbestos"
  },
  {
    label: "Tiles",
    value: "Tiles"
  },
  {
    label: "Other",
    value: "Other"
  }
];

export const SOLAR_SYSTEM_TYPE_OPTIONS = [
  {
    label: "On-Grid",
    value: "On-Grid"
  },
  {
    label: "Off-Grid",
    value: "Off-Grid"
  },
  {
    label: "Hybrid",
    value: "Hybrid"
  }
];


export const DAY_TIME_UNITS_OPTIONS = [
  { label: "Days", value: "Days" },
  { label: "Years", value: "Years" },
  { label: "Months", value: "Months" },
]


export const PROJECT_OWNERSHIP_TYPE_OPTIONS = [
  { label: "Independent Power Producer", value: "Independent Power Producer" },
  { label: "Utility-Owned", value: "Utility-Owned" },
  { label: "Joint Venture", value: "Joint Venture" },
  { label: "Special Purpose Vehicle", value: "Special Purpose Vehicle" },
  { label: "Government-Owned", value: "Government-Owned" }
]

export const DC_COMMISIONING_TYPE_OPTIONS = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" }
]

export const TERM_PLAN_OPTIONS = [...Array(10)].map((_, indx: number) => {
  return { label: (indx + 1) + " Years", value: (indx + 1).toString() }
})


export const STATUS = {
  New: 'New',
  Approved: 'Approved',
  Active: 'Active',
  Completed: 'Completed',
  Rejected: 'Rejected',
  Closed: 'Closed'
}

export type ISTATUS =
  | "New"
  | "Approved"
  | "Active"
  | "Completed"
  | "Rejected"
  | "Closed";



export const APPROVAL_REJECT_OPTIONS = [{
  "label": "Approved",
  "value": "Approved"
}, {
  "label": "Rejected",
  "value": "Rejected"
}];


export const ENUM_APPROVE_REJECT = {
  "Approved": "Approved",
  "Rejected": "Rejected"
}


export const TRANSMISSION_CURRENT_STATUS_OPTIONS = [{
  "label": "In-Progress",
  "value": "In_Progress"
}, {
  "label": "Completed",
  "value": "Completed"
}];


export const LAND_OWNERSHIP_STATUS_OPTIONS = [{
  "label": "Freehold",
  "value": "Freehold"
}, {
  "label": "Leasehold",
  "value": "Leasehold"
}];

export const LAND_ACQUISITION_STATUS_OPTIONS = [{
  "label": "Completed",
  "value": "Completed"
}, {
  "label": "In-Progress",
  "value": "In_Progress"
},
{
  "label": "Pending",
  "value": "Pending"
}];



export const TYPE_OF_SOLAR_PLANT_OPTIONS = [{
  "label": "Ground-Mounted",
  "value": "Ground-Mounted"
}, {
  "label": "Rooftop",
  "value": "Rooftop"
}, {
  "label": "Floating",
  "value": "Floating"
}, {
  "label": "Hybrid",
  "value": "Hybrid"
}];

export const PROJECT_CONNECTION_TYPE_OPTIONS = [{
  "label": "On-Grid",
  "value": "On-Grid"
}, {
  "label": "Off-Grid",
  "value": "Off-Grid"
}, {
  "label": "Hybrid",
  "value": "Hybrid"
}];


export const PROJECT_PLANT_STATUS_OPTIONS = [{
  "label": "Under Development",
  "value": "Under Development"
}, {
  "label": "Operational",
  "value": "Operational"
}, {
  "label": "Commissioned",
  "value": "Commissioned"
}, {
  "label": "Completed",
  "value": "Completed"
}];


export const PLANT_STATUS_OPTIONS_2 = [{
  "label": "Under Maintenance",
  "value": "Under Maintenance"
}, {
  "label": "Operational",
  "value": "Operational"
}, {
  "label": "Partially Operational",
  "value": "Partially Operational"
},];

export const PROJECT_SOLAR_TECHNOLOGY_OPTIONS = [{
  "label": "Photovoltaic (PV)",
  "value": "Photovoltaic (PV)"
}, {
  "label": "Concentrated Solar Power (CSP)",
  "value": "Concentrated Solar Power (CSP)"
}, {
  "label": "Other",
  "value": "Other"
}];

export const PROJECT_PROJECT_OWNERSHIP_TYPE_OPTIONS = [{
  "label": "Developer",
  "value": "Developer"
}, {
  "label": "Owner",
  "value": "Owner"
}];

export const OWNERSHIP_STATUS_OPTIONS = [{
  "label": "Owned",
  "value": "Owned"
}, {
  "label": "Joint Ownership",
  "value": "Joint Ownership"
}, {
  "label": "Leased",
  "value": "Leased"
}];


export const PROJECT_SOLAR_MODULES_TYPE_OPTIONS = [{
  "label": "Monocrystalline",
  "value": "Monocrystalline"
}, {
  "label": "Polycrystalline",
  "value": "Polycrystalline"
}, {
  "label": "Thin Film",
  "value": "Thin Film"
}, {
  "label": "Other",
  "value": "Other"
}];

// New constructed projects field arrays
export const TYPE_OF_SOLAR_PLANT = [
  {
    label: "Ground-Mounted",
    value: "Ground-Mounted",
  },
  {
    label: "Rooftop",
    value: "Rooftop",
  },
  {
    label: "Floating",
    value: "Floating",
  },
  {
    label: "Hybrid",
    value: "Hybrid",
  },
];

export const CONNECTION_TYPE = [
  {
    label: "On-Grid",
    value: "On-Grid",
  },
  {
    label: "Off-Grid",
    value: "Off-Grid",
  },
  {
    label: "Hybrid",
    value: "Hybrid",
  },
];

export const PLANT_STATUS = [
  {
    label: "Under Development",
    value: "Under Development",
  },
  {
    label: "Operational",
    value: "Operational",
  },
  {
    label: "Commissioned",
    value: "Commissioned",
  },
  {
    label: "Completed",
    value: "Completed",
  },
];

export const SOLAR_TECHNOLOGY_USED = [
  {
    label: "Photovoltaic (PV)",
    value: "Photovoltaic (PV)",
  },
  {
    label: "Concentrated Solar Power (CSP)",
    value: "Concentrated Solar Power (CSP)",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const PROJECT_OWNERSHIP_TYPE = [
  {
    label: "Developer",
    value: "Developer",
  },
  {
    label: "Owner",
    value: "Owner",
  },
];


export const TYPE_OF_SOLAR_MODULES_USED = [
  {
    label: "Monocrystalline",
    value: "Monocrystalline",
  },
  {
    label: "Polycrystalline",
    value: "Polycrystalline",
  },
  {
    label: "Thin Film",
    value: "Thin Film",
  },
  {
    label: "Other",
    value: "Other",
  },
];


export const DOCUMENTS_UPLOAD = [
  {
    label: "Power Purchase Agreement",
    value: "Power Purchase Agreement",
  },
  {
    label: "Ownership Documents",
    value: "Ownership Documents",
  },
  {
    label: "Legal Clearance",
    value: "Legal Clearance",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const STATES_OPTIONS = [
  "Maharashtra",
  "Uttar Pradesh",
].map((d: any) => ({ label: d, value: d }))


export const SPECIALIZATION_OPTIONS = [...EPC_CONTRACTORS_SPECIALIZATIONS, ...MANUFACTURERS_SPECIALIZATIONS, ...OTHER_SPECIALIZATIONS].map((d: any) => ({ label: d, value: d }))

export const IS_SUCCESS_RESPONSE = (result: any) => [200, 201].includes(result?.status || result?.statsu || result?.code) && (result?.success ? result?.success : true)
export const PANEL_WIDTH = 1.2
export const PANEL_HEIGHT = 2.3
export const SINGLE_PANEL_AREA_IN_SQ_M = (PANEL_WIDTH * PANEL_HEIGHT)

export const PROJECT_PROJECT_CONSTRUCTION_PROPERTY_DETAILS_LAND_TYPE_OPTIONS = convertArrayToOptionType(['Private', 'Public', 'Leasehold'])
export const PROJECT_PROJECT_CONSTRUCTION_CONSTRUCTION_TIMELINE_STATUS_OPTIONS = convertArrayToOptionType(['Not Started', 'In Progress', 'Completed']);
export const PROJECT_PROJECT_CONSTRUCTION_CONSTRUCTION_PHASE_OPTIONS = convertArrayToOptionType(['Planning', 'Execution', 'Completed']);
export const PROJECT_PROJECT_CONSTRUCTION_SUBSTATION_TRANSMISSION_STATUS_OPTIONS = convertArrayToOptionType(['Work in Progress', 'Completed']);
export const PROJECT_PROJECT_CONSTRUCTION_APPROVAL_PERMITS_REQUIRED_OPTIONS = convertArrayToOptionType(['Environmental Clearance', 'Local Body Approval', 'Grid Connection Approval', 'Other']);
export const PROJECT_PROJECT_CONSTRUCTION_PERMIT_STATUS_OPTIONS = convertArrayToOptionType(['Approved', 'In Progress', 'Pending']);
export const PROJECT_CI_AND_RE_PROJECTS_BUILDING_TYPE_OPTIONS = convertArrayToOptionType(['Residential', 'Commercial', 'Industrial', 'Institutional']);
export const PROJECT_CI_AND_RE_PROJECTS_ROOF_TYPE_OPTIONS = convertArrayToOptionType(['RCC', 'Tiled', 'Metal', 'Asbestos']);
export const PROJECT_CI_AND_RE_PROJECTS_ROOF_CONDITION_OPTIONS = convertArrayToOptionType(['Good', 'Fair', 'Poor']);
export const PROJECT_CI_AND_RE_PROJECTS_OWNERSHIP_STATUS_OPTIONS = convertArrayToOptionType(['Freehold', 'Leasehold']);
export const PROJECT_CI_AND_RE_PROJECTS_ACQUISITION_STATUS_OPTIONS = convertArrayToOptionType(['Completed', 'In Progress', 'Pending']);
export const PROJECT_CI_AND_RE_PROJECTS_SYSTEM_TYPE_OPTIONS = convertArrayToOptionType(['On-grid', 'Off-grid', 'Hybrid']);
export const PROJECT_RE_PROJECT_CAPACITY_COMPANY_RATING_OPTIONS = convertArrayToOptionType(['A+', 'A', 'B+', 'B', 'C', 'D']);
export const PROJECT_RE_PROJECT_CAPACITY_INDUSTRY_TYPE_OPTIONS = convertArrayToOptionType(['Manufacturing', 'IT', 'Retail', 'Hospitality', 'Healthcare', 'Other']); // Added 'Other' for flexibility
export const PROJECT_RE_PROJECT_CAPACITY_PREFERRED_RENEWABLE_ENERGY_SOURCE_OPTIONS = convertArrayToOptionType(['Solar', 'Wind', 'Biomass', 'Hybrid']);
export const PROJECT_RE_PROJECT_CAPACITY_PREFERRED_ENERGY_CONTRACT_DURATION_OPTIONS = convertArrayToOptionType(['1 year', '3 years', '5 years', '10 years']);
export const PROJECT_RE_PROJECT_CAPACITY_INTERESTED_IN_PPA_OPTIONS = convertArrayToOptionType(['Yes', 'No']);
export const PROJECT_RE_PROJECT_CAPACITY_PPA_TERM_PREFERRED_OPTIONS = convertArrayToOptionType(['Short Term (<5 years)', 'Long Term (5+ years)']);
export const PROJECT_RE_PROJECT_CAPACITY_PREFERRED_CONNECTION_VOLTAGE_OPTIONS = convertArrayToOptionType(['LT', 'HT', 'EHV']);
export const PROJECT_RE_PROJECT_CAPACITY_WILLING_TO_PARTICIPATE_IN_P2P_TRADING_OPTIONS = convertArrayToOptionType(['Yes', 'No']);
export const PROJECT_RE_PROJECT_CAPACITY_HAS_SUSTAINABILITY_GOALS_OPTIONS = convertArrayToOptionType(['Yes', 'No']);
export const PROJECT_RE_PROJECT_CAPACITY_RENEWABLE_ENERGY_CERTIFICATION_REQUIRED_OPTIONS = convertArrayToOptionType(['Yes', 'No']);
export const PROJECT_RE_PROJECT_CAPACITY_EXISTING_SUBSTATION_CONNECTION_VOLTAGE_OPTIONS = convertArrayToOptionType(['11 KV', '33 KV', '66 KV', '132 KV', '220 KV', '400 KV']);

export const PROJECT_RE_PROJECT_RIGHTS_ENVIRONMENTAL_CLEARANCE_STATUS_OPTIONS = convertArrayToOptionType(['Approved', 'In Progress', 'Pending']);
export const PROJECT_RE_PROJECT_RIGHTS_GRID_CONNECTIVITY_APPROVAL_OPTIONS = convertArrayToOptionType(['Approved', 'In Progress', 'Pending']);
export const PROJECT_RE_PROJECT_RIGHTS_LAND_USE_PERMIT_OPTIONS = convertArrayToOptionType(['Approved', 'In Progress', 'Pending']);

export const PROJECT_RE_PROJECT_RIGHTS_TRANSMISSION_LINE_STATUS_OPTIONS = convertArrayToOptionType(['Work in Progress', 'Completed', 'Pending']);

export const PROJECT_RE_PROJECT_RIGHTS_FUNDING_SOURCE_OPTIONS = convertArrayToOptionType(['Self-Funded', 'Loan', 'Investment']);
export const PROJECT_RE_PROJECT_RIGHTS_POWER_PURCHASE_AGREEMENT_OPTIONS = convertArrayToOptionType(['Signed', 'In Progress', 'Pending']);
export const PROJECT_GENERAL_PROJECT_ORDER_TYPE_OPTIONS = convertArrayToOptionType(['Buy', 'Sell']);

export const CUSTOMER_TYPE_OPTIONS = ['Commercial', 'Industrial', 'Residential', 'Society'].map((d: any) => ({ label: d, value: d }))
export const CI_PROJECT_CATEGORY_TYPE_OPTIONS = convertArrayToOptionType([
  "RE Project Capacity", "RE Project Rights", "New RE Project Construction", "Land for RE Projects", "Rooftop for RE Projects", "Project Finance", "C&I RE Projects"
])


export const RS_PROJECT_CATEGORY_TYPE_OPTIONS = [
  "Lease Roof Areas",
  "Offer Land For Utility Scale Project",
  "Buy Project Rights",
  "Solar EPC",
  "Buy Existing Projects",
  "Competitive finance",
  "Buy New Construction Projects",
  "Rooftop Solar"
].map((d: any) => ({ label: d, value: d }))


export const YES_NO_OPTIONS = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
  // Add more options as needed
]
