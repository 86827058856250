import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import ProjectRightDetail from 'src/modules/Cell/ProjectRightDetail'
import ProjectDetails from 'src/modules/Layouts/ProjectDetails'

type Props = {}

const ProjectsRoute = (props: Props) => {
    return (
        <Routes>
            <Route element={<ProjectDetails />}>
                <Route index element={<ProjectRightDetail />} />
                <Route path={"/:id"} element={<ProjectRightDetail />} />
                <Route path={"*"} element={<Navigate to={'/' + "/p"} />} />
            </Route>
        </Routes>
    )
}

export default ProjectsRoute