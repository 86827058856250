import { useNavigate } from 'react-router-dom'
import { cn, ELECTRICITY_EM_BANNER_IMAGE, RESPONSIVE_BANNER_4, RESPONSIVE_BG } from 'src/helpers'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { Button, CarouselItem } from 'src/shadcn/components/ui'

type Props = {}

const Banner3 = (props: Props) => {

  const navigate = useNavigate()

  return (
    <CarouselItem>
      <div
        className={cn(`w-full xl:h-[650px] h-max relative`)}
        style={{
          background: `url(${RESPONSIVE_BG})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"

        }}
      >
        <div className='flex justify-center xl:justify-end xl:mt-4'>
          <img src={RESPONSIVE_BANNER_4} className='lg:h-[90%] xl:h-[100%] lg:right-0 lg:absolute xl:p-20 pt-24 px-4'/>
        </div>

        <div className=" md:w-[500px]    xl:w-[500px]  mx-auto lg:mx-0   space-y-3 xl:ml-40  pb-10 px-10 w-full">
          <div className='lg:pt-[15vh]'>
            <div className='md:w-[450px]'>
              <img src={ELECTRICITY_EM_BANNER_IMAGE} style={{ height: "100px" }} className='mb-4' />
              <div className='space-y-1'>
                <div className="font-sans font-bold text-3xl md:text-4xl uppercase "><span className=''>P2P Energy</span> trading </div>
                <div className="font-sans font-bold text-3xl md:text-4xl uppercase ">Solution</div>
              </div>
              <div className='h-[2px] bg-primary w-[380px] my-4'></div>
            </div>
            <div >

              <ul className='list-disc ml-4'>
                <li>Consumers save money.</li>
                <li>Producer maximizes income.</li>
                <li>Through every additional unit sale.</li>
                {/* <li>Benefit from 20 years of performance tracking and record management</li> */}
              </ul>
            </div>
            <div className='my-5'>
              P2P platform: Electreecity-EM truly helps connecting rural <span className='font-bold'>Bharat</span> as a producer with urban <span className='font-bold'>India's</span> consumers in rooftop adoption.</div>

          </div>
          <div className='flex space-x-2'>
            <Button
              onClick={() => {
                const violation: any = document.getElementById("welcome-p2p-energy-section");
                window.scrollTo({
                  top: violation.offsetTop - 50,
                  behavior: "smooth",

                });
              }}

              className='w-[150px] bg-primary text-white hover:bg-primary hover:text-white'>Learn More</Button>
            <Button onClick={() => navigate("/" + MAIN_ROUTES_CONST.contact_us)} variant={'theamBlack'} className='w-[150px]'>Contact Us</Button>
            <Button onClick={() => {
          window.open('https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1743511011648_Marketplace%20user%20manual_v2.pdf')
            }} className='w-[150px] bg-primary text-white hover:bg-primary hover:text-white'>User Manual</Button>
          
          </div>
        </div>
      </div>
    </CarouselItem>
  )
}

export default Banner3