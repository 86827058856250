import { useFormik } from 'formik'
import React, { useState } from 'react'
import { BILL_PHONE_FORM_FIELDS, initialValues, validationSchema } from './modules'
import { catchAsync, checkAnyFileToBeUpload, FormField, IS_SUCCESS_RESPONSE } from 'src/helpers'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import { Button } from 'src/shadcn/components/ui'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'

type Props = {
    onSubmit: (data: any) => void
}

const UplodeElectBillsForm = ({ onSubmit }: Props) => {


    const [isLoading, setisLoading] = useState<boolean>(false)

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit(values, formikHelpers) {
            handleCheckAnyFilesToBeUpload()
        },
    })

    const handleCheckAnyFilesToBeUpload = catchAsync(async () => {
        setisLoading(true)
        return await checkAnyFileToBeUpload(formik.values)
    }, (result: any) => {
        if (result[1]) {
            // handleCreateRequest({ ...result[0] })
            onSubmit({ ...result[0] })
            console.log({ result: { ...result[0] } });
        }
        setisLoading(false)
    }, () => { setisLoading(false) })


    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                {
                    BILL_PHONE_FORM_FIELDS.map((field: FormField) => {
                        return <div><RenderFormComponents key={`new-user-bill-form-${field.dataKey}`} formik={formik} value={formik.values[field.dataKey as keyof object]} {...field} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} /></div>
                    })
                }
                <div className='my-4 flex justify-center'>
                    <Button isLoading={isLoading} className='w-[150px]'>Submit</Button>
                </div>
            </form>
            <LoadingDialog isOpen={isLoading} />
        </>
    );
}

export default UplodeElectBillsForm