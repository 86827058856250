import { useFormik } from 'formik'
import React, { useState } from 'react'
import { CAPTURE_WHATSAPP_INTEREST_FORM_FIELDS, CAPTURE_WHATSAPP_INTEREST_FORM_VALIDATION, INIT_CAPTURE_WHATSAPP_INTEREST_FORM } from './modules'
import { catchAsync, FormField, IS_SUCCESS_RESPONSE } from 'src/helpers'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import { Button } from 'src/shadcn/components/ui'
import { sendCaptureWpInterestFormApiRequest } from 'src/services/requests/CaptureWhatsAppInterest'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import CustomeDialog from 'src/shadcn/components/custom/CustomeDialog'
import { CheckCircle } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

type Props = {}

const CaptureWhatAppInterestForm = (props: Props) => {

    const [isLoading, setisLoading] = useState<boolean>(false)
    const [openDialog, setopenDialog] = useState<boolean>(false)
    const navigate = useNavigate()
    const formik = useFormik({
        initialValues: INIT_CAPTURE_WHATSAPP_INTEREST_FORM,
        validationSchema: CAPTURE_WHATSAPP_INTEREST_FORM_VALIDATION,
        onSubmit(values, formikHelpers) {
            handleSubmitRequest()
        },
    })

    const handleSubmitRequest = catchAsync(async () => {
        setisLoading(true)
        return await sendCaptureWpInterestFormApiRequest(formik.values)
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            setisLoading(false)
            setopenDialog(true)
        }
    }, () => { setisLoading(false) })


    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                {
                    CAPTURE_WHATSAPP_INTEREST_FORM_FIELDS.map((field: FormField) => {
                        return <div><RenderFormComponents key={`new-enquiry-form-${field.dataKey}`} formik={formik} value={formik.values[field.dataKey as keyof object]} {...field} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} /></div>
                    })
                }
                <div className='flex justify-center mt-4'>
                    <Button className='min-w-[150px]'>
                        <span>Submit</span>
                    </Button>
                </div>
            </form>
            <LoadingDialog isOpen={isLoading} />
            <CustomeDialog isOpen={openDialog} className='md:max-w-lg' headerTitle={
                <div className='flex flex-col items-center w-full  space-y-2 text-primary uppercase'>
                    <CheckCircle size={42} />
                    <span className='font-semibold'>Thank you !</span>
                </div>
            } hideDefaultCancle={true} onSubmit={() => navigate("/")} >
                <p className='pb-4'>
                    Request Sent successfully, Our team will contact you withing 24-48 hrs.
                </p>
            </CustomeDialog>
        </>
    );
}

export default CaptureWhatAppInterestForm