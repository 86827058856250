import React, { useMemo } from 'react'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '../ui'

type Props = {}

const DocumentViwer = ({ url, title }: { url: string, title?: string }) => {

    const extention = useMemo(() => {
        if(url){
            return url.slice((url.lastIndexOf(".") - 1 >>> 0) + 2);
        }
     
    }, [url])


    console.log({ extention });

    return (
        // url ?: null
        <div>
            {
                url && extention && ["jpeg", "jpg", "png", "jfif"].includes(extention) ?
                    <PhotoProvider>
                        <PhotoView src={url}>
                            <div className='text-blue-500 text-xs font-semibold underline underline-offset-2 cursor-pointer'>{title ?  title : "View"}</div>
                        </PhotoView>
                    </PhotoProvider>
                    : null
            }
            {url && extention && extention == "mp4" ? <Dialog>
                <DialogTrigger>
                    <div className='text-blue-500 text-xs font-semibold underline underline-offset-2 cursor-pointer'>{title ?  title : "View"}</div>
                </DialogTrigger>
                <DialogContent>
                    <DialogHeader>
                        <DialogDescription>
                            <video autoPlay controls={true} className="w-full">
                                <source src={url} type="video/mp4" />
                            </video>
                        </DialogDescription>
                    </DialogHeader>
                </DialogContent>
            </Dialog> : null}
        </div>




    )
}

export default DocumentViwer