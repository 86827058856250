import React from 'react'
import { ClassNameValue } from 'tailwind-merge';
import { Label, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../ui';
import { cn } from 'src/helpers';
import { InfoIcon } from 'lucide-react';
import TimeRangePicker from './TimeRangePicker';

type Props = {
    formik: any;
    label: string;
    nameKey: string;
    placeholder?: string;
    lableClass?: string;
    defaultValue?: string;
    value?: string;
    required?: boolean;
    onchange: (e: any) => void;
    disabled?: boolean,
    showInfoIcon?: boolean
    infoContent?: string,
    toYear?: number
    fromYear?: number,
    labelStyle?: ClassNameValue,
    error_className?: ClassNameValue,
}

const FromikTimeRangePicker = ({
    formik,
    placeholder,
    nameKey,
    label,
    lableClass,
    defaultValue,
    value,
    required,
    onchange,
    disabled,
    showInfoIcon,
    infoContent,
    toYear,
    fromYear,
    labelStyle,
    error_className
}: Props) => {

    return (
        <>
            <div className="flex justify-between mt-2">
                <Label className={cn(" text-xs", labelStyle, `${required ? "required" : ""}`)}>
                    {label}
                </Label>
                {showInfoIcon ? (
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger onClick={(event: any) => event.preventDefault()} >
                                <InfoIcon className="w-[16px] h-[16px] text-gray-500 cursor-pointer" />
                            </TooltipTrigger>
                            <TooltipContent>
                                <div className="w-[200px]">{infoContent}</div>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                ) : null}
            </div >
            <div className="mt-2">
                <TimeRangePicker start_time={value && value?.split("-")[0]} end_time={value && value?.split("-")[1]} onChange={({ start_time, end_time }) => onchange(`${start_time}-${end_time}`)} />
            </div>
            {
                formik.touched[nameKey] && formik.errors[nameKey] && (
                    <div className={cn("text-xs font-medium text-destructive", error_className)}>
                        {formik.errors[nameKey]}
                    </div>
                )
            }
        </>
    )
}

export default FromikTimeRangePicker