import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE, stringifyObjectToRequestQuery } from 'src/helpers'
import { axiosInstance } from 'src/services/axiosSetup'
import { BASE_URL } from 'src/services/consts/api_const'
import CustomeDialog from 'src/shadcn/components/custom/CustomeDialog'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'


type Props = {}

const SubscribeUserPageMain = (props: Props) => {


    const [isLoading, setisLoading] = useState<boolean>(false)
    const [showSuccessDailog, setshowSuccessDailog] = useState<boolean>(false)
    const navigate = useNavigate()
    const { search } = useLocation()
    if (!search || search.replace("?", "") === '') {
        navigate('/')
    }
    const queryParams = new URLSearchParams(search).toString()
    // console.log("test");


    const queryParamsObject = useMemo(() => {
        if (queryParams) {
            console.log({ queryParams });
            let queryExtract = queryParams && queryParams.split("&").reduce((acc: any, curr) => {
                let [key, value] = curr.split("=");
                acc[key] = value
                return acc
            }, {})
            return queryExtract
        }
        return null
    }, [])

    useEffect(() => {
        if (search && search.replace("?", "") !== '' && Object.values(queryParamsObject).length > 0) {
            handleRequestSubscribe(queryParamsObject)
        }
        else navigate("/")
        return () => { }
    }, [])



    const handleRequestSubscribe = catchAsync(async () => {
        setisLoading(true)
        return await axiosInstance.get(`${BASE_URL}/newsletter/subscribe/email?${stringifyObjectToRequestQuery(queryParamsObject)}`)
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            setisLoading(false)
            setshowSuccessDailog(true)
        }
    }, () => { setisLoading(false) })


    return (
        <div>
            <CustomeDialog className=' md:max-w-lg' isOpen={showSuccessDailog} headerTitle={"Subscribed"} submitBtnTitle='Continue' hideDefaultCancle onSubmit={() => { navigate("/") }} >
                <div className='mb-4'>
                    <p>Thank you for visit, your email has been subscribed successfully.</p>
                </div>
            </CustomeDialog>
            <LoadingDialog isOpen={isLoading} />
        </div>
    )
}

export default SubscribeUserPageMain