import { AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { catchAsync, successTheme } from 'src/helpers'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { setEmailForValidatOtp } from 'src/redux/reducers/tempDataSlice'
import { changePasswordRequestApi, registrationRequestApi } from 'src/services/requests/Auth'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { Checkbox } from 'src/shadcn/components/ui'
import { CHANGE_PASSWORD_FORM_FIELDS, changePassword_initialValues, changePasswordFormValidationSchema } from './modules'
import { useAuth } from '../Auth/Core'

type Props = {}

const ChangePassword = (props: Props) => {
  const [isLoading, setisLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { logout, currentUser } = useAuth()

  const formik = useFormik({
    initialValues: changePassword_initialValues,
    validationSchema: changePasswordFormValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      // Handle form submission here
      handleChangeRegistrationForm()
    },
  });

  useEffect(() => {
    if (currentUser) {
      formik.setFieldValue("mobileNumber", currentUser.mobileNumber)
    }
    return () => { }
  }, [currentUser])


  const handleChangeRegistrationForm = catchAsync(
    async () => {
      setisLoading(true)
      return await changePasswordRequestApi(formik.values);
    },
    (result: AxiosResponse) => {
      if (result.status == 200) {
        toast.success("Password Reset Successfully.", { style: successTheme })
        logout()
        navigate("/")
      }
      console.log('Registration successful:', result);
      setisLoading(false)
    },
    () => {
      setisLoading(false)
    }
  );

  console.log({ error: formik.errors, values: formik.values })

  return (
    <div className='space-y-4'>
      <div className='text-sm my-4'>Enter new password, to reset log in password</div>
      <div className='space-y-2'>
        <form className='space-y-2' onSubmit={formik.handleSubmit}>
          <div className='space-y-2'>
            {
              CHANGE_PASSWORD_FORM_FIELDS.map((field: any) => {
                return <RenderFormComponents key={`change-password-form-${field.dataKey}`} formik={formik} value={formik.values[field.dataKey as keyof object]} {...field} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} />
              })
            }
          </div>
          <div><RoundedButton isLoading={isLoading} type='submit' className='w-full'>Reset Password</RoundedButton></div>
        </form>
      </div>
    </div>
  )
}

export default ChangePassword