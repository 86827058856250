import {
  ANNOTATION_ICON,
  catchAsync,
  COMMERCIAL_AND_INDUSTRIAL_PROJECT_OPTIONS,
  COMMERCIAL_SERVICE_BANNER_IMAGE,
  HAND_SHAK_IMG,
  IMG_COMMERCIAL_SERVICES_REFERENCES_BG,
  IS_SUCCESS_RESPONSE,
  optionTypes,
  SECTION_BG,
  WELCOME_SERVICE_BG,
} from "src/helpers";

import { Tags } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EnquiryFormDialog from "src/modules/EnquiryForms/EnquiryFormDialog";
import { MAIN_ROUTES_CONST } from "src/navigations/modules";
import {
  fetchProjectsSummaryApiRequest,
  fetchTop5ProjectsApiRequest,
  fetchTopProjectsByCategoryApiRequest,
} from "src/services/requests/CommercialAndIndustrial";
import LoadingDialog from "src/shadcn/components/custom/LoadingDialog";
import { Button } from "src/shadcn/components/ui";
import {
  COMMERCIAL_SERVICES_DATA_NEW,
  REFERENCES_ANALYSIS,
} from "./Component/modules";
import ServiceTile from "./Component/ServiceTile";
import useFetchData from "./useHandleGetTop5Products";
import CounterAnimation from "src/shadcn/components/custom/Animations/CounterAnimation";

type Props = {};

const BannerImg = COMMERCIAL_SERVICE_BANNER_IMAGE;

const CommercialServices = (props: Props) => {
  const navigate = useNavigate();
  const [openEnquiryDialog, setOpenEnquiryDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [top5Projects, settop5Projects] = useState<any[]>([])
  // const [projectSummary, setprojectSummary] = useState<any[]>([])
  // const [topProjectsByCategory, settopProjectsByCategory] = useState<any[]>([])

  const { data: top5Projects, isLoading: isLoadingTop5 } = useFetchData(
    fetchTop5ProjectsApiRequest
  );
  const { data: projectSummary, isLoading: isLoadingSummary } = useFetchData(
    fetchProjectsSummaryApiRequest
  );
  const { data: topProjectsByCategory, isLoading: isLoadingCategory } =
    useFetchData(fetchTopProjectsByCategoryApiRequest);

  useEffect(() => {
    // setIsLoading(true)
    // handleGetTop5Projects()
    // handleProjectSummary()
    // handleGetTopProjectByCategory()
    // setIsLoading(false)
    return () => { };
  }, []);

  // console.log({ top5Projects, projectSummary, topProjectsByCategory });

  // const handleGetTop5Projects = catchAsync(async () => {
  //   return await fetchTop5ProjectsApiRequest()
  // }, (result: any) => {
  //   if (IS_SUCCESS_RESPONSE(result)) {
  //     settop5Projects(result.data)
  //   }
  // }, () => { })

  // const handleProjectSummary = catchAsync(async () => {
  //   return await fetchProjectsSummaryApiRequest()
  // }, (result: any) => {
  //   if (IS_SUCCESS_RESPONSE(result)) {
  //     setprojectSummary(result.data)
  //   }
  // }, () => { })

  // const handleGetTopProjectByCategory = catchAsync(async () => {
  //   return await fetchTopProjectsByCategoryApiRequest()
  // }, (result: any) => {
  //   if (IS_SUCCESS_RESPONSE(result)) {
  //     settopProjectsByCategory(result.data)
  //   }
  // }, () => { })

  return (
    <div className="mb-56 ">
      <div
        className="py-8"
        style={{
          background: `url(${SECTION_BG})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="space-y-2 max-w-[80vw] mx-auto  ">
          <div className=" text-center font-bold text-2xl md:text-3xl md:my-4">
            Get The RE Project of Your Choice on A Click !!!
          </div>
          <div className="grid md:grid-cols-2 space-x-2 ">
            <div className=" h-full flex items-center order-2 md:order-1">
              <ul className=" text-md list-disc md:ml-6 md:p-8 max-w-[600px] ">
                <li className="mb-6">India’s first Project Marketplace.</li>
                <li className="mb-6">
                  We take care of end-to-end sale process.
                </li>
                <li className="mb-6">No commission from the buyer.</li>
                <li className="mb-6">
                  Only the seller of the Solar projects pays a mutually agreed
                  fee to the Electreecity-PM on successful transaction.
                </li>
                <li className="mb-6">
                  Projects above 100 kWp can be sold or buy on the
                  Electreecity-PM.
                </li>
                <li className="mb-6">
                  Free land above 1000 Sq meter, rooftop above 500 Sq meter can
                  be listed.
                </li>
              </ul>
            </div>
            <div className="flex justify-center h-full order-1 md:order-2">
              <img src={HAND_SHAK_IMG} className="my-4 md:mt-8 rounded-xl" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          style={{
            background:
              "linear-gradient(to bottom, rgb(238, 237, 236), 50%, white 50%)",
          }}
        >
          <div className="space-y-8 pt-8 pb-8">
            <div className="px-4 md:px-16">
              <div className="flex items-center space-x-2">
                {" "}
                <img
                  src={ANNOTATION_ICON}
                  style={{ width: "20px", height: "20px" }}
                />{" "}
                <span className=" font-bold text-3xl">Our Top 5 Projects</span>
              </div>
              <div className="px-[30px]">
                Highlighting the top projects that drive real change.
              </div>
            </div>
            <div className="px-16 mx-auto flex flex-col items-center space-y-8">
              <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-4  mx-auto">
                {top5Projects.map(
                  ({
                    headerImage,
                    imageAttachments,
                    projectName,
                    projectSize,
                    units = "MW",
                  }: any) => {
                    let img = headerImage ? headerImage : (imageAttachments && imageAttachments?.length > 0 ? imageAttachments[0] : null);
                    return (
                      <div className="flex flex-col items-center space-y-2 bg-white rounded-xl overflow-hidden border border-primary ">
                        <img
                          src={img}
                          style={{ height: "220px", width: "100%" }}
                        />
                        <div className="flex flex-col items-center  py-2 ">
                          <span className="font-bold">
                            {projectSize / 1000} {units}
                          </span>
                          <span>{projectName}</span>
                        </div>
                      </div>
                    );
                  }
                )}

                <div className="cursor-pointer h-full w-full rounded-xl flex justify-center items-center p-0 hover:border-2 hover:border-primary hover:p-2 transition-all duration-300 ease-in-out">
                  <button
                    className="h-full w-full"
                    onClick={() => navigate("/" + MAIN_ROUTES_CONST.contact_us)}
                  >
                    <div className="bg-theamblack rounded-xl  h-full w-full flex justify-center items-center space-y-4 ">
                      <div className="flex flex-col items-center p-2 space-y-2">
                        <Tags
                          className="text-theamblack-foreground"
                          size={64}
                        />
                        <div className="text-white text-center text-xs">
                          Post You Solar Project here
                        </div>
                        <div className="text-primary"> Click to know more</div>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#EEEDEC]" style={{ margin: 0 }}>
        <div
          style={{
            background: `url(${WELCOME_SERVICE_BG})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            margin: "0 ",
          }}
        >
          <div className="space-y-8 pt-16 pb-8">
            <div className="px-4 md:px-16">
              <div className="flex items-center space-x-2">
                {" "}
                <img
                  src={ANNOTATION_ICON}
                  style={{ width: "20px", height: "20px" }}
                />{" "}
                <span className=" font-bold text-3xl">
                  Commercial & Industrial (CI)
                </span>
              </div>
              <div className="px-[30px]">
                Powering businesses with reliable and efficient solar solutions.
              </div>
            </div>
            <div className="px-4 lg:px-16 mx-auto w-[100vw] lg:w-[80vw]  flex flex-wrap justify-center">
              {COMMERCIAL_SERVICES_DATA_NEW.map((d: any, index: number) => {
                return <ServiceTile {...d} key={index + 1} />;
              })}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="space-y-8 pt-8 pb-8">
          <div className="px-4 md:px-16">
            <div className="flex items-center space-x-2">
              {" "}
              <img
                src={ANNOTATION_ICON}
                style={{ width: "20px", height: "20px" }}
              />{" "}
              <span className=" font-bold text-3xl">How it works?</span>
            </div>
            <div className=" px-8 md:px-[30px]">
              A digital and collaborative approach for the best outcomes.
            </div>
          </div>
          <div className="px-8 md:px-[95px] mx-auto  flex flex-wrap justify-center space-y-8">
            <div>
              If you’re interested in selling your solar project, or part rights
              or leasing your land, click on "Contact Us" and complete the
              contact form. Once you confirm your email address, we’ll provide
              you with the details of a Single Point of Contact (SPOC) and a
              list of required documents. We look forward to working with you to
              close your requirement of green energy !!!
            </div>
            <Button
              onClick={() => navigate("/" + MAIN_ROUTES_CONST.contact_us)}
              className="w-[250px]"
            >
              Contact Us
            </Button>
          </div>
        </div>
      </div>
      <div>
        <div
          style={{
            background: `url(${IMG_COMMERCIAL_SERVICES_REFERENCES_BG})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <div className="space-y-8 pt-8 pb-8">
            <div className="px-16">
              <div className="flex items-center space-x-2">
                {" "}
                <img
                  src={ANNOTATION_ICON}
                  style={{ width: "20px", height: "20px" }}
                />{" "}
                <span className=" font-bold text-3xl">References</span>
              </div>
              <div className="px-[30px]">
                A showcase of successful transactions.
              </div>
            </div>
            <div className="px-8 md:px-16   space-y-8">
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 lg:max-w-[50vw] gap-4 mx-auto">
                {REFERENCES_ANALYSIS.map(
                  ({ count, dataKey, name, annotation }: any) => {
                    if (projectSummary[dataKey]) {
                      count = projectSummary[dataKey];
                    }
                    return (
                      <div className="flex flex-col items-center space-y-1 p-4  " key={name}>
                        <div className="font-bold text-3xl text-primary">
                          <CounterAnimation from={0} to={count} />
                          {annotation}
                        </div>
                        <div className="text-center">{name}</div>
                      </div>
                    );
                  }
                )}
              </div>
              <div className={`flex `}>
                <div className={`grid md:grid-cols-2 lg:grid-cols-${topProjectsByCategory.length >= 3 ? 3 : topProjectsByCategory.length} xl:grid-cols-${topProjectsByCategory.length >= 4 ? 4 : topProjectsByCategory.length} gap-4  mx-auto`}>
                  {topProjectsByCategory.map(
                    ({
                      headerImage,
                      imageAttachments,
                      projectCategoryType,
                      projectName,
                      projectSize,
                      units = "MW",
                    }: any) => {
                      let img = headerImage ? headerImage : (imageAttachments && imageAttachments?.length > 0 ? imageAttachments[0] : null);

                      return (
                        <div className="flex flex-col items-center space-y-2 bg-white rounded-xl overflow-hidden " >
                          <img
                            src={img}
                            style={{ height: "220px", width: "100%" }}
                          />
                          <div className="flex flex-col items-center py-2 ">
                            <span className="font-bold ">
                              {projectSize}
                            </span>
                            <span>{projectCategoryType}</span>
                          </div>
                        </div>
                      );

                    }
                  )}
                </div>
              </div>
              {/* <Button className='w-[150px]'>Learn More</Button> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className=''  >
        <div className='space-y-8 py-8'>
          <div className='px-16'>
            <div className='flex items-center  space-x-2'> <img src={ANNOTATION_ICON} style={{ width: "20px", height: "20px" }} /> <span className=' font-bold text-3xl'>Need Help? Check Our FAQs</span></div>
            <div className='px-[30px]'>Frequently Asked Questions</div>
          </div>
          <div className='px-16 '>
            <CommercialFaqSection />
          </div>
        </div>
      </div> */}
      <EnquiryFormDialog
        isOpenState={setOpenEnquiryDialog}
        openState={openEnquiryDialog}
      />
      <LoadingDialog isOpen={isLoading} message="Fetching Details..." />
      {/* <SolarServicesEnquiryNow /> */}
    </div>
  );
};

export default CommercialServices;
