import { cn, FormField } from 'src/helpers';
import { Label } from '../../ui';
import FormikDateField from '../DateInpute/FormikDateField';
import Dropdown from '../Dropdown';
import FormikMUltiFIleUpload from '../FileUploadInput/FormikMUltiFIleUpload';
import FormikSingleFileUpload from '../FileUploadInput/FormikSingleFileUpload';
import FormikInput from '../InputeFiled/FormikInput';
import FormikPasswordFiled from '../PasswordField/FormikPasswordFiled';
import TextareaFormFiled from '../TextareaFormFiled';
import { useMemo } from 'react';
import FromikTimeRangePicker from '../TimePicker/FromikTimeRangePicker';

interface Props extends FormField {
    value?: any,
    onChange: (e: any) => void,
    onBlur?: (e: any) => void
    formik?: any
}


const RenderFormComponents = ({ label, dataKey, componentType, componentProps, value, onChange, onBlur, formik }: Props) => {
    // console.log({ componentProps });

    const isHidden = useMemo(() => {
        if (componentProps?.hide) {
            // console.log({ expectedValue : componentProps?.hide?.expectedValue , value : formik.values[componentProps?.hide?.formKey] });
            if (componentProps?.hide?.expectedValue == formik.values[componentProps?.hide?.formKey]) {
                return true
            }
            return false
        }
        else if (componentProps?.conditionalHide) {
            // console.log({ expectedValue : componentProps?.conditionalHide?.expectedValue , value :  formik.values[componentProps?.conditionalHide?.formKey] });
            if (Array.isArray(componentProps?.conditionalHide.expectedValue)) {
                return componentProps.conditionalHide.expectedValue.some((d: any) => d == formik.values[componentProps?.conditionalHide?.formKey as keyof object])
            }
            else if (componentProps?.conditionalHide?.expectedValue == formik.values[componentProps?.conditionalHide?.formKey]) {
                return true
            }
            return false
        }

        return true

    }, [componentProps?.hide, componentProps?.conditionalHide, formik.values])

    return (
        <div>
            {
                componentType == "input" && componentProps?.type && ['email', 'phone', 'text', 'url','number'].includes(componentProps?.type) &&
                <FormikInput
                    formik={formik}
                    labelStyle={componentProps?.labelStyle}
                    label={label}
                    nameKey={dataKey}
                    placeholder={componentProps?.placeholder}
                    regex={componentProps?.regex}
                    prefix={componentProps?.inputPrefixIcon}
                    numberFormatted={componentProps?.numberFormatted}
                    sufix={componentProps?.inputSuffixIcon}
                    maxLength={componentProps?.maxLength}
                    value={value}
                    showCharacterLength={componentProps.showCharacterLength}
                    type={componentProps?.type}
                    required={componentProps?.required}
                    onchange={onChange}
                    onBlur={onBlur}
                    disabled={componentProps?.disabled}
                    readonly={componentProps?.readonly}
                    description={componentProps?.description}
                    options={componentProps?.options}
                    numberPattern={componentProps?.numberPattern}
                    error_className={componentProps?.error_className}
                />
            }
            {
                componentType == "input" && componentProps?.type && ['password'].includes(componentProps?.type) &&
                <FormikPasswordFiled
                    formik={formik}
                    label={label}
                    labelStyle={componentProps?.labelStyle}
                    nameKey={dataKey}
                    placeholder={componentProps?.placeholder}
                    value={value}
                    required={componentProps?.required}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={componentProps?.disabled}
                    error_className={componentProps?.error_className}
                />
            }
            {
                componentType == "file_picker" && componentProps?.fileSelectionType == 'multi' &&
                <FormikMUltiFIleUpload
                    formik={formik}
                    label={label}
                    labelStyle={componentProps?.labelStyle}
                    nameKey={dataKey}
                    value={value}
                    required={componentProps?.required}
                    onChange={onChange}
                    error_className={componentProps?.error_className}
                />
            }
            {
                componentType == "file_picker" && !componentProps?.fileSelectionType &&
                <FormikSingleFileUpload
                    formik={formik}
                    label={label}
                    labelStyle={componentProps?.labelStyle}
                    nameKey={dataKey}
                    value={value}
                    required={componentProps?.required}
                    onchange={onChange}
                    placeholder={componentProps?.placeholder}
                    error_className={componentProps?.error_className}
                    disabled={componentProps?.disabled || (componentProps?.conditionalDisabled ? formik.values[componentProps.conditionalDisabled.formKey] == componentProps.conditionalDisabled.expectedValue : false)} />
            }
            {
                componentType == "options" && <div className=''>
                    {
                        label ? <Label className={cn(' text-xs mb-2', componentProps?.labelStyle)}>{label} {componentProps?.required ? <span className='text-destructive'>*</span> : ""} </Label> : <></>
                    }
                    <div className='pt-2'>
                        <Dropdown
                            options={componentProps?.options}
                            value={value || formik.values[dataKey] || ""}
                            onChange={onChange} /></div>
                            {formik.touched[dataKey] && formik.errors[dataKey] && (
                        <div className={cn("text-xs font-medium text-destructive", componentProps?.error_className)}>
                            {formik.errors[dataKey]}
                        </div>
                    )}
                </div>
            }
            {
                componentType == "date_picker" && <div>
                    <FormikDateField
                        formik={formik}
                        label={label}
                        labelStyle={componentProps?.labelStyle}
                        nameKey={dataKey}
                        value={value}
                        onchange={onChange}
                        placeholder={componentProps?.placeholder}
                        required={componentProps?.required}
                        error_className={componentProps?.error_className}
                        disabled={componentProps?.disabled || (componentProps?.conditionalDisabled ? formik.values[componentProps.conditionalDisabled.formKey] == componentProps.conditionalDisabled.expectedValue : false)} />
                </div>
            }
            {
                componentType == "textarea" && <div>
                    <TextareaFormFiled
                        formik={formik}
                        label={label}
                        labelStyle={componentProps?.labelStyle}
                        nameKey={dataKey}
                        value={value}
                        onChange={onChange}
                        placeholder={componentProps?.placeholder}
                        required={componentProps?.required}
                        error_className={componentProps?.error_className}
                        disabled={componentProps?.disabled || (componentProps?.conditionalDisabled ? formik.values[componentProps.conditionalDisabled.formKey] == componentProps.conditionalDisabled.expectedValue : false)} />
                </div>
            }
             {
                componentType == "time-range-piker" && <div>
                    <FromikTimeRangePicker
                        formik={formik}
                        label={label}
                        labelStyle={componentProps?.labelStyle}
                        nameKey={dataKey}
                        value={value}
                        onchange={onChange}
                        placeholder={componentProps?.placeholder}
                        required={componentProps?.required}
                        error_className={componentProps?.error_className}
                        disabled={componentProps?.disabled || (componentProps?.conditionalDisabled ? formik.values[componentProps.conditionalDisabled.formKey] == componentProps.conditionalDisabled.expectedValue : false)} />
                </div>
            }

        </div>
    )
}

export default RenderFormComponents