import React from 'react';
import { Skeleton } from 'src/shadcn/components/ui/skeleton'


const NewLayoutResultLoader = () => {
    return (
        <div
            className="bg-white border border-gray-200 rounded-2xl overflow-hidden shadow-md transition-transform duration-300 ease-in-out hover:scale-105 cursor-pointer 
w-full min-w-[280px] mx-auto h-full flex flex-col"
        >
            {/* Image Section */}
            <div className="relative h-52 w-full">
                {/* <img
                    src={imageAttachments?.length > 0 ? imageAttachments[0] : ""}
                    className="w-full h-full object-cover rounded-t-2xl"
                    alt={projectName}
                /> */}
                <Skeleton className="w-full h-full object-cover rounded-t-2xl" />
            </div>

            {/* Details Section */}
            <div className="p-4 flex flex-col flex-grow">  
                {/* <h3 className="text-lg font-semibold">{projectName}</h3> */}
                <Skeleton className="w-full h-4 object-cover " />
                {/* <p className="text-gray-500 text-sm">{nearestCity}, {state}</p> */}
                <Skeleton className="w-full h-4 object-cover " />

                <div className="grid grid-cols-3 gap-2 my-4 text-center text-sm">
                    <Skeleton className="w-full h-20 object-cover " />
                   
                    <Skeleton className="w-full h-20 object-cover " />
                    
                    <Skeleton className="w-full h-20 object-cover " />
                 
                </div>


                <div className="flex w-full gap-x-2 mt-auto">
                 
                    <Skeleton className="w-1/2 h-9 object-cover " />
                   
                    <Skeleton className="w-1/2 h-9 object-cover " />
                </div>


            </div>

           
         
        </div>

    );
};

export default NewLayoutResultLoader;
