import { ArrowRight } from "lucide-react";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { USER_ROLES } from "src/helpers";
import { useAuth } from "src/modules/Auth/Core";
import { MAIN_ROUTES_CONST } from "src/navigations/modules";
import { getTempDataRootState } from "src/redux/reducers/tempDataSlice";
import { RootState } from "src/redux/store";
import { INavMenu } from "src/shadcn/components/custom/NavMenuComponent";
import NavMenuReStructureComponent from "src/shadcn/components/custom/NavMenuReStructureComponent";
interface NavigationMenu_RProps {
  open?: boolean;
  setopen?: (val: boolean) => void
}
const NavigationMenu_R = ({ setopen, open }: NavigationMenu_RProps) => {

  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const [openSubMenus, setOpenSubMenus] = useState<number[]>([]);
  const { userLocationDetails } = useSelector((state: RootState) => getTempDataRootState(state))
  const DEFAULT_NAV: INavMenu = [
    {
      title: "Home",
      link: MAIN_ROUTES_CONST.welcome,
    },
    // {
    //   title: "Dashboard",
    //   link: MAIN_ROUTES_CONST.dashboard,
    // },
    {
      title: "Quotation Details",
      link: MAIN_ROUTES_CONST.quotation_details,
    },
    {
      title: "Services",
      link: "",
      subLinks: [
        {
          title: "Commercial & Industrial",
          link: MAIN_ROUTES_CONST.commercial_services,
        },
        {
          title: "Residential & Society",
          link: MAIN_ROUTES_CONST.residential_services,
        },
        {
          title: "Finance Services",
          link: MAIN_ROUTES_CONST.finance_service,
        },
      ],

    },
    {
      title: "Explore Market",
      link: MAIN_ROUTES_CONST.marketplace,
    },
    {
      title: "Solutions",
      link: "",
    },
    {
      title: "About Us",
      link: "",
      subLinks: [
        {
          title: "About US",
          link: MAIN_ROUTES_CONST.about_us,
        },
        {
          title: "Vision & Mission",
          link: MAIN_ROUTES_CONST.mission_and_vision,
        },
        {
          title: "Career",
          link: MAIN_ROUTES_CONST.careers,
        },
        {
          title: "Our Team",
          link: MAIN_ROUTES_CONST.our_team,
        },
        {
          title: "Experts Panel",
          link: MAIN_ROUTES_CONST.advisory,
        },
        {
          title: "Contact Us",
          link: MAIN_ROUTES_CONST.contact_us,
        },
      ],
    },
    {
      title: "History",
      link: "",
      subLinks: [
        {
          title: "Enquiry History",
          link: MAIN_ROUTES_CONST.enquiry_history,
        },
        {
          title: "Project History",
          link: MAIN_ROUTES_CONST.project_history,
        },

      ],
    },
    {
      title: "Enquiry Request",
      link: MAIN_ROUTES_CONST.new_enquiry_request,
    },
  ];

  const NAVIGATOIN_MENU_ITEMS = useMemo(() => {
    let routes = DEFAULT_NAV
    console.log(currentUser?.role, 'role');


    // routes = DEFAULT_NAV.filter(({ title }: any) => !["Dashboard", "Investment Calculator", "Enquiry Request", "Quotation Details"].includes(title))
    // if (currentUser?.role == USER_ROLES.INVESTOR) {
    //   routes = DEFAULT_NAV.filter(({ title }: any) => !["Home", "Enquiry Request"].includes(title))
    // }
    // else if (currentUser?.role == USER_ROLES.OFF_TAKER) {
    //   routes = DEFAULT_NAV.filter(({ title }: any) => !["Home", "Investment Calculator"].includes(title))
    // }

    if (currentUser) {
      routes = DEFAULT_NAV.filter(({ title }: any) => !["Dashboard", "Investment Calculator", "Enquiry Request", "Quotation Details"].includes(title))
    }
    else {
      routes = DEFAULT_NAV.filter(({ title }: any) => !["Dashboard", "Investment Calculator", "Enquiry Request", "Quotation Details", "History"].includes(title))
    }
    if (userLocationDetails?.address) {
      let spliteAddr = userLocationDetails?.address.split(",").reverse();
      console.log({ spliteAddr });

      if (!spliteAddr.some((d: any) => d.toLowerCase().includes("maharashtra") || d.toLowerCase().includes("uttar pradesh"))) {
        routes = routes.filter(({ title }: any) => title != "Explore Market")
      }
    }
    return routes

  }, [currentUser, DEFAULT_NAV, userLocationDetails])

  const toggleSubMenu = (index: number) => {
    setOpenSubMenus(prev =>
      prev.includes(index)
        ? prev.filter(i => i !== index)
        : [...prev, index]
    );
  };

  return (
    <div>
      {/* <NavMenuReStructureComponent navs={NAVIGATOIN_MENU_ITEMS} open={open} setopen={setopen} /> */}
      {/* desktop */}
      <div className="hidden md:flex md:items-center">
        <ul className="flex items-center space-x-1">
          {NAVIGATOIN_MENU_ITEMS.map((item, index) => (
            <li key={index} className="relative group">
              <button
                onClick={() => navigate("/" + item.link)}
                className="py-4 px-2 text-gray-700 hover:text-primary font-medium transition-colors duration-200"
              >
                <span className="flex items-center">
                  <span className="text-xs">{item.title}</span>
                  {item.subLinks && (
                    <svg
                      className="w-4 h-4 ml-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  )}
                </span>
              </button>

              {item.subLinks && (
                <div className="absolute left-0 mt-0 w-48 bg-white rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-50">
                  <ul className="py-1">
                    {item.subLinks.map((subItem, subIndex) => (
                      <li key={subIndex}>
                        <button
                          onClick={() => navigate("/" + subItem.link)}
                          className="block px-4 py-2 text-xs text-gray-700 hover:bg-orange-50 hover:text-orange-500 w-full text-left"
                        >
                          {subItem.title}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
      {/* mobile */}
      <div className="md:hidden border-t border-gray-200">
        <ul className="px-2 pt-2 pb-3 space-y-1">
          {NAVIGATOIN_MENU_ITEMS.map((item, index) => (
            <li key={index}>
              {item.subLinks ? (
                <div>
                  <button
                    onClick={() => toggleSubMenu(index)}
                    className="w-full flex justify-between px-3 py-2 text-gray-700 hover:text-orange-600 hover:bg-gray-50 rounded-md"
                  >
                    <span className="text-xs">{item.title}</span>
                    <svg
                      className={`w-4 h-4 transition-transform duration-300 ease-in-out ${openSubMenus.includes(index) ? 'transform rotate-180' : ''
                        }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                  <div
                    className={`transition-all duration-200 ease-in-out overflow-hidden ${openSubMenus.includes(index)
                      ? 'max-h-48 opacity-100'
                      : 'max-h-0 opacity-0'
                      }`}
                  >
                    <ul className="pl-4 py-2">
                      {item.subLinks.map((subItem, subIndex) => (
                        <li key={subIndex}>
                          <button
                            onClick={() => navigate("/" + subItem.link)}
                            className="block w-full px-3 py-2 text-start text-xs text-gray-700 hover:text-orange-600 hover:bg-gray-50 rounded-md"
                          >
                            <span className="flex space-x-2"><ArrowRight size={14} /><span>{subItem.title}</span></span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : (
                <button
                  onClick={() => navigate("/" + item.link)}
                  className="block text-start text-xs w-full px-3 py-2 text-gray-700 hover:text-orange-600 hover:bg-orange-50 rounded-md"
                >
                  {item.title}
                </button>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NavigationMenu_R;
