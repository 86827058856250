import React, { useEffect, useMemo, useState } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../ui'


type Props = {
    max_time?: string
    min_time?: string
    default_time?: string,
    onChange: (e: any) => void
}

const handleParseNumber = (number: string) => {
    return `${String(number).padStart(2, '0')}`
}

const TimePicker = ({ max_time, min_time, default_time, onChange }: Props) => {

    const [selectedHour, setSelectedHour] = useState<string>("00")
    const [selectedMin, setSelectedMin] = useState<string>("00")

    useEffect(() => {
        if (default_time) {
            let [hr, min] = default_time.split(":")
            let parsed_hr = handleParseNumber(hr)
            let parsed_min = handleParseNumber(min)
            setSelectedHour(parsed_hr)
            setSelectedMin(parsed_min)
            onChange(`${parsed_hr}:${parsed_min}`)
        }
        if (min_time) {
            let [hr, min] = min_time.split(":")
            let parsed_hr = handleParseNumber(Number(hr) == 23 ? '00' : ((Number(hr)).toString()))
            let parsed_min = handleParseNumber(Number(min) == 59 || Number(min) == 0 ? '00' : ((Number(min) + 1).toString()))
            setSelectedHour(handleParseNumber(hr))
            setSelectedMin(handleParseNumber(min))
            onChange(`${parsed_hr}:${parsed_min}`)
        }
        return () => { }
    }, [])


    useEffect(() => {
        if (min_time) {
            let [hr, min] = min_time.split(":")
            let parsed_hr = handleParseNumber(Number(hr) == 23 ? '00' : ((Number(hr)).toString()))
            let parsed_min = handleParseNumber(Number(min) == 59 || Number(min) == 0  ? '00' : ((Number(min) + 1).toString()))
            setSelectedHour(handleParseNumber(parsed_hr))
            setSelectedMin(handleParseNumber(parsed_min))
            onChange(`${parsed_hr}:${parsed_min}`)
        }
        return () => { }
    }, [min_time])

    const HOURS = useMemo(() => {
        // Create array from 0-23 (24 hours)
        const hours = [...new Array(24)].map((_, i: number) => i);

        if (max_time || min_time) {
            // Extract hour values, defaulting to undefined if not provided
            const start = min_time ? parseInt(min_time.split(":")[0]) : undefined;
            const end = max_time ? parseInt(max_time.split(":")[0]) : undefined;

            return hours.filter((hour: number) => {
                // If both start and end exist, check both conditions
                if (start !== undefined && end !== undefined) {
                    return hour >= start && hour <= end;
                }
                // If only start exists (min_time)
                if (start !== undefined) {
                    return hour >= start;
                }
                // If only end exists (max_time)
                if (end !== undefined) {
                    return hour <= end;
                }
                return true;
            });
        }

        return hours;
    }, [max_time, min_time]);

    const MINUTES = useMemo(() => {
        // Create array from 0-59 (60 minutes)
        const minutes = [...new Array(60)].map((_, i: number) => i);
        if (selectedHour) {
            if (min_time || max_time) {
                // Parse hours and minutes from selectedTime
                const [minHour, minMin] = min_time ? min_time.split(":") : [0, 0];
                const [maxHour, maxMin] = max_time ? max_time.split(":") : [0, 0];

                if (selectedHour == minHour) {
                    return minutes.filter((min: number) => min > Number(minMin));
                }
                else if (selectedHour == maxHour) {
                    return minutes.filter((min: number) => min <= Number(minMin));
                }
            }
        }
        return minutes;
    }, [selectedHour, min_time, max_time]);


    return (
        <div className='flex items-center'>
            <Select onValueChange={(e: any) => {
                setSelectedHour(handleParseNumber(e))
                const [minHour, minMin] = min_time ? min_time.split(":") : ["00", "00"];
                if (min_time && e == minHour) {
                    setSelectedMin(handleParseNumber(minMin))
                    onChange(`${handleParseNumber(e)}:${handleParseNumber(minMin)}`)
                } else {
                    setSelectedMin(handleParseNumber("00"))
                    onChange(`${handleParseNumber(e)}:00`)
                }

            }} >
                <SelectTrigger clearable={false} onClear={() => { }} className="w-[50px]">
                    <div>{selectedHour || "Hr"}</div>
                </SelectTrigger>
                <SelectContent>
                    {
                        HOURS.map((hr: any) => {
                            return <SelectItem value={`${hr}`}>{hr}</SelectItem>
                        })
                    }
                </SelectContent>
            </Select>
            <div className='mx-1 font-bold'>:</div>
            <Select onValueChange={(e: any) => {
                setSelectedMin(handleParseNumber(e))
                onChange(`${selectedHour}:${handleParseNumber(e)}`)
            }} >
                <SelectTrigger clearable={false} onClear={() => { }} className="w-[50px]">
                    <div>{selectedMin || "Min"}</div>
                </SelectTrigger>
                <SelectContent>
                    {
                        MINUTES.map((min: any) => {
                            return <SelectItem value={`${min}`}>{min}</SelectItem>
                        })
                    }
                </SelectContent>
            </Select>
        </div>
    )
}

export default TimePicker