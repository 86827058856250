import React, { useState } from "react";
import { FAQ, KEY_FETURES, OVERVIEW } from "./modules";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
} from "src/shadcn/components/ui";
import { useNavigate } from "react-router-dom";
import { IMG_FAQ, NAME_REGEX, toAbsoluteUrl } from "src/helpers";
import { MAIN_ROUTES_CONST } from "src/navigations/modules";
import CustomeDialog from "src/shadcn/components/custom/CustomeDialog";
import ContactusForm from "src/modules/ContactUs";
import { IoCloseCircle } from "react-icons/io5";
import { IContactUdForm } from "src/modules/ContactUs/modukes";

type Props = {};

const ProjectRightsDetails = (props: Props) => {
  const [openContactUsDailog, setOpenContactUsDailog] = useState(false);

  const CONTACT_US_INIT: IContactUdForm = {
    name: "",
    phoneNumber: "",
    email: "",
    queryType: "Project Rights",
    description: "",
    attachments: undefined,
  };

  const navigate = useNavigate();
  return (
    <div className="bg-white text-gray-800 mb-40 md:mb-32 lg:mb-24 xl:mb-0">
      {/* Header Section */}
      <section className="container mx-auto flex flex-col md:flex-row  px-4 py-8">
        {/* Text Content */}
        <div className="md:w-1/2">
          <h1 className="text-4xl font-bold mb-4">{OVERVIEW.title}</h1>
          <p className="mb-6 text-sm">{OVERVIEW.description}</p>
          <div className="flex space-x-2">
            <Button onClick={() => setOpenContactUsDailog(true)}>
              Contract Us
            </Button>
            <Button
              onClick={() => navigate("/" + OVERVIEW.targetPage)}
              className=""
            >
              Explore Marketplace
            </Button>
          </div>
        </div>
        {/* Image/Video Content */}
        <div className="md:w-1/2 flex justify-end mt-6 md:mt-0">
          {/* <video src='src="https://videos.pexels.com/video-files/4034091/4034091-uhd_2560_1440_30fps.mp4"' className='h-[400px] w-[300px] rounded-lg' ></video> */}
          {OVERVIEW.video ? (
            <video
              src={OVERVIEW.video}
              className="rounded-xl"
              width="550"
              height="600"
              controls
            >
              <source src={OVERVIEW.video} type="video/mp4" />
            </video>
          ) : null}
          {OVERVIEW.img ? (
            <img
              src={OVERVIEW.img}
              style={{ width: "550px", height: "600px" }}
            />
          ) : null}
        </div>
      </section>
      {/* Key Features Section */}
      <section className="bg-gray-100 py-12">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-10">Key Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 text-center">
            {/* Feature 1 */}
            {KEY_FETURES.map(({ title, description, icon }: any) => {
              return (
                <div className="p-4 space-y-1 flex flex-col items-center">
                  <div className="h-[80px] flex justify-center items-center w-full">
                    <img
                      src={icon}
                      style={{ height: "50px" }}
                    />
                  </div>
                  <h3 className="text-xl font-bold mb-2">{title}</h3>
                  <p className="text-sm">{description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="">
        <div className="md:p-[100px] p-4">
          <div className="max-w-[80vw] mx-auto md:grid md:grid-cols-5 lg:grid-cols-3 ">
            <div className="space-y-8 md:col-span-2 lg:col-span-1">
              <div className="space-y-2">
                <div>Frequently Asked Questions</div>
                <div className="h-[2px] bg-primary w-[80px]"></div>
              </div>
              <div className="relative">
                <div className="text-3xl font-bold">
                  Need Help? Check Our FAQs
                </div>
                <img
                  src={IMG_FAQ}
                  style={{ width: "150px" }}
                  className="absolute top-[50px] left-[100px]"
                />
              </div>
            </div>
            <div className="lg:col-span-2 md:col-span-3">
              <div className=" mx-auto p-4">
                {FAQ.map((d: any) => {
                  return (
                    <Accordion type="single" collapsible>
                      <AccordionItem
                        className="border-black border-b-2 border-dotted border-t-0 border-r-0 border-l-0 "
                        value="item-1"
                      >
                        <AccordionTrigger>
                          <div className="font-bold ">{d.question}</div>
                        </AccordionTrigger>
                        <AccordionContent>{d.answer}</AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <CustomeDialog
        isOpen={openContactUsDailog}
        headerTitle=""
        hideDefaultSubmit
        hideDefaultCancle
        className="max-w-xs md:max-w-md lg:max-w-lg"
        onCancle={() => setOpenContactUsDailog(false)}
      >
        <div>
          <IoCloseCircle
            onClick={() => setOpenContactUsDailog(false)}
            size={24}
            className="text-gray-400 absolute right-1 top-3"
          />
          <ContactusForm
            setOpenContactUsDailog={setOpenContactUsDailog}
            initialValue={CONTACT_US_INIT}
          />
        </div>
      </CustomeDialog>
    </div>
  );
};

export default ProjectRightsDetails;
