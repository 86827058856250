import { MAIN_ROUTES_CONST } from "src/navigations/modules";

export const OVERVIEW = {
    title: "Rooftop Solar Systems",
    description: "Unlock the potential of your rooftop with efficient solar energy solutions. Rooftop solar installations provide a cost-effective and sustainable way to generate clean energy while reducing electricity bills. Ideal for residential, commercial, and industrial properties, rooftop systems are a versatile option for harnessing the power of the sun.",
    video: "",
    img: "",
    targetPage: MAIN_ROUTES_CONST.marketplace+ "?projectCategoryType=Lease Roof Areas"
  };
  
  export const KEY_FEATURES = [
    { icon: "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661693407_MaximizeSpaceEfficiency.png", title: "Maximize Space Efficiency", description: "Rooftop solar systems use unused roof space to generate energy, making them ideal for urban buildings with limited outdoor area." },
    { icon: "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661692237_ReduceEnergyCosts.png", title: "Reduce Energy Costs", description: "Generating your own electricity can significantly reduce or eliminate your reliance on the grid, leading to substantial savings on bills." },
    { icon: "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661694698_QuickAndEasyInstallation.png", title: "Quick and Easy Installation", description: "Rooftop solar systems are typically easier and faster to install compared to ground-mounted systems, with minimal disruption to your property or daily operations." },
    { icon: "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661691020_GridIndependence.png", title: "Grid Independence", description: "Rooftop solar systems with battery storage provide power during grid outages, ensuring an uninterrupted energy supply." },
  ];

  export const FAQ = [
    {
      "question": "What is Rooftop solar?",
      "answer": "Rooftop solar involves installing solar panels on residential or commercial rooftops to generate electricity for on-site use."
    },
    {
      "question": "How long do rooftop solar panels last?",
      "answer": ""
    },
    {
      "question": "Can I sell excess electricity back to the grid?",
      "answer": ""
    },
    {
      "question": "Do I need a battery for rooftop solar?",
      "answer": ""
    },
    {
      "question": "Are there maintenance requirements?",
      "answer": ""
    },
    {
      "question": "What incentives are available for rooftop solar?",
      "answer": ""
    }
  ]