import { useFormik } from 'formik';
import React, { useState } from 'react'
import { catchAsync, FormField, IS_SUCCESS_RESPONSE } from 'src/helpers';
import { INITIAL_SCHEDULE_CALL_FORM_VALUES, SCHEDULE_CALL_FORM_FIELDS, SCHEDULE_CALL_FORM_VALIDATION_SCHEMA } from './modules';
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents';
import { Button } from 'src/shadcn/components/ui';
import { createScheduleCallbackApiRequest } from 'src/services/requests/ScheduleCall';
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog';
import CustomeDialog from 'src/shadcn/components/custom/CustomeDialog';
import { useNavigate } from 'react-router-dom';


type Props = {}

const ScheduleCallForm = (props: Props) => {

  const [isLoading, setisLoading] = useState<boolean>(false)
  const [isOpenSuccessDialog, setIsOpenSuccessDialog] = useState<boolean>(false)
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: INITIAL_SCHEDULE_CALL_FORM_VALUES,
    validationSchema: SCHEDULE_CALL_FORM_VALIDATION_SCHEMA,
    onSubmit(values, formikHelpers) {
      handleRequest()
    },
  })

  const handleRequest = catchAsync(async () => {
    setisLoading(true)
    return await createScheduleCallbackApiRequest(formik.values)
  }, (result: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      setIsOpenSuccessDialog(true)
      setisLoading(false)
    }
  }, () => { setisLoading(false) })

  console.log({error:formik.errors});
  

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {
          SCHEDULE_CALL_FORM_FIELDS.map((field: FormField) => {
            return <div><RenderFormComponents key={`new-schedule-meet-form-${field.dataKey}`} formik={formik} value={formik.values[field.dataKey as keyof object]} {...field} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} /></div>
          })
        }
        <div className='flex justify-center py-4'>
          <Button isLoading={isLoading} className="w-[150px]">Submit</Button>
        </div>
      </form>
      <LoadingDialog isOpen={isLoading} />
      <CustomeDialog isOpen={isOpenSuccessDialog} headerTitle={"Request Sent"} hideDefaultCancle onSubmit={() => navigate("/")} >
        <p className='mb-2'>
          Your call scheduled, We will contact you soon.
        </p>
      </CustomeDialog>
    </>
  );
};


export default ScheduleCallForm