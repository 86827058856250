import React from 'react'
import { useNavigate } from 'react-router-dom'
import Playbtn from 'src/shadcn/components/custom/Playbtn'
import { CarouselItem } from 'src/shadcn/components/ui'

type Props = {
    heading: string,
    number: string,
    description: string,
    link:string
}

const ServiceTile = ({ heading, number, description,link }: Props) => {
    const navigate=  useNavigate()
    return (
        <CarouselItem className="w-full sm:basis-1/2 md:basis-1/3 lg:basis-1/3 p-4 ">
            <div className='relative border-2 border-primary rounded-xl p-4 hover:bg-theamblack hover:text-white h-full ' onClick={()=>navigate("/"+link)}>
                <div className='flex justify-between items-start py-2'>
                    <div className='uppercase font-bold '>{heading}</div>
                    <div className='text-2xl md:text-4xl font-bold text-theamblack-foreground'>{number}</div>
                </div>
                <div className='py-4 border-t-2 text-sm '>
                    {description}
                </div>
                <div className='absolute -bottom-4 right-10 ' ><div><button ><Playbtn /></button></div></div>
            </div>
        </CarouselItem>
    )
}

export default ServiceTile