import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import UplodeElectBillsForm from 'src/modules/UplodeElectBills/UplodeElectBillsForm'
import { uploadBillsApiRequest } from 'src/services/requests/UploadBills'
import CustomeDialog from 'src/shadcn/components/custom/CustomeDialog'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import { Card } from 'src/shadcn/components/ui'

type Props = {}

const UplodeElectBills = (props: Props) => {


  const [data, setdata] = useState<any>()
  const [isLoading, setisLoading] = useState<boolean>(false)
  const [showSuccessDialog, setshowSuccessDialog] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleUplaodBill = catchAsync(async (data: any) => {
    setisLoading(true)
    return uploadBillsApiRequest({
      billUrl: data?.billUrl,
      phoneNumber: data?.phoneNumber?.toString()
    })
  }, (result: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      setisLoading(false)
      setshowSuccessDialog(true)
      // navigate("/")
    }
  }, () => { setisLoading(false) })

  return (
    <div className=" bg-gray-50 flex items-center justify-center p-4">
      <Card className="w-full max-w-md p-6 ">
        <div className='text-lg text-primary uppercase'>Upload Electricity Bill</div>
        <div className='h-0.5 bg-primary w-[50px] rounded-full'></div>
        <div className='my-4 text-xs text-gray-400'>Please upload your electricity bill and provide your phone number</div>
        <UplodeElectBillsForm onSubmit={handleUplaodBill} />
      </Card>
      <LoadingDialog isOpen={isLoading} />
      <CustomeDialog isOpen={showSuccessDialog} hideDefaultCancle onSubmit={() => {
         setshowSuccessDialog(true)
        navigate("/")}} headerTitle={" "} >
        <p className='text-center'>
        Your electricity bill has been uploaded successfully. 
       
        </p>
        <p className='mb-4 text-center'>
        We will contact you within next 24-48 hours.
        </p>
      </CustomeDialog>
    </div>
  )
}

export default UplodeElectBills