import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { RootState } from '../store';
import { toast } from 'src/shadcn/components/ui/use-toast';
import { getProjectDetailsByIDRequestApi, getProjectDetailsByProjectIDRequestApi } from 'src/services/requests/Projects';


interface project_detailsState {
    project_details: any;
    loading: boolean;
    calculateInvestment: {
        totalInvestment: number | undefined,
        expectedReturnPerYear: number | undefined,
        yearTillReturn: number | undefined,
        investmentGain: number | undefined,
        totalInterestReturn: number | undefined,
        finalReturn: number | undefined
    },
    error: string | undefined;
}

const initialState: project_detailsState = {
    project_details: null,
    calculateInvestment: {
        totalInvestment: undefined,
        expectedReturnPerYear: undefined,
        yearTillReturn: undefined,
        investmentGain: undefined,
        totalInterestReturn: undefined,
        finalReturn: undefined
    },
    loading: false,
    error: undefined,
};

export const fetchProjectDetailsAsync: any = createAsyncThunk(
    'project_details/fetch_project_details',
    async (id: any, thunkApi: any) => {
        try {
            const response = await getProjectDetailsByIDRequestApi(id)
            return response.data
        } catch (err) {
            const error = err as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                if (error) {
                    const status = error.response?.status;
                    toast({
                        variant: "destructive",
                        title: "Uh oh! Something went wrong.",
                        description: error.response?.data?.message,
                    })
                }
            } else {
                toast({
                    variant: "destructive",
                    title: "Unexpected error",
                    description: error?.message,
                })
            }
        }
    }
);

export const fetchProjectDetailsByProjectIDAsync: any = createAsyncThunk(
    'project_details/fetch_project_details_by_project_id',
    async (id: any, thunkApi: any) => {
        try {
            const response = await getProjectDetailsByProjectIDRequestApi(id)
            return response.data
        } catch (err) {
            const error = err as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                if (error) {
                    const status = error.response?.status;
                    toast({
                        variant: "destructive",
                        title: "Uh oh! Something went wrong.",
                        description: error.response?.data?.message,
                    })
                }
            } else {
                toast({
                    variant: "destructive",
                    title: "Unexpected error",
                    description: error?.message,
                })
            }
        }
    }
);

const project_detailsSlice = createSlice({
    name: 'project_details',
    initialState,
    reducers: {
        setInvestment: (state, action: PayloadAction<any>) => {
            state.calculateInvestment = action.payload
        },
        resetInvestment: (state) => {
            state.calculateInvestment.totalInvestment = 0
            state.calculateInvestment.expectedReturnPerYear = 0
            state.calculateInvestment.yearTillReturn = 0
            state.calculateInvestment.totalInterestReturn = 0
            state.calculateInvestment.investmentGain = 0
            state.calculateInvestment.finalReturn = 0
        },
        resetProjectDetails: (state) => {
            state.project_details = undefined
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjectDetailsAsync.pending, (state) => {
                state.loading = true;
                state.error = undefined;
            })
            .addCase(fetchProjectDetailsAsync.fulfilled, (state, action) => {
                state.project_details = action.payload;
                state.loading = false;
            })
            .addCase(fetchProjectDetailsAsync.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            })
            .addCase(fetchProjectDetailsByProjectIDAsync.pending, (state) => {
                state.loading = true;
                state.error = undefined;
            })
            .addCase(fetchProjectDetailsByProjectIDAsync.fulfilled, (state, action) => {
                state.project_details = action.payload;
                state.loading = false;
            })
            .addCase(fetchProjectDetailsByProjectIDAsync.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            });
    },

});

export const { setInvestment, resetInvestment,resetProjectDetails } = project_detailsSlice.actions;
export const getProjectDetailsRootState = (state: RootState) => state.projectDetailsReducer;
export default project_detailsSlice.reducer;