import { MoveUpRight } from "lucide-react";
import React from "react";
import ListMain from "src/modules/ProjectRights/AllProjectRights/ListMain";

type Props = {};

const AllSellExistingProjectsPage = (props: Props) => {
  return (
    <div className=" max-w-[90vw] mx-auto">
      <div className="space-y-2 py-4">
        <div className="my-8 text-xl uppercase space-x-2 flex ">
          <span>
            <MoveUpRight className="text-primary " />
          </span>{" "}
          <span className=" font-semibold">Project Rights </span>
        </div>
        <ListMain />
      </div>
    </div>
  );
};

export default AllSellExistingProjectsPage;
