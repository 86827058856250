import { useNavigate } from 'react-router-dom'
import Playbtn from 'src/shadcn/components/custom/Playbtn'


type Props = {
    heading: string,
    number: string,
    description: string,
    link: string
}

const ServiceTile = ({ heading, number, description, link }: Props) => {
    const navigate = useNavigate()
    return (
        <button className="md:basis-1/2 lg:basis-1/2 xl:basis-1/3 p-4" onClick={() => navigate("/" + link)} >
            <div >
                <div className='border-2 border-primary rounded-xl p-2 hover:bg-theamblack hover:text-white  cursor-pointer transition duration-300 ease-in-out space-y-2'>
                    <div className='flex justify-between  py-2'>
                        <div className='uppercase font-bold text-left '>{heading}</div>
                        <div className=' text-2xl md:text-4xl font-bold text-theamblack-foreground'>{number}</div>
                    </div>
                    <div className='py-4 border-t-2 text-sm h-[120px] '>
                        {description}
                    </div>
                    <div className='w-full flex justify-center space-x-2 items-center' ><div className=''>
                        <div className='flex space-x-2 items-center'>
                            <Playbtn size='sm' />
                            <div className='font-bold uppercase'>Explore Market</div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </button>
    )
}

export default ServiceTile