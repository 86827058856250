import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  catchAsync,
  COSTOMER_JOURNEY_SOLAR_IMAGE,
  IS_SUCCESS_RESPONSE
} from 'src/helpers'
import { getTempDataRootState } from 'src/redux/reducers/tempDataSlice'
import { RootState } from 'src/redux/store'
import {
  fetchQuotationProjectRequestApi
} from 'src/services/requests/Projects'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import { Button } from 'src/shadcn/components/ui'
import SolarPlanAccordian from './Component/CostomerJorneyAccordian.tsx/SolarPlanAccordian'
import SolarPlan from './Component/Solarplan/SolarPlan'


const CostomerJourneyMain = () => {
  const SolarDeal = COSTOMER_JOURNEY_SOLAR_IMAGE
  const navigate = useNavigate()
  const { id } = useParams()

  const [isLoading, setisLoading] = useState<boolean>(false)
  const [projectDetails, setProjectDetails] = useState<any>(undefined)
  const [isOpenDialog, setisOpenDialog] = useState<boolean>(false)

  const { quotationDetails } = useSelector((state: RootState) =>
    getTempDataRootState(state)
  )

  

  // const formik = useFormik({
  //   initialValues: USER_DETAILS_INIT,
  //   validationSchema: USERDetailsFormSchema,
  //   onSubmit(values, formikHelpers) {
  //     handleUpdateMonthlyBillAmount(values.billAmount)
  //   },
  // })

  useEffect(() => {
    if (id) {
      handleFetchProjectDetails()
    }
    return () => {}
  }, [id])

  const handleFetchProjectDetails = catchAsync(
    async () => {
      setisLoading(true)
      return id && (await fetchQuotationProjectRequestApi(id))
    },
    (result: any) => {
      if (IS_SUCCESS_RESPONSE(result)) {
        setProjectDetails(result?.data)
        // if (Number(result?.data?.monthlyBillAmount) == 0) {
        //   setisOpenDialog(true)
        // }
        setisLoading(false)
      }
    },
    () => {
      setisLoading(false)
    }
  )

  // const handleUpdateMonthlyBillAmount = catchAsync(
  //   async (monthlyUnits: string) => {
  //     setisLoading(true)
  //     return await fetchProjectCalculationsRequestApi({
  //       _id: id,
  //       monthlyBillAmount: monthlyUnits,
  //     })
  //   },
  //   (result: any) => {
  //     if (IS_SUCCESS_RESPONSE(result)) {
  //       setProjectDetails({
  //         ...projectDetails,
  //         ...result?.data,
  //       })
  //       handleFetchProjectDetails()
  //       setisOpenDialog(false)
  //     }
  //     setisLoading(false)
  //   },
  //   () => {
  //     setisLoading(false)
  //   }
  // )

  return (
    <div className="w-full font-sans px-4 py-6">
      {/* Header Section */}
      {/* <div className="mb-4">
        <div className="flex items-center flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
          <MoveLeft
            className="cursor-pointer"
            onClick={() => navigate(`/${MAIN_ROUTES_CONST.welcome}`)}
          />
          <div className="font-bold text-lg sm:text-2xl text-black">
            {checkDecimal(projectDetails?.unitConsumptionPerDay, true)} (kW/day)
          </div>
        </div>
        <div className="flex items-center space-x-4 mt-2 sm:mt-4">
          <MapPin className="text-primary w-6 h-6" />
          <div className="text-md sm:text-xl font-normal text-black">
            {projectDetails?.city}, {projectDetails?.state}
          </div>
        </div>
      </div> */}

      {/* Components */}
      {/* <SolarPowerGenaration projectDetails={projectDetails} /> */}
      {/* <CostomerJorneyOutcome />   */}

      {/* Promo Section */}
      {/* <div className="flex flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-2 text-primary font-bold text-lg sm:text-2xl mt-6">
        <div>Get the Best Solar Deals with Our Partners</div>
        <img
          className="w-[40px] h-[40px]  sm:w-auto max-w-xs sm:max-w-none "
          src={SolarDeal}
          alt="solarDeal"
        />
      </div> */}

      <SolarPlan projectDetails={projectDetails} />
      <SolarPlanAccordian />

      {/* Action Buttons */}
      <div className="flex justify-center py-4">
        <Button
          variant="outlinePrimary"
          className="rounded-full w-[120px] sm:w-[150px]"
        >
          Back
        </Button>
      </div>

      {/* Form Dialog */}
      {/* <form onSubmit={formik.handleSubmit}>
        <CustomeDialog
          className="w-full md:max-w-md"
          headerTitle="Enter Average Monthly Bill"
          isOpen={isOpenDialog}
          onSubmit={() => formik.handleSubmit()}
          onCancle={() => {
            setisOpenDialog(false)
          }}
        >
          <div className="mb-4 space-y-4">
            {USER_DETAILS_FIELDS.map((field: FormField) => (
              <div key={`new-enquiry-form-${field.dataKey}`}>
                <RenderFormComponents
                  formik={formik}
                  value={formik.values[field.dataKey as keyof object]}
                  {...field}
                  onChange={(e: any) => formik.setFieldValue(field.dataKey, e)}
                />
              </div>
            ))}
          </div>
        </CustomeDialog>
      </form> */}

      {/* Loading Dialog */}
      <LoadingDialog isOpen={false} message="Please wait request sent" />
    </div>
  )
}

export default CostomerJourneyMain
