import { MAIN_ROUTES_CONST } from "src/navigations/modules";

export const DATA = [
  {
    "heading": "ROOFTOP SOLAR",
    "number": "01",
    "description": "Transform unused rooftop space into a clean energy powerhouse with our customized solar solutions, perfect for homes and businesses.",
    "link": MAIN_ROUTES_CONST.rooftop_solar_services
  },

  {
    "heading": "COMPETITIVE FINANCE",
    "number": "02",
    "description": "Flexible financing options to bring your solar projects to life, ensuring you have the resources to meet energy goals.",
    "link":""
  },
  {
    "heading": "SOLAR EPC FOR SOCIETIES",
    "number": "03",
    "description": "Comprehensive Engineering, Procurement, and Construction (EPC) services for end-to-end solar project execution.",
    "link": ""
  },
]

export const SOCIETY_DATA = [
  {
    "heading": "MAHARASHTRA",
    "number": "01",
    "description": "Transform unused rooftop space into a clean energy powerhouse with our customized solar solutions, perfect for homes and businesses.",
    "link": ""
  },

  {
    "heading": "UTTAR PRADESH",
    "number": "02",
    "description": "Flexible financing options to bring your solar projects to life, ensuring you have the resources to meet energy goals.",
    "link": ""
    // "link": MAIN_ROUTES_CONST.finance_services
  },
]

