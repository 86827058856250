
export const OUR_TEAM = [
    {
        "image": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661804221_Mr.%20Sanjay.png",
        "name": "Mr. Sanjay",
        "designation": "Director/Technical Head"
    },
    {
        "image": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661791757_Mrs.%20Harshala.png",
        "name": "Mrs. Harshala",
        "designation": "Director/Creative Head"
    },
    {
        "image": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661775808_Mr.%20Roshan.png",
        "name": "Mr. Roshan",
        "designation": "Sr. Associate"
    },
    // {
    //     "image": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661795457_Mr.%20Shravan.png",
    //     "name": "Mr. Shravan",
    //     "designation": "Sr. Business Analyst"
    // },
    // {
    //     "image":"https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661786332_Mr.%20Vishnu.png",
    //     "name": "Mr. Vishnu",
    //     "designation": "Sr. Associate"
    // },
    {
        "image": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661773956_Mrs.%20Neelima.png",
        "name": "Mrs. Neelima",
        "designation": "HRBP"
    },
    {
        "image": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661800516_Mr.%20Inzamam.png",
        "name": "Mr. Inzamam",
        "designation": "Associate"
    },
    {
        "image": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661798880_Mr.%20Parag.png",
        "name": "Mr. Parag",
        "designation": "Associate"
    },
    // {
    //     "image": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661784308_Mr.%20Tushar.png",
    //     "name": "Mr. Tushar",
    //     "designation": "Associate"
    // },
    {
        "image": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661790134_Mr.%20Hrishikesh.png",
        "name": "Mr. Hrishikesh",
        "designation": "Associate"
    },
    {
        "image": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661815091_Mrs.%20Pallavi.png",
        "name": "Mrs. Pallavi",
        "designation": "Associate"
    },
    {
        "image": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661793575_Mrs.%20Shraddha.png",
        "name": "Mrs. Shraddha",
        "designation": "Associate"
    },
    {
        "image": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661780197_Mr.%20Siddhant.png",
        "name": "Mr. Siddhant",
        "designation": "Associate"
    },
    // {
    //     "image": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661813383_Ms.%20Riya.png",
    //     "name": "Ms. Riya",
    //     "designation": "Executive"
    // },
    {
        "image":  "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661811450_Mrs.%20Sonal.png",
        "name": "Mrs. Sonal",
        "designation": "Program Analyst"
    },

    {
        "image": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661809718_Mr.%20Mohammed%20Arif.png",
        "name": "Mr. Mohammed Arif",
        "designation": "Program Analyst"
    },
    {
        "image": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661806158_Mr.%20Ajay.png",
        "name": "Mr. Ajay",
        "designation": "Program Analyst"
    },
    {
        "image": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661788488_Mr.%20Krishna.png",
        "name": "Mr. Krishna",
        "designation": "Program Analyst"
    },
    {
        "image":"https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661802236_Mr.%20Mohammed%20Arshadull.png",
        "name": "Mr. Mohammed Arshadull",
        "designation": "Program Analyst"
    },
    {
        "image": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661807752_Mr.%20Gurav.png",
        "name": "Mr. Gurav",
        "designation": "Program Analyst"
    },
    {
        "image": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661797244_Mr.%20Umesh.png",
        "name": "Mr. Umesh",
        "designation": "Program Analyst"
    },
    {
        "image": "https://p2p-marketplace-production-bucket.s3.ap-south-1.amazonaws.com/1738661777961_Mr.Cleophas.png",
        "name": "Mr.Cleophas",
        "designation": "Program Analyst"
    },
]