import dayjs from "dayjs";
import { convertArrayToOptionType, FormField, NAME_REGEX } from "src/helpers";
import * as Yup from "yup";

export const SCHEDULE_CALL_FORM_FIELDS: FormField[] = [
  {
    label: "Your Name",
    dataKey: "name",
    componentType: "input",
    componentProps: {
      type: "text",
      regex: NAME_REGEX,
      placeholder: "Name",
    },
  },
  {
    label: "Your Mobile No",
    dataKey: "phonenumber",
    componentType: "input",
    componentProps: {
      type: "number",
      maxLength: 10,
      placeholder: "Ex:91XXXXXXXX",
    },
  },
  {
    label: "Date",
    dataKey: "scheduled_date",
    componentType: "options",
    componentProps: {
      required: true,
      options: convertArrayToOptionType(
        [...new Array(5)].map((_, indx: number) => dayjs().add(indx ,'day').format("DD/MM/YYYY"))
      ),
      placeholder: "Scheduled Date ",
    },
  },
  {
    label: "Select slot",
    dataKey: "preferred_time_to_call",
    componentType: "options",
    componentProps: {
      placeholder: "Select time Slot",
      required: true,
      options: convertArrayToOptionType([
        "10 AM - 12 PM",
        "12 PM - 02 PM",
        "02 PM - 04 PM",
        "04 PM - 06 PM",
        "06 PM - 08 PM",
        "Over Weekend",
      ]),
    },
  },
];

export const INITIAL_SCHEDULE_CALL_FORM_VALUES = {
  name: "",
  phonenumber: "",
  preferred_time_to_call: "",
  scheduled_date :""
};

export const SCHEDULE_CALL_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required("Your Name is required"),
  phonenumber: Yup.string()
    .required("Your Mobile No is required")
    .length(10, "Phone number must be exactly 10 digits"),
  preferred_time_to_call: Yup.string().required("Select a time slot"),
  scheduled_date :Yup.string().required('Date is required')
});
