import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE, stringifyObjectToRequestQuery } from 'src/helpers'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { userEngagmentActionApiRequest } from 'src/services/requests/Campagin'
import CustomeDialog from 'src/shadcn/components/custom/CustomeDialog'
import InfiniteProgressBar from 'src/shadcn/components/custom/InfiniteProgressBar'

type Props = {}

const UserEngagmentPage = (props: Props) => {

    const { search } = useLocation()
    // const [errors, seterrors] = useState({
    //     isError:false,
    //     message:'',
    // })

    // convert querystring  serach params to object
    const queryParams = new URLSearchParams(search).toString()
    const queryParamsObject = useMemo(() => {
        if (queryParams) {
            console.log({ queryParams });
            let queryExtract = queryParams && queryParams.split("&").reduce((acc: any, curr) => {
                let [key, value] = curr.split("=");
                acc[key] = value
                return acc
            }, {})
            return queryExtract
        }
        return null
    }, [queryParams])

    // const queryParamsObject = JSON.parse(queryParams)
    console.log(queryParamsObject)

    useEffect(() => {
        if (Object.values(queryParamsObject).length > 0) {
            handleActionRequest()
        }
        return () => { }
    }, [queryParamsObject])


    const [data, setdata] = useState<any>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const navigate = useNavigate()

    const handleActionRequest = catchAsync(async () => {
        setIsLoading(true)
        return queryParamsObject && userEngagmentActionApiRequest(stringifyObjectToRequestQuery(queryParamsObject))
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            setIsLoading(false)
            if (queryParamsObject?.activity == "WHATSAPP_CLICKED") {
                window.location.replace("" + process.env.REACT_APP_WHATSAPP_LINK)
            }
            else if (queryParamsObject?.activity?.toLowerCase().includes("project")) {
                navigate("/" + MAIN_ROUTES_CONST.marketplace)
            }
            else navigate("/")
        }
    }, () => { setIsLoading(false) })


    return (
        <div>
            <div className=' my-4 text-center'>

                <div className='my-4 w-full h-[100vh] '>
                    <div className='space-y-4 mt-[50vh]'>
                        <div>Loading ....</div>
                        <div className='mx-auto max-w-xs '>
                            <InfiniteProgressBar />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default UserEngagmentPage