import React, { useState } from 'react'
import { IMG_CROWDFUNDING_BANNER } from 'src/helpers'
import SolarPlan from '../ReStructure/CustomerJorney/Component/Solarplan/SolarPlan'
import ServicePlan from './ServicePlan'
import TopFinanceView from './TopFinanceView'
import MiddleFinanceView from './MiddleFinanceView'
import FeaturesView from './FeaturesView'
import BottomSection from './BottomSection'
import WorkingProcess from './WorkingProcess'

type Props = {}

const FinanceServiceDetails = (props: Props) => {
    const [projectDetails, setProjectDetails] = useState<any>(undefined)

    return (
        <div className='w-full  p-4 md:p-16 mb-48 md:mb-0'>
            <TopFinanceView />
            <MiddleFinanceView />
            <FeaturesView />
            <ServicePlan />
            <BottomSection />
            <WorkingProcess />

        </div>
    )
}

export default FinanceServiceDetails