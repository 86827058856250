import React from 'react'
import FinanceServiceDetails from 'src/modules/FinanceService/FinanceServiceDetails'

type Props = {}

const FinanceServiceMain = (props: Props) => {
  return (
    <div className='w-full mb-20'>
 <FinanceServiceDetails />
    </div>
   
  )
}

export default FinanceServiceMain