import { FC, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import App from "src/App";
import { WithChildren } from "src/helpers";
import ReStructureLayoutMain from "src/modules/Layouts/ReStructure/ReStructureLayoutMain";

import AboutUsMAin from "src/pages/Aboutus/AboutMain";
import ForgotMain from 'src/pages/Auth/Forgot/ForgotMain';
import VerifyOtpMain from 'src/pages/Auth/Otp/VerifyOtpMain';
import ResetpasswordMain from 'src/pages/Auth/Resetpassword/ResetpasswordMain';
import ContactusMain from 'src/pages/Contactus/ContactusMain';
import CrowdFundingMain from 'src/pages/CrowdFunding/CrowdFundingMain';
import ExistingEnquiryForm from "src/pages/EnquiryForms/ExistingEnquiryForm";
import NewEnquiryForm from "src/pages/EnquiryForms/NewEnquiryForm";
import FaqMain from 'src/pages/Faq/FaqMain';
import GreenEnergyMain from 'src/pages/GreenEnergy/GreenEnergyMain';
import SuccessResetPassoword from 'src/pages/InfoDialogs/SuccessResetPassoword';
import AllLandsPage from "src/pages/LandRegistration/AllLandsPage";
import LandDetailsPage from "src/pages/LandRegistration/LandDetailsPage";
import LandRegistrationPage from "src/pages/LandRegistration/LandRegistrationPage";
import MarketplaceMain from 'src/pages/Marketplace/MarketplaceMain';
import ProductDetails from "src/pages/Marketplace/ProductDetails";
import AllNewConstrutedProjectsPage from "src/pages/NewConstructedProjects/AllNewConstrutedProjectsPage";
import NewConstrcutedProjectDetailsPage from "src/pages/NewConstructedProjects/NewConstrcutedProjectDetailsPage";
import CreateOrderPage from "src/pages/Orders/CreateOrderPage";
import OrderDetailsPage from "src/pages/Orders/OrderDetailsPage";
import OrdersPageMain from "src/pages/Orders/OrdersPageMain";
import P2pEnergyTradingMain from "src/pages/P2pEnergyTrading";
import ProductDetailPage from "src/pages/Products/ProductDetailPage";
import ProductsPageMain from "src/pages/Products/ProductsPageMain";
import ProfileMain from "src/pages/Profile";
import AllProjectRightsPage from "src/pages/ProjectRights/AllProjectRightsPage";
import ProjectRightsDetailsPage from "src/pages/ProjectRights/ProjectRightsDetailsPage";
import CustomerJourny from "src/pages/ReStructure/CustomerJoerny/CustomerJourny";
import Services from "src/pages/ReStructure/Services/Services";
import WelcomePage_R from "src/pages/ReStructure/Welcome";
import AllRooftopsPage from "src/pages/Rooftop/AllRooftopsPage";
import RoofTopDetailsPage from "src/pages/Rooftop/RoofTopDetailsPage";
import AllSellExistingProjectsPage from "src/pages/SellExistingProjects/AllSellExistingProjectsPage";
import SellExistingProjectsDetailsPage from "src/pages/SellExistingProjects/SellExistingProjectsDetailsPage";
import ServicesEnquiryRequestPage from "src/pages/ServicesEnquiryRequest/ServicesEnquiryRequestPage";
import WalletMain from "src/pages/Wallet/WalletMain";
import CellRoutes from "./CellRoutes";
import { APP_ROUTES_CONST, MAIN_ROUTES_CONST } from "./modules";
import P2pEnergyLandingPage from "src/pages/P2pEnergyLandingPage";
import CommercialServices from "src/modules/ReStructure/Services/CommercialServices";
import RecidentialServices from "src/modules/ReStructure/Services/RecidentialServices";
import OurTeamPage from "src/pages/OurTeam/OurTeamPage";
import VisionAndMIssionPage from "src/pages/VisionAndMission/VisionAndMIssionPage";
import FinancePage from "src/pages/SubServices/Finance/FinancePage";
import LandLeasePage from "src/pages/SubServices/LandLease/LandLeasePage";
import ProjectRightsPage from "src/pages/SubServices/ProjectRights/ProjectRightsPage";
import SalesOfExistingProjectsPage from "src/pages/SubServices/SalesOfExistingProjects/SalesOfExistingProjectsPage";
import NewConstructedProjectsPage from "src/pages/SubServices/NewConstructedProjects/NewConstructedProjectsPage";
import RoofTopSolarPage from "src/pages/SubServices/RoofTopSolar/RoofTopSolarPage";
import CareerPage from "src/pages/Career/CareerPage";
import ReferAndEarnPage from "src/pages/ReferAndEarn/ReferAndEarnPage";
import Registration from "src/modules/Auth/Registration";
import RegistrationPage from "src/pages/Auth/Registartion/RegistrationPage";
import QuotationMain from "src/pages/Quotaion/QuotationMain";
import ReferredRewardsMain from "src/pages/ReferredRewards/ReferredRewardsMain";
import TermsAndConditions from "src/pages/TermsAndConditions/TermsAndConditions";
import PrivacyPage from "src/pages/TermsAndConditions/PrivacyPage";
import OrderConfirmation from "src/pages/OrderConfirmation/OrderConfirmation";
import PaymentScreenPage from "src/pages/PaymentScreen/PaymentScreenPage";
import AreaCalculate from "src/pages/AreaCalculate";
import FinanceServiceMain from "src/pages/FinanceService/FinanceServiceMain";
import Service1 from "src/pages/SubServices/Service1/Service1";
import Service2 from "src/pages/SubServices/Service2/Service2";
import MyWishlist from "src/pages/Wishlist/MyWishlist";
import EnquiryHistoryMain from "src/pages/History/EnquiryHistory/EnquiryHistoryMain";
import ProjectHistoryMain from "src/pages/History/ProjectHistory/ProjectHistoryMain";
import LoginPage from "src/pages/Auth/Login/LoginPage";
import VisitPageMain from "src/modules/VisitsPage/VisitPageMain";
import CaptureWhatsAppInterestPage from "src/pages/CaptureWhatsAppInterest/CaptureWhatsAppInterestPage";
import AdvisoryOptionMain from "src/pages/AdvisoryOption/AdvisoryOptionMain";
import ProjectsMain from "src/pages/Projects/ProjectsMain";
import ProjectsEnquiryMain from "src/pages/ProjectsEnquiry/ProjectsEnquiryMain";
import UserEngagmentPage from "src/pages/UserEngagment/UserEngagmentPage";
import ProjectDetails from "src/modules/Layouts/ProjectDetails";
import ProjectRightDetail from "src/modules/Cell/ProjectRightDetail";
import ProjectsRoute from "./ProjectsRoute";
import AdvisoryConsultancyMain from "src/pages/AdvisoryConsultancy/AdvisoryConsultancyMain";
import ScheduleCallPageMain from "src/pages/ScheduleCall/ScheduleCallPageMain";
import SubscribeUserPageMain from "src/pages/SubscribeUserPage/SubscribeUserPageMain";
import UplodeElectBills from "src/pages/UplodeElectBills";

const { PUBLIC_URL } = process.env;
type Props = {};
const MainRoutes = (props: Props) => {


  return (
    <BrowserRouter>
      <Routes >
        <Route>
          <Route path={MAIN_ROUTES_CONST.captureInterest} element={<CaptureWhatsAppInterestPage />} />
          <Route path={MAIN_ROUTES_CONST.subscribe} element={<SubscribeUserPageMain />} />
        </Route>
        <Route element={<App />}>
          <Route element={<ReStructureLayoutMain />}>
            {/* <Route index element={<WelcomePage />} /> */}
            <Route index element={<WelcomePage_R />} />

            {/* <Route path={MAIN_ROUTES_CONST.welcome} element={<WelcomePage />} /> */}
            <Route path={MAIN_ROUTES_CONST.welcome} element={< WelcomePage_R />} />
            <Route path={MAIN_ROUTES_CONST.login} element={< LoginPage />} />
            <Route path={MAIN_ROUTES_CONST.marketplace} element={<MarketplaceMain />} />
            <Route path={MAIN_ROUTES_CONST.forgot} element={<ForgotMain />} />
            <Route path={MAIN_ROUTES_CONST.verify_otp} element={<VerifyOtpMain />} />
            <Route path={MAIN_ROUTES_CONST.reset_password} element={<ResetpasswordMain />} />
            <Route path={MAIN_ROUTES_CONST.success_reset_password} element={<SuccessResetPassoword />} />
            <Route path={MAIN_ROUTES_CONST.faq} element={<FaqMain />} />
            <Route path={MAIN_ROUTES_CONST.about_us} element={<AboutUsMAin />} />
            <Route path={MAIN_ROUTES_CONST.advisory} element={<AdvisoryOptionMain />} />
            <Route path={MAIN_ROUTES_CONST.contact_us} element={<ContactusMain />} />
            <Route path={MAIN_ROUTES_CONST.crowd_funding} element={<CrowdFundingMain />} />
            <Route path={MAIN_ROUTES_CONST.green_energy} element={<GreenEnergyMain />} />
            <Route path={MAIN_ROUTES_CONST.cell + "/*"} element={<CellRoutes />} />
            <Route path={"p/*"} element={<ProjectsRoute />} />
            <Route path={MAIN_ROUTES_CONST.marketplace_product_details + "/:id"} element={<ProductDetails />} />


            <Route path={MAIN_ROUTES_CONST.wallet} element={<WalletMain />} />
            <Route path={MAIN_ROUTES_CONST.referredRewards} element={<ReferredRewardsMain />} />
            <Route path={MAIN_ROUTES_CONST.profile} element={<ProfileMain />} />
            <Route path={MAIN_ROUTES_CONST.wishlist} element={<MyWishlist />} />
            <Route path={MAIN_ROUTES_CONST.existing_enquiry_rquest + "/:id"} element={<ExistingEnquiryForm />} />
            <Route path={MAIN_ROUTES_CONST.new_enquiry_request} element={<NewEnquiryForm />} />
            <Route path={MAIN_ROUTES_CONST.solarkit} element={<ProductsPageMain />} />
            <Route path={MAIN_ROUTES_CONST.p2pEnergyTrading} element={<P2pEnergyTradingMain />} />
            <Route path={MAIN_ROUTES_CONST.order} element={<OrdersPageMain />} />
            <Route path={MAIN_ROUTES_CONST.create_order} element={<CreateOrderPage />} />
            <Route path={MAIN_ROUTES_CONST.order_details + "/:id"} element={<OrderDetailsPage />} />
            <Route path={MAIN_ROUTES_CONST.products_details + "/:id"} element={<ProductDetailPage />} />
            <Route path={MAIN_ROUTES_CONST?.customerJourney + "/:id"} element={<CustomerJourny />} />
            <Route path={MAIN_ROUTES_CONST?.services} element={<Services />} />
            <Route path={MAIN_ROUTES_CONST?.commercial_services} element={<CommercialServices />} />
            <Route path={MAIN_ROUTES_CONST?.residential_services} element={<RecidentialServices />} />
            <Route path={MAIN_ROUTES_CONST.land_registration} element={<LandRegistrationPage />} />
            <Route path={MAIN_ROUTES_CONST.lands} element={<AllLandsPage />} />
            <Route path={MAIN_ROUTES_CONST.land_details + "/:id"} element={<LandDetailsPage />} />
            <Route path={MAIN_ROUTES_CONST.services_enquiry_form} element={<ServicesEnquiryRequestPage />} />
            <Route path={MAIN_ROUTES_CONST.roof_tops} element={<AllRooftopsPage />} />
            <Route path={MAIN_ROUTES_CONST.roof_top_details + "/:id"} element={<RoofTopDetailsPage />} />
            <Route path={MAIN_ROUTES_CONST.project_rights} element={<AllProjectRightsPage />} />
            <Route path={MAIN_ROUTES_CONST.project_rights_detail + "/:id"} element={<ProjectRightsDetailsPage />} />
            <Route path={MAIN_ROUTES_CONST.sell_existing_projects} element={<AllSellExistingProjectsPage />} />
            <Route path={MAIN_ROUTES_CONST.sell_existing_projects_detail + "/:id"} element={<SellExistingProjectsDetailsPage />} />
            <Route path={MAIN_ROUTES_CONST.new_constructed_projects} element={<AllNewConstrutedProjectsPage />} />
            <Route path={MAIN_ROUTES_CONST.new_constructed_projects_detail + "/:id"} element={<NewConstrcutedProjectDetailsPage />} />
            <Route path={MAIN_ROUTES_CONST.p2p_energy_trading_landing} element={<P2pEnergyLandingPage />} />
            <Route path={MAIN_ROUTES_CONST.careers} element={<CareerPage />} />
            <Route path={MAIN_ROUTES_CONST.mission_and_vision} element={<VisionAndMIssionPage />} />
            <Route path={MAIN_ROUTES_CONST.our_team} element={<OurTeamPage />} />
            <Route path={MAIN_ROUTES_CONST.finance_services} element={<FinancePage />} />
            <Route path={MAIN_ROUTES_CONST.land_lease_services} element={<LandLeasePage />} />
            <Route path={MAIN_ROUTES_CONST.service_1} element={<Service1 />} />
            <Route path={MAIN_ROUTES_CONST.service_2} element={<Service2 />} />
            <Route path={MAIN_ROUTES_CONST.advisory_consultancy} element={<AdvisoryConsultancyMain />} />
            <Route path={MAIN_ROUTES_CONST.project_rights_services} element={<ProjectRightsPage />} />
            <Route path={MAIN_ROUTES_CONST.sales_existing_project_service} element={<SalesOfExistingProjectsPage />} />
            <Route path={MAIN_ROUTES_CONST.new_constructed_project_service} element={<NewConstructedProjectsPage />} />
            <Route path={MAIN_ROUTES_CONST.rooftop_solar_services} element={<RoofTopSolarPage />} />
            <Route path={MAIN_ROUTES_CONST.refer_now} element={<ReferAndEarnPage />} />
            <Route path={MAIN_ROUTES_CONST.registration + "/:code"} element={<RegistrationPage />} />
            <Route path={MAIN_ROUTES_CONST.registration} element={<RegistrationPage />} />
            <Route path={MAIN_ROUTES_CONST.quotation_details} element={<QuotationMain />} />
            <Route path={MAIN_ROUTES_CONST.termsAndConditions} element={<TermsAndConditions />} />
            <Route path={MAIN_ROUTES_CONST.dataPrivacy} element={<PrivacyPage />} />
            <Route path={MAIN_ROUTES_CONST.finance_service} element={<FinanceServiceMain />} />
            <Route path={MAIN_ROUTES_CONST.order_confirmation + "/:id"} element={<OrderConfirmation />} />
            <Route path={MAIN_ROUTES_CONST.payment + "/:order_id"} element={<PaymentScreenPage />} />
            <Route path={MAIN_ROUTES_CONST.area_calculator} element={<AreaCalculate />} />
            <Route path={MAIN_ROUTES_CONST.enquiry_history} element={<EnquiryHistoryMain />} />
            <Route path={MAIN_ROUTES_CONST.project_history} element={<ProjectHistoryMain />} />
            <Route path={MAIN_ROUTES_CONST.visit} element={<VisitPageMain />} />
            <Route path={MAIN_ROUTES_CONST.projects} element={<ProjectsMain />} />
            <Route path={MAIN_ROUTES_CONST.projects_enquiry + "/:id"} element={<ProjectsEnquiryMain />} />
            <Route path={MAIN_ROUTES_CONST.user_engagement} element={<UserEngagmentPage />} />
            <Route path={MAIN_ROUTES_CONST.schedule_call} element={<ScheduleCallPageMain />} />
            <Route path={MAIN_ROUTES_CONST.uploadBills} element={<UplodeElectBills />} />
            {/* {
              currentUser &&
              <Route path={APP_ROUTES_CONST.dashboard} element={<DashboardMain />} />
            } */}
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  return <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
}

export default MainRoutes;
