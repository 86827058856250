import React, { useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import Login from 'src/modules/Auth/Login'
import Registration from 'src/modules/Auth/Registration'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'src/shadcn/components/ui'

type Props = {}

const LoginPage = (props: Props) => {
const [logoinTab, setLoginTab] = useState<string>("login");
    return (
        <div className='flex justify-center items-center py-16 mb-20 xl:mb-0'>
              <Tabs
                defaultValue="login"
                className=""
                onValueChange={(e: string) => setLoginTab(e)}
              >
                <TabsList className="grid w-full grid-cols-2">
                  <TabsTrigger value="login">Login</TabsTrigger>
                  <TabsTrigger value="signUp">Sign Up</TabsTrigger>
                </TabsList>
                <TabsContent value="login" className="">
                  <Login/>
                </TabsContent>
                <TabsContent value="signUp" className="">
                  <Registration/>
                </TabsContent>
              </Tabs>
        </div>
    )
}

export default LoginPage