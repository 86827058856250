import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import { catchAsync, FormField, ICON_EMAIL, SESSIONS } from 'src/helpers'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import InputWithIcon from 'src/shadcn/components/custom/InputWithIcon'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { Input, Label } from 'src/shadcn/components/ui'
import { FORGOT_FORM_FIELDS, forgot_initialValues, forgotFormValidationSchema } from './modules'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import { AxiosResponse } from 'axios'
import { forgetpasswordRequestApi } from 'src/services/requests/Auth'
import { useDispatch } from 'react-redux'
import { getTempDataRootState, setEmailForValidatOtp, setMobileNoForValidatOtp, setSession } from 'src/redux/reducers/tempDataSlice'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'

type Props = {}

const Forgot = (props: Props) => {

    const [isLoading, setisLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()


    const formik = useFormik({
        initialValues: forgot_initialValues,
        validationSchema: forgotFormValidationSchema,
        onSubmit(values, formikHelpers) {
            handleForgotSubmit()
        },
    })

    const handleForgotSubmit = catchAsync(async () => {
        setisLoading(true)
        return await forgetpasswordRequestApi(formik.values)
    }, (results: AxiosResponse) => {
        setisLoading(false)
        if (results.status == 200) {
            dispatch(setSession(SESSIONS.FORGOT_PASSWORD))
            dispatch(setMobileNoForValidatOtp(formik.values.mobileNumber))
            navigate("/" + MAIN_ROUTES_CONST.verify_otp)
        }
        console.log((results));

    }, () => {
        setisLoading(false)
    })

    console.log({ error: formik.errors, Value: formik.values });



    return (
        <div>
            <div className='space-y-4'>
                <div className=' font-bold uppercase text-primary'>Forgot Password</div>
                <div className='text-xs'>Enter your email and we'll send you OTP to reset your password</div>
                <form className='space-y-2' onSubmit={formik.handleSubmit}>
                    {
                        FORGOT_FORM_FIELDS.map((field: FormField,index:number) => {
                            return <RenderFormComponents formik={formik} {...field} value={formik.values[field.dataKey as keyof object]} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} key={index}/>
                        })
                    }
                    <div>
                        <RoundedButton isLoading={isLoading} className='w-full' >Send</RoundedButton>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Forgot