import React, { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE, stringifyObjectToRequestQuery, stringifyRequestQuery } from 'src/helpers'
import { updateVisitorStateApiRequest } from 'src/services/requests/Auth'

type Props = {}

const VisitPageMain = (props: Props) => {

    const { search } = useLocation()
    const navigate = useNavigate()


    // Function to extract search params and convert to an object
    const extractSearchParams = (search: string) => {
        const params = new URLSearchParams(search);
        const paramsObject: { [key: string]: string } = {};

        params.forEach((value, key) => {
            paramsObject[key] = value;
        });

        return paramsObject;
    };

    const { uuid, path } = useMemo(() => extractSearchParams(search), [search])

    console.log({ uuid, path });

    useEffect(() => {
        if (uuid) {
            handleUpdateUserState(uuid)
        }
        return () => { }
    }, [uuid])



    const handleUpdateUserState = catchAsync(async (uuid: string) => {
        let action = "";
        if (path == "register") {
            action = "clickedOnJoinUs"
        }
        else if (path == "marketplace") {
            action = "clickedOnExploreMarketplace"
        }
        return await uuid && updateVisitorStateApiRequest(stringifyObjectToRequestQuery({
            "uuid": uuid,
            "action": action
        }))
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            navigate("/" + path)
        }
    }, () => { })


    return (
        <div className='flex justify-center items-center p-6 text-2xl font-bold'>Loading....</div>
    )
}

export default VisitPageMain