import React from 'react'
import { useParams } from 'react-router-dom'
import ProjectsEnquiry from 'src/modules/ProjectsEnquiry/ProjectsEnquiry'

const ProjectsEnquiryMain = () => {
    const{id}=useParams()
    console.log(id)
  return (
    <div className='p-10'>
        <ProjectsEnquiry/>
    </div>
  )
}

export default ProjectsEnquiryMain