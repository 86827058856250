import React from "react";
import NewLayoutResultLoader from "../Marketplace/Loaders/NewLayoutResultLoader";
import ProjectsCard from "./Component/ProjectsCard";
type ProjectsProps = {
  isLoading: boolean;
  data: any;
};
const Projects = ({ data, isLoading }: ProjectsProps) => {
  return (
    <div className="w-full  pb-20">
      {isLoading ? (
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-4 2xl:gap-8 w-full px-28">
          {[...new Array(3)].map((_) => {
            return <NewLayoutResultLoader />;
          })}
        </div>
      ) : (
        <>
          {Array.isArray(data) && data?.length > 0 ? (
            <ProjectsCard projects={data} loading={isLoading} />
          ) : (
            <div className="w-full text-center text-sm font-normal my-10">
              No records found !
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Projects;
