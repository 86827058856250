import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  catchAsync,
  FormField,
  IS_SUCCESS_RESPONSE
} from "src/helpers";
import { useAuth } from "src/modules/Auth/Core";
import {
  INTEREST_DETAILS_FIELDS,
  INTEREST_DETAILS_INIT,
  InterestDetailsFormSchema
} from "src/modules/ReStructure/Services/Component/modules";
import { CELL_ROUTES } from "src/navigations/CellRoutes";
import { MAIN_ROUTES_CONST } from "src/navigations/modules";
import { createProjectInterestRequestApi } from "src/services/requests/Projects";
import CustomeDialog from "src/shadcn/components/custom/CustomeDialog";
import RenderFormComponents from "src/shadcn/components/custom/RenderFormComponents";
import { Button } from "src/shadcn/components/ui";

type Props = {
  projects: Array<any>;
  loading: boolean;
};

type RenderItemProps = {
  projectName: string;
  _id: string;
  projectSize: string;
  customerType: string;
  projectCategoryType: string;
  projectType: string;
  systemType: string;
  totalProjectCost: string;
  returnPerYearRate: string;
  headerImage: any;
  termPlan: string;
  projectId: string;
  imageAttachments: string[];
  requiredInvestment: string;
  totalInvestmentValue: string;
  buildingType: string;
  state: string;
  nearestCity: string;
  project_description: string;
  totalIntrestedUsers: number
};

const RenderItem = ({
  projectName,
  _id,
  projectSize,
  projectType,
  systemType,
  totalProjectCost,
  headerImage,
  returnPerYearRate,
  termPlan,
  projectId,
  imageAttachments,
  requiredInvestment,
  totalInvestmentValue,
  buildingType,
  customerType,
  projectCategoryType,
  state,
  nearestCity,
  project_description,
  totalIntrestedUsers
}: RenderItemProps) => {
  const navigate = useNavigate();

  const [openUserDetailsDailog, setOpenUserDetailsDailog] = useState(false);
  const [showUserInterestDialog, setshowUserInterestDialog] = useState(false);
  const [loading, setloading] = useState(false);
  const { currentUser } = useAuth()
  // console.log({ currentUser });



  const formik = useFormik({
    initialValues: INTEREST_DETAILS_INIT,
    validationSchema: InterestDetailsFormSchema,
    onSubmit(values, formikHelpers) {
      //   dispatch(
      //     setResidentialUserDetails({
      //       ...residentialUserDetails,
      //       ...values,
      //     })
      //   );
      //   navigation("/" + MAIN_ROUTES_CONST.area_calculator);
      handleProjectInterest();
    },
  });

  const handleProjectInterest = catchAsync(
    async () => {
      setloading(true);
      return await createProjectInterestRequestApi(formik.values);
    },
    (result: any) => {
      if (IS_SUCCESS_RESPONSE(result)) {
        setshowUserInterestDialog(true);
        setOpenUserDetailsDailog(false);
        formik.resetForm();
        setloading(false);
      }
    },
    () => {
      setloading(false);
    }
  );

  useEffect(() => {
    if (projectId) formik.setFieldValue("project_id", projectId);
  }, [projectId, currentUser]);

  useEffect(() => {
    if (currentUser?.mobileNumber) formik.setFieldValue("phonenumber", currentUser.mobileNumber)
    return () => { };
  }, [currentUser]);

  console.log({ error: formik.errors });

  return (
    <div
      onClick={
        () => { }
        // navigate(
        //     "/" + MAIN_ROUTES_CONST.cell + "/" + CELL_ROUTES.details + "/" + _id
        // )
      }
      className="bg-white border border-gray-200 rounded-2xl overflow-hidden shadow-md transition-transform duration-300 ease-in-out hover:scale-105 cursor-pointer 
    w-full min-w-[280px] mx-auto h-full flex flex-col"
    >
      {/* Image Section */}
      <div className="relative h-52 w-full">
        <img
          src={imageAttachments?.length > 0 ? imageAttachments[0] : ""}
          className="w-full h-full object-cover rounded-t-2xl"
          alt={projectName}
        />
        <div className="flex items-center space-x-2">
          <div className="absolute top-2 left-2 flex space-x-2">
            <div className=" bg-green-500 text-white text-xs font-semibold px-2 py-1 rounded">
              New
            </div>
            <span className=" bg-green-500 text-white text-xs font-semibold px-2 py-1 rounded">
              # {projectId}
            </span>
          </div>
        </div>
      </div>

      {/* Details Section */}
      <div className="p-4 flex flex-col flex-grow">
        {" "}
        {/* ✅ Ensures consistent spacing */}
        <h3 className="text-lg font-semibold">{projectName}</h3>
        <p className="text-gray-500 text-sm">
          {nearestCity}, {state}
        </p>
        <div className="flex justify-between items-center mt-2">
          <span className="text-md uppercase text-primary font-bold ">
            {projectCategoryType}
          </span>
        </div>
        {project_description ? (
          <p className="text-gray-500 text-sm ">{project_description}</p>
        ) : (
          <p className="text-gray-500 text-sm h-4"></p>
        )}
        <div className="grid grid-cols-3 gap-2 my-4 text-center text-sm">
          <div className="p-2 bg-gray-200 rounded-md">
            <p className="text-sm">Size</p>
            <p className=" text-sm font-bold">{projectSize}</p>
          </div>
          <div className="p-2 bg-gray-200 rounded-md">
            <p className="text-sm">Type</p>
            <p className="text-sm font-bold">{projectType}</p>
          </div>
          <div className="p-2 bg-gray-200 rounded-md">
            <p className="text-sm">Category</p>
            <p className="text-sm font-bold">{customerType}</p>
          </div>
        </div>
        <div className="flex w-full gap-x-2 mt-auto">
          <Button
            variant={"outlinePrimary"}
            className="flex-1"
            onClick={() => {
              navigate(
                "/" +
                MAIN_ROUTES_CONST.cell +
                "/" +
                CELL_ROUTES.details +
                "/" +
                _id
              );
            }}
          >
            View Details
          </Button>
          <Button
            className="flex-1"
            variant={"outlinePrimary"}
            onClick={(e) => {
              e.stopPropagation(); // ✅ Prevents click from triggering navigation
              navigate("/" + MAIN_ROUTES_CONST?.projects_enquiry + "/" + _id);
              setOpenUserDetailsDailog(true);
            }}
          >
            User Interests {totalIntrestedUsers || totalIntrestedUsers === 0 ? totalIntrestedUsers : "-"}
          </Button>
        </div>
      </div>

      {/* <form onSubmit={formik.handleSubmit}> */}
      <CustomeDialog
        isOpen={openUserDetailsDailog}
        headerTitle="Fill Interest Form"
        className="max-w-xs md:max-w-md lg:max-w-lg"
        onCancle={() => setOpenUserDetailsDailog(false)}
        onSubmit={() => formik.handleSubmit()}
      >
        <div className=" mb-8 ">
          {INTEREST_DETAILS_FIELDS.map((field: FormField) => {

            if (field.dataKey == "")

              return (
                <div key={`new-enquiry-form-${field.dataKey}`}>
                  <RenderFormComponents
                    formik={formik}
                    value={formik.values[field.dataKey as keyof object]}
                    {...field}
                    onChange={(e: any) => formik.setFieldValue(field.dataKey, e)}
                  />
                </div>
              );
          })}
        </div>
      </CustomeDialog>
      {/* </form> */}
      <CustomeDialog
        isOpen={showUserInterestDialog}
        headerTitle=""
        className="max-w-xs md:max-w-md lg:max-w-lg"
        hideDefaultCancle
        onSubmit={() => setshowUserInterestDialog(false)}
      >
        <div className=" mb-8 ">
          Thank you for your interest, our team will get back to you soon.
        </div>
      </CustomeDialog>
    </div>
  );
};

const ProjectsCard = ({ projects, loading }: Props) => {
  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4  gap-4 2xl:gap-8 px-4 md:px-8">
      {projects?.map((project: any) => (
        <RenderItem key={project._id} {...project} />
      ))}
    </div>
  );
};

export default ProjectsCard;
