import React from 'react'
import { FINANCE_MAIN, HAND_SHAK_IMG, IMG_CROWDFUNDING_BANNER, SECTION_BG } from 'src/helpers'
import { services } from './Constants'

type Props = {}

const TopFinanceView = (props: Props) => {
    return (
        <div className=' w-full space-y-4  mx-auto px-4  md:px-8'>
            {/* Heading Section */}
            <div className='w-full whitespace-normal text-center font-bold text-2xl sm:text-3xl md:text-4xl md:my-6'>
                Are you looking for Easy Financing on Solar Rooftop for your Home?
            </div>

            {/* Content Section */}
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-8 items-center'>
                {/* Image Section */}
                <div className='flex justify-center lg:order-2'>
                    <img
                        src={FINANCE_MAIN}
                        alt="Finance"
                        className='rounded-xl overflow-hidden w-[500px] h-auto max-w-md md:max-w-full'
                    />
                </div>

                {/* Text and Features Section */}
                <div className='w-full order-1 lg:order-1 '>
                    <ul className="w-full text-md md:ml-4 md:p-4">
                        <li className="w-full  mb-4 text-2xl sm:text-3xl font-bold text-primary">
                            Invest in Solar - Build a Greener Planet
                        </li>
                        <li className="w-full text-lg sm:text-xl">
                            With Rooftop Solar Finance Scheme for Smart Homes
                        </li>
                        <li className="mb-8 text-lg sm:text-xl">
                            Go Solar - Pledge for a Cleaner and Greener Environment.
                        </li>

                        <li className="mb-4 text-xl sm:text-2xl font-bold">
                            Key Attractions
                        </li>
                    </ul>

                    {/* Services/Features Section */}
                    <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-6">
                        {services.map((feature, index) => (
                            <div key={index} className="flex flex-col items-center">
                                <div className="bg-gray-200 rounded-full p-4 flex justify-center items-center w-20 h-20 sm:w-24 sm:h-24">
                                    <img
                                        src={feature.icon}
                                        alt={feature.title}
                                        className="w-10 h-10 sm:w-12 sm:h-12"
                                    />
                                </div>
                                <p className="text-center text-gray-800 font-medium text-sm sm:text-base mt-2">
                                    {feature.title}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopFinanceView
