import { User } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { setAuth, useAuth } from 'src/modules/Auth/Core';
import { APP_ROUTES_CONST, MAIN_ROUTES_CONST } from 'src/navigations/modules';
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from 'src/shadcn/components/ui';


type Props = {}

const UserComponent = (props: Props) => {

    const { currentUser, logout, setCurrentUser, saveAuth } = useAuth()
    const navigate = useNavigate()


    return (
        <div className=''>
            <DropdownMenu >
                <DropdownMenuTrigger asChild>
                    <div className='flex space-x-2 items-center cursor-pointer'>
                        <span className='hidden md:flex font-bold uppercase '> {currentUser?.name}</span>
                        <User  className='text-primary md:w-4 md:h-4 w-5 h-5' />
                    </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56" align="end" forceMount>
                    <DropdownMenuLabel className="font-normal">
                        <div className="flex flex-col space-y-1">
                            <p className="text-sm font-medium leading-none">{currentUser?.name}</p>
                            <p className="text-xs leading-none text-muted-foreground">
                                {currentUser?.email}
                            </p>
                            <p className="text-xs leading-none text-muted-foreground">
                                {currentUser?.role}
                            </p>
                        </div>
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup>
                        <DropdownMenuItem onClick={() => {
                            navigate("/" + MAIN_ROUTES_CONST.profile)
                        }} >
                            Profile
                        </DropdownMenuItem>
                        {/* <DropdownMenuItem onClick={() => {
                            navigate("/" + MAIN_ROUTES_CONST)
                        }} >
                            Settings
                        </DropdownMenuItem> */}
                        <DropdownMenuItem onClick={() => {
                            navigate("/" + MAIN_ROUTES_CONST.wishlist)
                        }} >
                            My Wishlist
                        </DropdownMenuItem>
                    </DropdownMenuGroup>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem onClick={() => {
                        saveAuth(undefined)
                        setCurrentUser(undefined)
                        navigate("/")

                    }} className='text-destructive hover:text-destructive focus:text-destructive hover:font-bold '>
                        Log out
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    )
}

export default UserComponent