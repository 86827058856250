import { format } from "date-fns";
import { InfoIcon } from "lucide-react";
import { cn } from "src/helpers";
import { Label, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../ui";
import DateInput from "./DateInput";
import DatePicker from 'react-date-picker'
import { ClassNameValue } from "tailwind-merge";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

type Props = {
  formik: any;
  label: string;
  nameKey: string;
  placeholder?: string;
  lableClass?: string;
  defaultValue?: string;
  value?: string;
  required?: boolean;
  onchange?: (e: any) => void;
  disabled?: boolean,
  showInfoIcon?: boolean
  infoContent?: string,
  toYear?: number
  fromYear?: number,
  labelStyle?: ClassNameValue,
  error_className?: ClassNameValue,
};

const FormikDateField = ({
  formik,
  placeholder,
  nameKey,
  label,
  lableClass,
  defaultValue,
  value,
  required,
  onchange,
  disabled,
  showInfoIcon,
  infoContent,
  toYear,
  fromYear,
  labelStyle,
  error_className
}: Props) => {
  return (
    <>
      <div className="flex justify-between mt-2">
        <Label className={cn(" text-xs", labelStyle, `${required ? "required" : ""}`)}>
          {label}
        </Label>
        {showInfoIcon ? (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger onClick={(event: any) => event.preventDefault()} >
                <InfoIcon className="w-[16px] h-[16px] text-gray-500 cursor-pointer" />
              </TooltipTrigger>
              <TooltipContent>
                <div className="w-[200px]">{infoContent}</div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : null}
      </div >
      <div className="mt-2">
        <DatePicker className={'w-full'} value={value} onChange={(e: any) => { onchange && onchange(e ? format(new Date(e), "yyyy-MM-dd") : null); }} calendarIcon={false} />
      </div>
      {
        formik.touched[nameKey] && formik.errors[nameKey] && (
          <div className={cn("text-xs font-medium text-destructive", error_className)}>
            {formik.errors[nameKey]}
          </div>
        )
      }
    </>
  );
};

export default FormikDateField;
