import React from "react";
import { DataTable } from "./Tabel/components/data-table";
import { columns } from "./Tabel/components/columns";

const ProjectsEnquiry = () => {
  return (
    <div className="w-full">
      <DataTable data={[]} columns={columns} isLoading={false} />
    </div>
  );
};

export default ProjectsEnquiry;
