import React, { useEffect, useMemo } from "react";
import Projects from "src/modules/Projects/Projects";
import {
  QueryRequestProvider,
  useQueryRequest,
} from "./Core/QueryRequestProvider";
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "./Core/QueryResponseProvider";
import { useAuth } from "src/modules/Auth/Core";
import { MdFirstPage, MdLastPage } from "react-icons/md";
import { ArrowLeft, ArrowRight } from "lucide-react";

const Main = () => {
  const apiResponse = useQueryResponseData();
  const { response } = useQueryResponse();

  const projects = useMemo(() => apiResponse, [apiResponse]);
  const isLoading = useQueryResponseLoading();
  const paggination = useQueryResponsePagination();
  const { updateState, state } = useQueryRequest();

  const { currentUser } = useAuth();
  const element = document.getElementById("projects-list");
  const handleScroll = () => {
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    updateState({
      filter: {
        // channelPartnerId: currentUser?.id,
        channelPartnerId:"6793981179cb5f38938004bd"
      },
    });
  }, []);

  return (
    <div className="w-full py-10">
      <div className="w-full"> <Projects isLoading={isLoading} data={projects} /></div>
     
      <div className="flex items-center justify-center  ">
        <div className="flex items-center space-x-2">
          <button
            className="border p-2 rounded-md"
            onClick={() => {
              updateState({
                page: 1,
              });
              handleScroll();
            }}
            disabled={paggination?.page === 1}
          >
            <MdFirstPage />
          </button>
          <button
            className="border p-2 rounded-md"
            onClick={() => {
              updateState({
                page: paggination?.page !== 1 ? paggination?.page - 1 : 1,
              });
              handleScroll();
            }}
            disabled={paggination?.page === 1}
          >
            <ArrowLeft />
          </button>
          <span>
            Page {paggination?.page} of {paggination?.total_pages}
          </span>
          <button
            className="border p-2 rounded-md"
            onClick={() => {
              updateState({
                page: Number(paggination?.page) + 1,
              });
              handleScroll();
            }}
            disabled={
              paggination.page && paggination?.last_page
                ? Number(paggination.page) === Number(paggination?.last_page)
                : true
            }
          >
            <ArrowRight />
          </button>
          <button
            className="border p-2 rounded-md"
            onClick={() => {
              updateState({
                page: Number(paggination?.last_page),
              });
              handleScroll();
            }}
            disabled={
              paggination.page && paggination?.last_page
                ? Number(paggination.page) === Number(paggination?.last_page)
                : true
            }
          >
            <MdLastPage />
          </button>
        </div>
      </div>
    </div>
  );
};

const ProjectsMain = () => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <Main />
      </QueryResponseProvider>
    </QueryRequestProvider>
  );
};

export default ProjectsMain;
