import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { PROFILE_FORM_FIELDS, profileFormValidationSchema, ProfileInit } from './modules'
import { catchAsync, FormField, successTheme } from 'src/helpers'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import { useAuth } from '../Auth/Core'
import { AxiosResponse } from 'axios'
import { getUserProfileRequestApi, updateUserProfileRequestApi } from 'src/services/requests/Auth'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { toast } from 'sonner'
import { RocketIcon } from 'lucide-react'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'

type Props = {}

const ProfileComponent = (props: Props) => {


    const { currentUser } = useAuth()
    const [isLoading, setisLoading] = useState(false)

    useEffect(() => {
        handleFetchProfileDetails()
        return () => { }
    }, [])

    const formik = useFormik({
        initialValues: ProfileInit,
        validationSchema: profileFormValidationSchema,
        onSubmit(values, formikHelpers) {
            handleUpdateProfileDetails()
        },
    })

    const handleFetchProfileDetails = catchAsync(async () => {
        setisLoading(true)
        return await getUserProfileRequestApi()
    }, (result: any) => {
        if (result.success) {
            formik.setFieldValue("abouteMe",result.data.abouteMe)
            formik.setFieldValue("firstName",result.data.firstName)
            formik.setFieldValue("lastName",result.data.lastName)
            formik.setFieldValue("mobileNumber",result.data.mobileNumber)
        }
        setisLoading(false)
    }, () => { setisLoading(false) })

    const handleUpdateProfileDetails = catchAsync(async () => {
        setisLoading(true)
        return await updateUserProfileRequestApi(formik.values)
    }, (result: AxiosResponse) => {
        if (result.status == 200) {
            toast.success("Prfile updated successfully", { style: successTheme })
        }
        setisLoading(false)
    }, () => { setisLoading(false) })

    useEffect(() => {
        if (currentUser) {
            formik.setFieldValue("email", currentUser.email)
        }
        return () => { }
    }, [currentUser])


    console.log({ currentUser,value:formik.values });


    return (
        <div className='space-y-4 py-4'>
            {
                Object.entries(formik.values).some(([key, value]) => value == null) ?
                    <div className='border border-yellow-500 bg-yellow-50 text-yellow-600 p-4 rounded-lg'>
                        <div className='flex space-x-2 items-center '>
                            <span><RocketIcon className="h-4 w-4" /></span>
                            <span> Please complete your profile</span>
                        </div>
                    </div> : null
            }
            <div className='flex justify-between items-center'>
                <div className='flex items-center space-x-2'>
                    <div className='w-[60px] h-[60px]  '>
                        <img src='https://static.vecteezy.com/system/resources/previews/001/840/618/original/picture-profile-icon-male-icon-human-or-people-sign-and-symbol-free-vector.jpg' className='rounded-full' style={{ width: "100%", height: "100%" }} />
                    </div>
                    <div className='flex flex-col justify-center'>
                        <div className='text-xl font-bold'>Full Name</div>
                    </div>
                </div>
            </div>
            <div>
                <form onSubmit={formik.handleSubmit} className='space-y-4'>
                    {PROFILE_FORM_FIELDS.map((field: FormField) => {
                        return <RenderFormComponents {...field} formik={formik} value={formik.values[field.dataKey as keyof object]} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} />
                    })}
                    <div  className='flex justify-center'><RoundedButton type='submit' >Update</RoundedButton></div>
                </form>
            </div>
            <LoadingDialog isOpen={isLoading} />
        </div>
    )
}

export default ProfileComponent