import { CheckCircle } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import { BRAND_ICON_MD, toAbsoluteUrl } from 'src/helpers'
import CaptureWhatAppInterestForm from 'src/modules/CaptureWhatsAppInterest/CaptureWhatAppInterestForm'
import CustomeDialog from 'src/shadcn/components/custom/CustomeDialog'

type Props = {}

const CaptureWhatsAppInterestPage = (props: Props) => {

    return (
        <>
            <div className='p-4 flex justify-center h-[100vh] w-full items-center bg-gray-200'>
                <div className='w-[400px]  space-y-4 bg-white p-4 rounded-lg'>
                    <div className='flex justify-center'>
                        <ReactSVG src={toAbsoluteUrl(BRAND_ICON_MD)} />
                    </div>
                    <div>
                        <div className='text-xl text-primary uppercase'>ENQUIRY FORM</div>
                        <div className='h-0.5 bg-primary w-[50px] rounded-full'></div>
                        <div className='my-4 text-sm'>Please fill the form below to get in touch with us.</div>
                        <div >
                            <CaptureWhatAppInterestForm />
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default CaptureWhatsAppInterestPage