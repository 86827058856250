import { IMG_CROWDFUNDING_BANNER } from 'src/helpers'
import CounterAnimation from 'src/shadcn/components/custom/Animations/CounterAnimation'
import { FINANCE_ANALYSIS } from '../ReStructure/Services/Component/modules'

type Props = {}

const BottomSection = (props: Props) => {
  return (
    <div  className='rounded-[25px]'
    style={{
              background: `url(${IMG_CROWDFUNDING_BANNER})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center"
    
            }}>
              <div className='p-4 space-y-20 mt-20 '>
                <div className='lg:mt-20'>
                <div className='text-white text-5xl text-center font-bold mb-2'>The right decision ,</div>
                <div className='text-primary text-4xl text-center font-medium'>at the right time.</div>
                </div>
                  <div className='grid  grid-cols-2 md:grid-cols-3 lg:grid-cols-4 items-start' >
                    {
                      FINANCE_ANALYSIS.map(({ count, dataKey, name, annotation }: any) => {
                        // if (projectSummary[dataKey]) {
                        //   count = projectSummary[dataKey]
                        // }
                        return <div className='flex flex-col items-center  space-y-1 p-4  ' key={name}>
                          <div className='font-bold text-4xl text-white'><CounterAnimation from={0} to={count} />{annotation}</div>
                          <div className='font-medium text-xl text-center text-white'>{name}</div>
                        </div>
                      })
                    }
                  </div>
                
                </div>
              
            </div>
  )
}

export default BottomSection