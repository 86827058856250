import Registration from 'src/modules/Auth/Registration'

type Props = {}

const RegistrationPage = (props: Props) => {

    return (
        <div className='flex justify-center items-center py-16 bg-gray-200 '>
            <div className='bg-white p-4 rounded-lg'>
                <div className='text-primary uppercase text-xl mx-2'>Registration</div>
                <div className='h-0.5 bg-primary w-[50px] mx-2 rounded-full'></div>
                <Registration />
            </div>
        </div>
    )
}

export default RegistrationPage