import Autoplay from "embla-carousel-autoplay";
import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cn } from "src/helpers";
import { useCusrousal } from "src/modules/Welcome/useCusrousal";
import { getTempDataRootState, resetSetBannerIndex, setSelectedBannerIndex, setSetBannerIndex } from "src/redux/reducers/tempDataSlice";
import { RootState } from "src/redux/store";
import {
    Carousel,
    CarouselApi,
    CarouselContent,
    CarouselItem
} from "src/shadcn/components/ui";
import { BANNERS } from "./modules";


type Props = {}

const BannerTiles = (props: Props) => {

    const navigation = useNavigate();

    const [setApi, setSetApi] = useState<CarouselApi>();
    const [current, setCurrent] = React.useState(0);
    const dispatch = useDispatch()
    const { selectedIndex, scrollSnaps, onDotButtonClick } = useCusrousal(setApi);
    const { selectBannerIndex, setBannerIndex } = useSelector((state: RootState) => getTempDataRootState(state))
    const plugin = React.useRef(
        Autoplay({ delay: 4000, stopOnInteraction: true })
    );


    useEffect(() => {
        dispatch(setSelectedBannerIndex(selectedIndex))
        return () => { }
    }, [selectedIndex])



    // useEffect(() => {
    //     if (setBannerIndex !== undefined) {
    //         plugin.current.stop();
    //         setApi && setApi?.scrollTo(Number(setBannerIndex), true);
    //         dispatch(resetSetBannerIndex())
    //         plugin.current.play();
    //     }
    //     return () => { }
    // }, [setApi, plugin, setBannerIndex])



    const handleMouseEnter = () => {
        plugin.current.stop();

    };

    const handleMouseLeave = () => {
        plugin.current.play();
    };

    const handleNext = (selectedIndex: any) => {
        // if (setApi) {
        //     setApi.canScrollNext();
        // }
        dispatch(setSelectedBannerIndex(selectBannerIndex == (BANNERS.length - 1) ? 0 : selectBannerIndex + 1))
        dispatch(setSetBannerIndex(selectBannerIndex == (BANNERS.length - 1) ? 0 : selectBannerIndex + 1))
    };


    const handlePrev = (selectedIndex: any) => {
        // if (setApi) {
        //     setApi.canScrollPrev();
        // }
        dispatch(setSelectedBannerIndex(selectBannerIndex == 0 ? 0 : selectBannerIndex - 1))
        dispatch(setSetBannerIndex(selectBannerIndex == 0 ? 0 : selectBannerIndex - 1))
    };

    // useEffect(() => {
    //     if (!setApi) {
    //         return;
    //     }
    //     setCurrent(setApi.selectedScrollSnap() + 1);
    //     setApi.on("select", () => {
    //         setCurrent(setApi.selectedScrollSnap() + 1);
    //     });
    // }, [setApi]);


    return (
        <div className="w-full relative">
            <Carousel
                setApi={setSetApi}
                plugins={[plugin.current]}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="w-full"
            >

                <CarouselContent className="mx-20">
                    {BANNERS.map((d: any, indx: number) => {
                        return <CarouselItem className="md:basis-1/2 lg:basis-1/5 p-0" key={indx}>
                            <button className="w-full h-full" onClick={() => dispatch(setSetBannerIndex(indx))}>
                                <div className={cn('flex justify-center items-center p-4 bg-theamblack text-white h-full w-full ', { "border border-r-2 border-r-black border-dotted ": (indx) < (BANNERS.length - 1), "bg-primary text-white": selectBannerIndex == indx })}>
                                    <div className='flex flex-col space-y-1'>
                                        <div className=' text-center'>{d(selectBannerIndex)}</div>
                                    </div>
                                </div>
                            </button>
                        </CarouselItem>
                    })}
                </CarouselContent>
                {
                    <MdOutlineKeyboardArrowLeft size={64}
                        onClick={() => handlePrev(selectedIndex)}
                        className={cn(
                            "absolute cursor-pointer rounded-full hover:text-primary",
                            "left-3 top-[40%] -translate-y-[40%]"
                        )}
                    />
                }
                {
                    <MdOutlineKeyboardArrowRight size={64}
                        onClick={() => handleNext(selectedIndex)}
                        className={cn(
                            "absolute   cursor-pointer rounded-full hover:text-primary",
                            "right-3 top-[40%] -translate-y-[40%]"
                        )} />
                }
            </Carousel>
        </div>
    );
}

export default BannerTiles