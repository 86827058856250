
import { toAbsoluteUrl } from "src/helpers"
import { OUR_TEAM } from "./modules"

export function OurTeamCarousal() {
    return (
        <div className="space-y-8">

<div className='grid md:grid-cols-2 gap-4 md:max-w-[40vw] max-w-[80vw] mx-auto'>

                {
                    OUR_TEAM.filter((_, indx: number) => indx <= 1).map(({ image, name, designation }: any) => {
                        return <div className='h-full w-full flex flex-col  items-center' key={name}>
                            <img src={image} className='md:h-[150px] rounded-xl' />
                            <div className='text-center mt-2 font-bold'>{name}</div>
                            <div className='text-center text-sm'>{designation}</div>
                        </div>
                    })
                }
                
            </div>
            <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                {
                    OUR_TEAM.filter((_, indx: number) => indx > 1).map(({ image, name, designation }: any) => {
                        return <div className='h-full w-full flex flex-col items-center' key={name}>
                           <img src={image} className='md:h-[150px] rounded-xl' />
                            <div className='text-center mt-2 font-bold '>{name}</div>
                            <div className='text-center text-sm'>{designation}</div>
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default OurTeamCarousal