import { ColumnDef } from "@tanstack/react-table";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";

import dayjs from "dayjs";


export const columns: ColumnDef<Task>[] = [
  {
    accessorKey: 'project_id',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Project Id (#)" />
    ),
  },
  {
    accessorKey: 'name',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    cell({ row: { original: { name} } }) {
      return <div className="ml-2">{name||"-"}</div>
    },
  },
  {
    accessorKey: 'email',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Email" />
    ),
    cell({ row: { original: { email} } }) {
      return <div className="ml-2">{email||"-"}</div>
    },
  },
  {
    accessorKey: 'phonenumber',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Phone Number" />
    ),
    cell({ row: { original: { phonenumber} } }) {
      return <div className="ml-2">{phonenumber||"-"}</div>
    },
  },
  // {
  //   accessorKey: 'project_type',
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Project Type" />
  //   ),
  // },
  // {
  //   accessorKey: 'type',
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Type" />
  //   ),
  // },


  {
    accessorKey: 'createdAt',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Date" />
    ),
    cell({ row: { original: { createdAt} } }) {
      return createdAt ? dayjs(new Date(createdAt)).format('DD-MM-YYYY') : null
    },
  },
  




];
