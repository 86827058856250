import { MoveUpRight } from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'
import { FINANCE_BG, FINANCE_MAIN } from 'src/helpers'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { Button } from 'src/shadcn/components/ui/button'

type Props = {}

const MiddleFinanceView = (props: Props) => {
    return (
        <div className='w-full grid grid-cols-1 lg:grid-cols-2 gap-6 px-4 md:px-4  mt-8 md:mt-12 items-center'>
            {/* Image Section */}
            <div className='flex justify-center h-auto order-1 lg:order-2'>
                <img
                    src={FINANCE_BG}
                    alt="Finance"
                    className='rounded-2xl overflow-hidden w-full max-w-md md:max-w-full h-auto'
                />
            </div>

            {/* Content Section */}
            <div className='w-full order-2 lg:order-1 space-y-6 text-center md:text-left'>
                <ul className="w-full text-md md:ml-4 md:p-4 space-y-2">
                    <li className="text-3xl   font-bold text-primary">
                        Go Solar and Save Big
                    </li>
                    <li className="text-lg  text-gray-700">
                        A simple and cost-effective way to enhance your savings and pledge for a greener and cleaner environment.
                    </li>
                    <li className="text-lg  text-gray-700">
                        To Know More about our Smart Rooftop Solar Finance Schemes
                    </li>
                    <li className="text-2xl  font-bold text-green-600">
                        GET IN TOUCH WITH US TODAY!
                    </li>
                </ul>

                {/* Button Section */}
                <div className='flex justify-center md:justify-start md:px-6'>
                    <Link to={`/${MAIN_ROUTES_CONST.contact_us}`}>
                        <Button className='flex items-center space-x-2 rounded-full px-6 py-2'>
                            <MoveUpRight className='text-white' />
                            <span className='text-lg sm:text-lg'>Enquire Now</span>
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default MiddleFinanceView
